import { AUTO_TITLE_DESC_PROPERTY_TYPE } from "constants/dbEnums";
import { AUTO_TITLE_DESC_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";

export const handleAiFormData = (data) => {
  const { type: adType } = data;
  let modifiedData = { ...data };

  const commonFieldsToRemove = ["owner_role", "_id"];

  const fieldsToRemoveByType = {
    [PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY]: [
      "additional_rooms",
      "amenities",
      "approx_price",
      "available_from",
      "covered_parking",
      "flooring_type",
      "furnish_type_items",
      "notice_period",
      "open_parking",
      "preferences",
      "security_deposit",
      "possession_period",
    ],
    [PROPERTY_COLLECTIONS.PG]: [
      "security_deposit",
      "property_managed_by",
      "pricing",
      "pg_rules",
      "notice_period",
      "meal_on_weekends",
      "meal_on_weekdays",
      "manager_stays_at_pg",
      "lock_in_period",
      "hidden",
      "gate_timing",
      "furnish_type_items",
      "covered_parking",
      "common_areas",
      "capacity_and_availability",
      "meal_type",
      "meal_amount",
      "included_services",
      "preferred_tenant",
    ],
    [PROPERTY_COLLECTIONS.PLOT]: [
      "discount",
      "approx_price",
      "annual_dues_payable",
      "booking_amount",
      "other_charges",
      "dimension",
      "discount_validity",
      "financing_available",
      "amenities",
      "possession_period"
    ],
    [PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY]: [
      "approx_price",
      "booking_amount",
      "annual_dues_payable",
      "amenities",
      "financing_available",
      "discount",
      "yearly_appreciation",
      "age_of_property",
      "ownership",
      "office_features",
      "notice_period",
      "lock_in_period",
      "security_deposit",
      "possession_period",
      "occupancy_certificate",
      "is_hot_deal",
      "available_from",
      "negotiable"
    ],
    [AUTO_TITLE_DESC_PROPERTY_TYPE.NEED_FLAT]: [
      "electricity_charges_included",
      "date_of_birth",
      "negotiable",
      "amenities",
      "photos",
      "meta_title",
      "meta_description",
      "slug_url",
      "property_status",
      "listing_score",
      "sid",
      "room_details",
      "hidden",
      "created_by",
      "updated_by",
      "reverification_count",
      "expires_on",
      "created_at",
      "updated_at",
      "__v",
      "gender",
    ]
  };

  commonFieldsToRemove.forEach((field) => delete modifiedData[field]);

  if (fieldsToRemoveByType[adType]) {
    fieldsToRemoveByType[adType].forEach((field) => delete modifiedData[field]);
  }

  const formData = {
    posting_type: AUTO_TITLE_DESC_PROPERTY_TYPE_TAGS[data.type],
    form_data: { ...modifiedData },
  };

  return formData;
};
