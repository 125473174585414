import React from "react";
import {
  Box,
  Button,
  FormControl,
  Flex,
  Grid,
  Heading,
  ModalCloseButton,
} from "@chakra-ui/react";
import SelectInput from "components/addForm/SelectInput";
import CitySelectInput from "components/addForm/location/citySelectInput";
import AutoSuggestComponent from "components/addForm/location/locality";
import ValidatedInput from "components/addForm/ValidatedInput";
import {
  LOCATION_TYPE,
  PROJECT_PROPERTY_TYPE,
} from "constants/dbEnums";
import { getTagsForSelectedInput } from "utils/common";
import MultiSearchInput from "components/multiSearchInput";
import { CenteredSpinner } from "components/loader/Loader";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";
import { REQUIREMENT_TYPE } from "constants/dbEnums";

const PROPERTY_TYPE_OPTIONS = getTagsForSelectedInput([
  PROJECT_PROPERTY_TYPE.RESIDENTIAL_PROPERTY,
  PROJECT_PROPERTY_TYPE.COMMERCIAL_PROPERTY,
  PROPERTY_COLLECTIONS.PG
]);

const BrokerGroupForm = ({
  formData,
  handleChange,
  handleLocation,
  handleLocality,
  handleSubmit,
  handleReset,
  editingId,
  isDisabled,
  isSubmittingForm
}) => {
  const isFormEmpty = !formData?.name;

  return (
    <Box flex={1}>
      {/* Heading and Buttons */}
      <Flex justify="space-between" align="center" mb={2}>
        <Heading size="md">
          {editingId ? "Edit Broker Group" : "Create Broker Group"}
        </Heading>
        <Flex align="center" gap={19}>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={isFormEmpty || isDisabled}
            variant="ghost"
          >
            {editingId ? "Update" : "Add"}
          </Button>
          <Button variant="ghost" onClick={handleReset}>
            Reset
          </Button>
          <ModalCloseButton position="relative" top="0" />
        </Flex>
      </Flex>
      {isSubmittingForm && <CenteredSpinner />}
      <FormControl mb={4}>
        <ValidatedInput
          name="name"
          label="Group Name"
          value={formData.name}
          placeholder="Enter group name"
          type="text"
          onChange={(e) => handleChange(e)}
          isRequired={true}
        />
        <SelectInput
          name="property_type"
          label="Property Type"
          options={PROPERTY_TYPE_OPTIONS}
          onChange={(e) => handleChange(e)}
          selectedValue={formData?.property_type}
        />
      </FormControl>
      <FormControl mb={4}>
        <SelectInput
          name="ad_type"
          label="Ad Type"
          options={REQUIREMENT_TYPE}
          onChange={(e) => handleChange(e)}
          selectedValue={formData?.ad_type}
          disabled={[PROPERTY_COLLECTIONS.PLOT, PROPERTY_COLLECTIONS.PG].includes(formData.property_type)}
        />
      </FormControl>
      <FormControl mb={4}>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleLocation(e)}
          result_type={LOCATION_TYPE.CITY}
          selectedValue={formData?.location?.city}
        />
      </FormControl>
      <FormControl>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <AutoSuggestComponent
            name="locality"
            selectedValue={formData?.location?.label}
            placeholder="Search by locality, area"
            onChange={(e) => handleLocality(e)}
            label="Locality (Area)"
            location={formData.location}
            result_type={LOCATION_TYPE.LOCALITY}
            selectedCity={formData?.location?.city}
          />
          <ValidatedInput
            name="building"
            placeholder="Enter society or area"
            type="text"
            onChange={(e) => handleLocality(e)}
            label="Building (Apartment)"
            value={formData?.location?.building}
          />
        </Grid>
      </FormControl>
      <ValidatedInput
        name="comment"
        placeholder="Add Comment"
        type="text"
        onChange={(e) => handleChange(e)}
        label="Comment"
        value={formData.comment}
      />
      <FormControl mb={4}>
        <MultiSearchInput
          label="Users"
          name="users"
          value={formData.brokers ? formData.brokers : formData.users}
          onChange={(e) => handleChange(e)}
          placeholder="Search by Phone Number"
          type="text"
        />
      </FormControl>
    </Box>
  );
};

export default BrokerGroupForm;
