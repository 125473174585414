import commercialSchema, { commercialKeys, commercialKeysArray } from "./commercial.validate";
import pgSchema from "./pg.validation";
import plotValidation, { plotkeys, plotKeysArray } from "./plot.validation";
import projectsSchema, { projectsKeys, projectsKeysArrya } from "./projects.validation";
import residentialSchema, { residentialKeys, residentialKeysArrya } from "./residential.validate";
import shareFlatSchema from "./shareFlat.validation";

const formValidation = {
  PG: {
    create: pgSchema.create,
    update: pgSchema.update
  },
  residential_property: {
    create: residentialSchema.create,
    update: residentialSchema.update
  },
  plot: {
    create: plotValidation,
    update: plotValidation
  },
  commercial_property: {
    create: commercialSchema.create,
    update: commercialSchema.update
  },
  projects: {
    create: projectsSchema.create,
    update: projectsSchema.update,
  },
  share_flat: {
    create: shareFlatSchema.create,
    update: shareFlatSchema.update
  }
};

export default formValidation;

export const customFormkeysArray = {
  plot: plotKeysArray,
  residential_property: residentialKeysArrya,
  commercial_property: commercialKeysArray,
  projects: projectsKeysArrya
};

export const customFormKeys = {
  plot: plotkeys,
  residential_property: residentialKeys,
  commercial_property: commercialKeys,
  projects: projectsKeys
};
