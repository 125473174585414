import CalendarInput from "../CalendarInput";
import SelectInput from "../SelectInput";
import ValidatedInput from "../ValidatedInput";
import MultiSelect from "../MultiSelect";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";
import { Box, Flex } from "@chakra-ui/react";
import AutoSuggestComponent from "../location/locality";
import { getDataFromLocalStorage } from "utils/common";
import DescriptionGenerator from "../autoGenerateDescription";
import { GENDER_TYPE_TAGS } from "constants/dbEnums";
import { OCCUPATION_TAGS } from "constants/dbEnums";
import { AVAILABLE_ROOMS_TAGS } from "constants/dbEnums";
import { convertToIdNameArray } from "utils/common";
import CheckboxComponent from "../Checkbox";
import { SHARING_TYPE } from "constants/dbEnums";
import { BHK } from "constants/dbEnums";
import { FURNISH_TYPE } from "constants/dbEnums";
import AmenitiesInput from "../AmenitiesInput";
import { INDOOR_AMENITIES } from "constants/dbEnums";
import { is_price_negotiable } from "constants/dbEnums";
import { is_electricity_and_water_charge } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE } from "constants/dbEnums";
import { AMENITIES } from "constants/dbEnums";
import { PREFERENCES } from "constants/dbEnums";
import { ROOM_OPTIONS_OPTIONS } from "constants/dbEnums";
import { OCCUPATION } from "constants/dbEnums";
import Image from "../imageUpload";

const handleSocialLinkChange = (e, onChange) => {
  const { name, value } = e.target;
  onChange({ target: { name, social_links: { [name]: value }}});
}

const shareFlat = {
  date_of_birth: (props) => (
    <CalendarInput
      name="date_of_birth"
      label="Date of Birth"
      isRequired={true}
      error={props.error}
      disablePastDate={false}
      {...props}
    />
  ),
  gender: (props) => (
    <SelectInput
      name="gender"
      label="Gender"
      options={GENDER_TYPE_TAGS}
      propertyType="gender"
      selectedValue={props.value}
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  occupation: (props) => {
    return (
        <SelectInput
          name="occupation"
          label="Occupation"
          options={OCCUPATION_TAGS}
          propertyType="occupation"
          selectedValue={props.value}
          error={props.error}
          isRequired={true}
          {...props}
        />
    );
  },
  designation: (props) => {
    const { formData, onChange } = props;
    const isWorking = formData.occupation === OCCUPATION.WORKING || formData.occupation === OCCUPATION.OTHER;
    return (
      isWorking && (
        <ValidatedInput
          name="designation"
          label="Designation"
          value={formData?.designation}
          placeholder="Enter designation"
          type="text"
          onChange={onChange}
        />
      )
    );
  },
  studying: (props) => {
    const { formData, onChange } = props;
    const isStudent = formData.occupation === OCCUPATION.STUDENT;
    return (
      isStudent && (
        <ValidatedInput
          name="studying"
          label="Studying"
          value={formData?.studying}
          placeholder="Studying.."
          type="text"
          onChange={onChange}
        />
      )
    );
  },
  hobbies: (props) => {
    const { formData, onChange } = props;
    return (
        <ValidatedInput
          name="hobbies"
          label="Hobbies"
          value={formData?.hobbies}
          placeholder="Enter Hobbies"
          type="text"
          onChange={onChange}
        />
    );
  },
  languages: (props) => {
    const { formData, onChange } = props;
    return (
        <ValidatedInput
          name="languages"
          label="Languages"
          value={formData?.languages}
          placeholder="Enter Hobbies"
          type="text"
          onChange={onChange}
        />
    );
  },
  instagram: (props) => {
    const { formData, onChange } = props;
    return (
        <ValidatedInput
          name="instagram"
          label="Instagram"
          value={formData?.social_links?.instagram}
          placeholder="Enter Insta Account"
          type="text"
          onChange={(e) => handleSocialLinkChange(e, onChange)}
        />
    );
  },
  facebook: (props) => {
    const { formData, onChange } = props;
    return (
        <ValidatedInput
          name="facebook"
          label="Facebook"
          value={formData?.social_links?.facebook}
          placeholder="Enter Fb Account"
          type="text"
          onChange={(e) => handleSocialLinkChange(e, onChange)}
        />
    );
  },
  linkedin: (props) => {
    const { formData, onChange } = props;
    return (
        <ValidatedInput
          name="linkedin"
          label="Linkedin"
          value={formData?.social_links?.linkedin}
          placeholder="Enter Linkedin Account"
          type="text"
          onChange={(e) => handleSocialLinkChange(e, onChange)}
        />
    );
  },
  need_to_shift_and_available_from: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlat = formData.property_type === SHARING_TYPE.NEED_FLAT;
    return (
      <CalendarInput
        name={isNeedFlat ? "need_to_shift" : "available_from"}
        label={isNeedFlat ? "Need to shift" : "Available From"}
        onChange={onChange}
        value={formData?.need_to_shift ? formData?.need_to_shift : formData?.available_from}
        error={error?.need_to_shift ? error?.need_to_shift : error?.available_from}
      />
    );
  },
  bhk: (props) => {
    const { formData } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <SelectInput
          name="bhk"
          label="BHK Type"
          options={BHK}
          selectedValue={formData.bhk}
          isRequired={true}
          {...props}
        />
      )
    );
  },
  room_details: (props) => {
    const { formData, onChange } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <MultiSelect
          name="room_details"
          label="Room Details"
          options={ROOM_OPTIONS_OPTIONS}
          displayValue="label"
          value={formData.room_details}
          onChange={onChange}
        />
      )
    );
  },
  furnish_type: (props) => {
    const { formData } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;

    return (
      isNeedFlatmate && (
        <SelectInput
          name="furnish_type"
          label="Furnish Type"
          options={FURNISH_TYPE}
          propertyType="furnish_type"
          selectedValue={props.value}
          isRequired={true}
          {...props}
        />
      )
    );
  },
  furnish_type_items: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    if (formData.furnish_type !== "unfurnished" && formData.furnish_type) {
      return (
        isNeedFlatmate && (
          <AmenitiesInput
            options={INDOOR_AMENITIES}
            onChange={onChange}
            name="furnish_type_items"
            furnishType={formData?.furnish_type}
            isRequired={true}
            error={error}
            label="Select Amenities"
            selectedValue={
              formData?.furnish_type_items ? formData?.furnish_type_items : {}
            }
          />
        )
      );
    }
  },
  total_floors: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <ValidatedInput
          name="total_floors"
          label="Total Floors"
          value={formData?.total_floors}
          placeholder="Enter no. of Floors"
          type="number"
          isRequired={true}
          valueInNumber={true}
          error={error?.total_floors}
          onChange={onChange}
        />
      )
    );
  },
  your_floor: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <ValidatedInput
          name="your_floor"
          label="Your Floor"
          value={formData?.your_floor}
          placeholder="Enter no. of your floor"
          type="number"
          isRequired={true}
          valueInNumber={true}
          error={error?.your_floor}
          isDisabled={formData?.total_floors <= 0}
          onChange={onChange}
        />
      )
    );
  },
  partner_gender: (props) => {
    return (
      <SelectInput
        name="partner_gender"
        label="Partner Gender"
        options={GENDER_TYPE_TAGS}
        propertyType="gender"
        selectedValue={props.value}
        isRequired={true}
        error={props.error}
        {...props}
      />
    );
  },
  min_partner_age: (props) => (
    <ValidatedInput
      name="min_partner_age"
      label="Min Partner Age"
      value={props.value}
      placeholder="Enter min age"
      type="text"
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  max_partner_age: (props) => (
    <ValidatedInput
      name="max_partner_age"
      label="Max Partner Age"
      value={props.value}
      placeholder="Enter max age"
      type="text"
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  partner_occupation: (props) => {
    const occupationTypeArray = convertToIdNameArray(OCCUPATION_TAGS);
    return (
      <MultiSelect
        name="partner_occupation"
        label="Partner Occupation"
        options={occupationTypeArray}
        displayValue="name"
        value={props.value}
        onChange={props.onChange}
        isRequired={true}
      />
    );
  },
  min_approx_price_and_max_approx_price: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlat = formData.property_type === SHARING_TYPE.NEED_FLAT;

    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };

    return (
      <ValidatedInput
        name={isNeedFlat ? "min_approx_price" : "max_approx_price"}
        label={isNeedFlat ? "Min Approx Price" : "Approx Price.."}
        value={isNeedFlat ? formData.min_approx_price : formData?.max_approx_price}
        placeholder={isNeedFlat ? "Enter Min Approx Price" : "Enter Approx Price"}
        type="number"
        error={isNeedFlat ? error?.min_approx_price : error?.max_approx_price}
        isRequired={true}
        onChange={(e) => handleChange(e)}
      />
    );
  },
  max_approx_price_and_maintenance_charges: (props) => {
    const { formData, error, onChange } = props;
    const isNeedFlat = formData.property_type === SHARING_TYPE.NEED_FLAT;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <ValidatedInput
        name={isNeedFlat ? "max_approx_price" : "maintenance_charges"}
        label={isNeedFlat ? "Max Approx Price" : "Maintenance Charges"}
        value={isNeedFlat ? formData?.max_approx_price : formData?.maintenance_charges }
        placeholder={isNeedFlat ? "Enter Max Approx Price" : "Enter Maintenance Price"}
        type="number"
        error={isNeedFlat ? error?.max_approx_price : error?.maintenance_charges}
        isRequired={isNeedFlat}
        onChange={(e) => handleChange(e)}
      />
    );
  },
  booking_amount: (props) => {
    const { formData } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <ValidatedInput
          name="booking_amount"
          value={props?.formData?.booking_amount ? props?.formData?.booking_amount : ""}
          placeholder="Enter booking amount"
          type="number"
          onChange={props.onChange}
          label="Booking Amount (Rs)"
          valueInNumber={true}
          error={props.error}
        />
      )
    );
  },
  other_charges: (props) => {
    const { formData } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <ValidatedInput
          name="other_charges"
          value={props.formData?.other_charges ? props.formData?.other_charges : ""}
          placeholder="Enter other charges"
          type="number"
          onChange={props.onChange}
          label="Other Charges (Rs)"
          valueInNumber={true}
        />
      )
    );
  },
  negotiable_and_is_electricity_and_water_charge: (props) => {
    const { formData, onChange } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    const handleChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      isNeedFlatmate && (
        <Flex gap={4}>
          <div style={{ flex: 2 }}>
            <CheckboxComponent
              options={is_price_negotiable}
              name="negotiable"
              onChange={(e) => handleChange(e)}
              label="Is price negotiable"
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.negotiable && { selectedValues: "true" })}
            />
          </div>
          <div style={{ flex: 3 }}>
            <CheckboxComponent
              options={is_electricity_and_water_charge}
              name="is_electricity_and_water_charge"
              onChange={(e) => handleChange(e)}
              label="Is electricity charges included"
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.is_electricity_and_water_charge && {
                selectedValues: "true",
              })}
            />
          </div>
        </Flex>
      )
    );
  },
  security_deposit: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      const updatedvalue = {
        ...formData.security_deposit,
        ...(name === "type" && { type: updatedValue }),
        ...(name === "amount" && { amount: Number(updatedValue) }),
        ...(name === "month" && {
          amount: formData.approx_price * Number(updatedValue),
        }),
      };
      onChange({
        target: {
          name: "security_deposit",
          value: updatedvalue,
        },
      });
    };
    return (
      isNeedFlatmate && (
        <Flex direction="row" alignItems="center" gap={1}>
          <div style={{ flex: 3 }}>
            <CheckboxComponent
              options={SECURITY_DEPOSIT_TYPE}
              name="type"
              onChange={(e) => handleChange(e)}
              label="Security Deposit"
              selectedValues={formData?.security_deposit?.type ?? ""}
            />
          </div>
          <div style={{ flex: 2 }}>
            {formData?.security_deposit?.type &&
              formData?.security_deposit?.type !== "none" && (
                <ValidatedInput
                  name={
                    formData?.security_deposit?.type === "fixed"
                      ? "amount"
                      : "month"
                  }
                  placeholder={
                    formData?.security_deposit?.type === "fixed"
                      ? "Enter the amount in rs"
                      : "Enter no. of months (max 36)"
                  }
                  type="number"
                  onChange={(e) => handleChange(e)}
                  label={
                    formData?.security_deposit?.type === "fixed"
                      ? "Amount in RS"
                      : "Months (max 36)"
                  }
                  valueInNumber={true}
                  error={error?.security_deposit}
                  isRequired={true}
                  value={
                    formData?.security_deposit?.type === "fixed"
                      ? formData?.security_deposit?.amount
                      : formData?.security_deposit?.month
                  }
                />
              )}
          </div>
        </Flex>
      )
    );
  },
  amenities: (props) => {
    const { formData, onChange, value } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    return (
      isNeedFlatmate && (
        <MultiSelect
          name="amenities"
          label="Select Amenities"
          options={AMENITIES}
          displayValue="name"
          value={value}
          onChange={onChange}
        />
      )
    );
  },
  preferences: (props) => {
    return (
        <MultiSelect
          name="preferences"
          label="Select Preferences"
          options={PREFERENCES}
          displayValue="name"
          value={props.value}
          onChange={props.onChange}
        />
    );
  },
  location: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData?.location?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name,
      };
      onChange({
        target: {
          name: "location",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage("topCities");
    const selectedValue = topCities?.find(
      (city) => city.name === formData?.location?.city
    );
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "location",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "location",
            value: {
              ...formData.location,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.location?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={
                formData?.location?.city
                  ? formData.location?.city
                  : selectedValue?.city
              }
              error={error}
              location={formData.location}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (area)"
              value={formData?.location?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  photos: (props) => {
    const { formData, onChange, error } = props;
    const isNeedFlatmate = formData.property_type === SHARING_TYPE.NEED_FLATMATE;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      isNeedFlatmate && (
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          value={formData?._id ? formData?.photos : []}
        />
      )
    );
  },
  profile_image: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
        <Image
          label="Upload Profile Image"
            onChange={(e) => handleChange(e)}
            name="user_profile_pic"
            collectionType={formData.type}
            error={error}
            value={formData?.user_profile_pic ? [formData?.user_profile_pic] : []}
            multiple={false}
          />
    );
  },
  description: (props) => {
    const { value, onChange, formData, error } = props;
    const isNeedFlat = formData.property_type === SHARING_TYPE.NEED_FLAT;
    const descriptionData = {
      ...formData,
      type: formData.property_type || formData.propertyType,
    };
    const {
      location,
      gender,
      occupation,
      min_partner_age,
      max_partner_age,
      min_approx_price,
      max_approx_price,
    } = descriptionData;
    const isDescriptionFieldsEmpty =
      !location?.city ||
      !location?.locality ||
      !gender ||
      !occupation ||
      !min_partner_age ||
      !max_partner_age ||
      !min_approx_price ||
      !max_approx_price;
    return (
      <DescriptionGenerator
        onChange={onChange}
        formData={descriptionData}
        value={value}
        name="description"
        isRequired={true}
        label="Description"
        placeholder="Enter description or generate by AI"
        error={error}
        isDisabled={isNeedFlat && isDescriptionFieldsEmpty}
      />
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <>
        {formData?._id && (
          <ValidatedInput
            name="slug_url"
            label="Slug Url"
            placeholder="Add Slug Url"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            value={formData.slug_url}
            error={error}
          />
        )}
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Enter Meta Title"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter Meta Description"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
};

export default shareFlat;
