import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const Dropdown = ({
  placeholder,
  DROPDOWN_DATA,
  onChange,
  size,
  value,
  isDisabled,
  borderColor = "#637381",
  bg = "#F9F9FB",
  color = "#637381",
  borderRadius = "5px",
  borderWidth = "0.5px",
  minHeight = "32px",
  multiSelect = false,
}) => {
  const isFilterApplied = value && value.length > 0;

  const handleSelectChange = (selectedValues) => {
    if (multiSelect) {
      if (selectedValues.includes("")) {
        onChange([]);
      } else {
        onChange(selectedValues);
      }
    } else {
      onChange(selectedValues);
    }
  };

  return (
    <Menu closeOnSelect={!multiSelect}>
      <MenuButton
        as={Button}
        size={size}
        bg={isDisabled ? "#E2E8F0" : isFilterApplied ? "blue.100" : bg}
        borderColor={
          isDisabled ? "#CBD5E0" : isFilterApplied ? "blue.500" : borderColor
        }
        cursor={isDisabled ? "not-allowed" : "pointer"}
        color={isDisabled ? "#A0AEC0" : isFilterApplied ? "blue.700" : color}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        minHeight={minHeight}
        width="100%"
        disabled={isDisabled}
        textAlign="left"
        rightIcon={<ChevronDownIcon />}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text isTruncated>
            {value && value.length > 0
              ? DROPDOWN_DATA.filter((item) => multiSelect ? value.includes(item.value) : value === item.value)
                  .map((item) => item.label)
                  .join(", ")
              : placeholder}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList width="130%" marginLeft={"5%"}>
        <MenuOptionGroup
          type={multiSelect ? "checkbox" : "radio"}
          value={value}
          onChange={handleSelectChange}
        >
          <MenuItemOption key="empty" value="">
            {placeholder}
          </MenuItemOption>
          {DROPDOWN_DATA?.map((item, index) => (
            <MenuItemOption key={index} value={item.value}>
              {item.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
