import { showToast } from "components/toast/Toast";

export const submitResponse = async ({ apiCall, payload, url }) => {
    try {
      const method = payload._id ? "patch" : "post";
      const response = await apiCall[method](url, payload);
      if (response) {
        showToast({
          message: payload?._id
            ? "App Settings Status updated successfully."
            : "App Settings Status has been successfully submitted",
          success: true,
          customStyle: {
            background: "#52c41a",
            color: "#fff",
            padding: "10px",
          },
        });
      }
      return response;
    } catch (error) {
      console.error("Error creating/updating App Settings :", error);
      showToast({
        message: error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.response?.data?.error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
      throw error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error;
    }
  };