import { Box, Flex } from "@chakra-ui/react";
import DescriptionGenerator from "components/addForm/autoGenerateDescription";
import CalendarInput from "components/addForm/CalendarInput";
import CheckboxComponent from "components/addForm/Checkbox";
import MultiSelect from "components/addForm/MultiSelect";
import SelectInput from "components/addForm/SelectInput";
import ValidatedInput from "components/addForm/ValidatedInput";
import {
  GENDER_TYPE_TAGS,
  OCCUPATION_TAGS,
  AVAILABLE_ROOMS_TAGS,
} from "constants/dbEnums";
import { convertToIdNameArray } from "utils/common";

const ShareFlatForm = ({ onChange, formData, errors, userData }) => {
  const genderTypeArray = convertToIdNameArray(GENDER_TYPE_TAGS);
  const occupationTypeArray = convertToIdNameArray(OCCUPATION_TAGS);

  const descriptionFormData = {
    ...formData,
    type: "need_flat",
    ad_type: "share_flat",
    location: userData.location,
    owner_id: userData.userId
  };
  const {location, gender, occupation, min_partner_age, max_partner_age, min_approx_price, max_approx_price} = descriptionFormData;
  const isDescriptionFieldsEmpty = !location?.city || !location?.locality || !gender || !occupation || !min_partner_age || !max_partner_age || !min_approx_price || !max_approx_price;

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Flex width="100%" mb={3} gap={4}>
        <Box flex="1">
          <CalendarInput
            name="date_of_birth"
            label="Date of Birth"
            isRequired={true}
            disablePastDate={false}
            onChange={onChange}
            value={formData.date_of_birth}
            error={errors?.date_of_birth}
          />
        </Box>
        <Box flex="1">
          <SelectInput
            name="gender"
            label="Gender"
            options={GENDER_TYPE_TAGS}
            propertyType="gender"
            isRequired={true}
            onChange={onChange}
            selectedValue={formData.gender}
            error={errors?.gender}
          />
        </Box>
      </Flex>
      <Flex width="100%" gap={4} mb={3}>
        <Box flex="1">
          <SelectInput
            name="occupation"
            label="Occupation"
            options={OCCUPATION_TAGS}
            propertyType="occupation"
            isRequired={true}
            onChange={onChange}
            selectedValue={formData.occupation}
            error={errors.occupation}
          />
        </Box>
      </Flex>
      <Flex width="100%" gap={4} mb={3}>
        <Box flex="1">
          <CalendarInput
            name="need_to_shift"
            label="Need to shift"
            onChange={onChange}
            value={formData.need_to_shift}
            error={errors.need_to_shift}
          />
        </Box>
        <Box flex="1">
          <SelectInput
            name="partner_gender"
            label="Partner Gender"
            options={GENDER_TYPE_TAGS}
            propertyType="gender"
            selectedValue={formData.partner_gender}
            isRequired={true}
            error={errors.partner_gender}
            onChange={onChange}
          />
        </Box>
      </Flex>
      <Flex width="100%" gap={4} mb={3}>
        <Box flex="1">
          <ValidatedInput
            name="min_partner_age"
            label="Min Partner Age (Minimum: 18)"
            placeholder="Enter min age"
            type="text"
            valueInNumber={true}
            isRequired={true}
            onChange={onChange}
            value={formData.min_partner_age}
            error={errors.min_partner_age}
          />
        </Box>
        <Box flex="1">
          <ValidatedInput
            name="max_partner_age"
            label="Max Partner Age (Maximum: 60)"
            placeholder="Enter max age"
            type="text"
            valueInNumber={true}
            isRequired={true}
            onChange={onChange}
            value={formData.max_partner_age}
            error={errors.max_partner_age}
          />
        </Box>
      </Flex>
      <Flex width="100%" gap={4} mb={3}>
        <Box flex="1">
          <MultiSelect
            name="partner_occupation"
            label="Partner Occupation"
            options={occupationTypeArray}
            displayValue="name"
            onChange={onChange}
            value={formData.partner_occupation}
            error={errors.partner_occupation}
          />
        </Box>
        <Box flex="1">
          <ValidatedInput
            name="min_approx_price"
            label="Min Approx Price (Minimum: 1000)"
            placeholder="Enter Min Approx Price"
            type="text"
            isRequired={true}
            onChange={onChange}
            value={formData.min_approx_price}
            error={errors.min_approx_price}
          />
        </Box>
      </Flex>
      <Flex width="100%" gap={4} mb={3}>
        <Box flex="1">
          <ValidatedInput
            name="max_approx_price"
            label="Max Approx Price (Maximum: 1,000,000)"
            value={formData.max_approx_price}
            placeholder="Enter Min Approx Price"
            type="text"
            isRequired={true}
            onChange={onChange}
            error={errors.max_approx_price}
          />
        </Box>
        <Box flex="1">
          <DescriptionGenerator
            onChange={onChange}
            formData={descriptionFormData}
            value={formData.description}
            name="description"
            isRequired={true}
            label="Description"
            placeholder="Enter description or generate by AI"
            error={errors.description}
            isDisabled={isDescriptionFieldsEmpty}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ShareFlatForm;
