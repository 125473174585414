import { showToast } from "components/toast/Toast";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";
import { REQUIREMENT_TYPE } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_TYPE } from "constants/dbEnums";
import { USER_REQUIREMENTS_API } from "constants/endpoints";

export const submitRequirement = async ({ apiCall, payload }) => {
  try {
    const method = payload.requirement_id ? "patch" : "post";
    const url = USER_REQUIREMENTS_API;
    const response = await apiCall[method](url, payload);
    if (response) {
      showToast({
        message: payload?._id
          ? "Requirement updated successfully."
          : "Requirement has been successfully submitted",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating requirement:", error);
    showToast({
      message: error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};

export const displayRequirement = (req) => {
  const {ad_type} = req;
  const text = `${
    req?.property_type ? `Looking for a ${PROPERTY_TYPE[req?.property_type]}` : ""
  } ${
    req?.location ? `${req?.location.locality ? `at ${req?.location.locality}, ${req.location.city}` : ''}` : ""
  } ${req.ad_type ? `for ${REQUIREMENT_TYPE[ad_type]} ` : ""}`;
  return text;
};

export const filterUnusedData = (data) => {
  const locationKey = data.address ? 'address' : data.location ? 'location' : null;
  if(locationKey && data[locationKey]){
    const filteredLocation = Object.fromEntries(
      Object.entries(data[locationKey]).filter(([key, value]) => value !== "")
    );
    data[locationKey] = filteredLocation;
  }
  return data;
};

export const getVisibleColumns = (columnVisibility) => {
  return Object.keys(columnVisibility).filter(
    (key) => columnVisibility[key] === true && key !== "_id"
  );
};