import React, { useState } from "react";
import {
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from "@chakra-ui/react";

import { FiGrid } from "react-icons/fi";

const ManageColumns = ({
  columns,
  handleColumnChange,
  columnVisibility,
  manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox,
  setManageColumnSelectAllCheckbox,
  isMenuAlwaysOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function setAllValues(obj, value) {
    Object.keys(obj).forEach((key) => (obj[key] = value));
    return obj;
  }

  const toggleAllColumnsCheckbox = () => {
    if (manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox) {
      setManageColumnSelectAllCheckbox(false);
      setAllValues(columnVisibility, false);
    } else {
      setManageColumnSelectAllCheckbox(true);
      setAllValues(columnVisibility, true);
    }
  };

  const handleMenuToggle = () => {
    if (!isMenuAlwaysOpen) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <Menu
      closeOnSelect={false}
      zIndex={1000}
      isOpen={isMenuAlwaysOpen || isOpen} 
      onClose={() => setIsOpen(false)}
    >
      <MenuButton
        as={IconButton}
        icon={<FiGrid />}
        colorScheme="teal"
        variant="outline"
        borderRadius="md"
        size="sm"
        onClick={handleMenuToggle}
      />

      <MenuList
        p={4}
        style={{ zIndex: 100 }}
        overflowY={'auto'}
        maxH={'70vh'}
      >
        <VStack align="start" spacing={2}>
          <MenuItem closeOnSelect={false} p={0}>
            <Checkbox
              isChecked={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
              onChange={toggleAllColumnsCheckbox}
              size="sm"
            >
              {manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox ? "Deselect" : "Select"} all
            </Checkbox>
          </MenuItem>

          {columns.map((column) => (
            <MenuItem key={column.accessor} closeOnSelect={false} p={0}>
              <Checkbox
                isChecked={columnVisibility[column.accessor]}
                onChange={() => handleColumnChange(column.accessor)}
                size="sm"
              >
                {column.Header}
              </Checkbox>
            </MenuItem>
          ))}
        </VStack>
      </MenuList>
    </Menu>
  );
};

export default ManageColumns;
