import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  Box,
} from "@chakra-ui/react";

export const CenteredSpinner = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="10"
    >
      <Spinner size="lg" color="blue.500"/>
    </Box>
  );
};

export const OverlayLoaderComponent = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(1px)" />
      <ModalContent
        bg="transparent"
        boxShadow="none"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Center>
          <Spinner size="lg" color="#22acee" thickness="6px" />
        </Center>
      </ModalContent>
    </Modal>
  );
};
