import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import SendLeadForm from "./SendLeadForm";
import { useEffect, useState } from "react";
import useApi from "hooks/useApi";
import { showToast } from "components/toast/Toast";
import { submitForm } from "../services/sendLeadService";
import { BROKER_GROUP_API } from "constants/endpoints";
import { HttpStatusCode } from "axios";
import { PROPERTY_TYPE } from "constants/dbEnums";
import { REQUIREMENT_TYPE } from "constants/dbEnums";

const initialFormData = {
  group_id: "",
  ad_type: "",
  location: {},
};

const SendLeadModal = ({ isOpen, onClose, selectedRequirements, afterSendLead, selectedData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [brokerGroupData, setBrokerGroupData] = useState([]);
  const [brokerGroup, setBrokerGroup] = useState('');
  const { apiCall } = useApi();
  console.log("form data", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocation = (e) => {
    const { value: selectedValue } = e.target;
    const updatedValue = {
      city: selectedValue.name,
    };
    setFormData((prev) => ({
      ...prev,
      ["location"]: updatedValue,
    }));
  };

  const handleGroupClick = (res) => {
    const groupName = `Group Name: ${res.name} - Total Members: ${res.total_members}${
      res.property_type ? ` - Property Type: ${PROPERTY_TYPE[res.property_type]}` : ""
    } ${
      res.ad_type ? ` -Ad Type: ${REQUIREMENT_TYPE[res?.ad_type]}` : ""
    }`;
    setBrokerGroup(groupName);
    setFormData((prev) => ({
      ...prev,
      ['group_id']: res._id
    }));
  };

  const handleClearFilter = async () => {
    setFormData({
      ...formData,
      location: { city: "" },
      ad_type: ""
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmittingForm(true);
      const payload = {
        requirments: selectedRequirements,
        group_id: formData.group_id,
      };
      const response = await submitForm({ apiCall, payload });
      if (response.data) {
        setIsSubmittingForm(false);
      }
    } catch (error) {
      console.error("Error submitting requirement:", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const getBrokersList = async () => {
    try {
      const body = {
        city: formData.location.city,
        ad_type: formData.ad_type
      };
      const res = await apiCall.get(`${BROKER_GROUP_API}`, body);
        if (res?.statusCode === HttpStatusCode.Ok) {
          setBrokerGroupData(res?.data?.groups);
        }
    } catch (error) {
      console.error("Error fetching brokers group data", error);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const handleClearGroup = () =>{
    setBrokerGroup('');
    setFormData((prev) => ({
      ...prev,
      ['group_id']: ""
    }));
  };

  useEffect(() => {
    if (isOpen) {
      getBrokersList();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction={{ base: "column", md: "column" }} gap={2}>
            <SendLeadForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmittingForm={isSubmittingForm}
              formData={formData}
              handleLocation={handleLocation}
              handleApplyFilter={getBrokersList}
              listData={brokerGroupData}
              handleItemClick={(res)=>handleGroupClick(res)}
              handleClearFilter={handleClearFilter}
              brokerGroup={brokerGroup}
              userList={selectedData}
              setBrokerGroupData={setBrokerGroupData}
              setBrokerGroup={setBrokerGroup}
              setFormData={setFormData}
              handleClearGroup={handleClearGroup}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SendLeadModal;
