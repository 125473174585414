import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Box,
} from "@chakra-ui/react";
import useApi from "hooks/useApi";
import { showToast } from "components/toast/Toast";
import { CenteredSpinner } from "components/loader/Loader";
import { HOMEPAGE_TYPES } from "constants/dbEnums";
import { HOMEPAGE_SECTIONS } from "constants/dbEnums";
import CitySelectInput from "components/addForm/location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";
import { UPDATE_HOMEPAGE_DATA } from "constants/endpoints";

const UpdateHomepageDataModal = ({ isOpen, onClose }) => {
  const { apiCall, loading } = useApi();
  const [form, setForm] = useState({});

  const handleFormSubmit = useCallback(
    async (updateAll = false) => {
      try {
        let payload = form;
        if (updateAll) payload = { updateAll: true };
        const response = await apiCall.patch(UPDATE_HOMEPAGE_DATA, payload);
        if (response?.data) {
          setForm({});
          showToast({
            message: response?.data,
            success: true,
            customStyle: {
              background: "#52c41a",
              color: "#fff",
              padding: "10px",
            },
          });
          onClose();
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error?.message || error?.response?.data?.error || error.message;
        showToast({
          message: `Error: ${errorMessage}`,
          success: false,
          customStyle: {
            background: "#F24052",
            color: "#fff",
            padding: "10px",
          },
        });
        if (error.response?.status !== 409 && error.response?.status !== 400) {
          onClose();
        }
      }
    },
    [form]
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "page_type") setForm((prev) => ({ ...prev, section: "" }));
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleCityChange = useCallback((e) => {
    const { title } = e.target?.value;
    setForm((prev) => ({ ...prev, city: title }));
  }, []);

  const isButtonDisabled = useMemo(() => {
    if (!form?.city || !form?.page_type || !form?.section) return true;
    return false;
  }, [form]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Homepage Data:</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            <FormControl mt={3} isRequired>
              <FormLabel>Homepage Type</FormLabel>
              <Select
                placeholder="Select Homepage Type"
                value={form.homepageType}
                onChange={handleChange}
                name="page_type"
              >
                {HOMEPAGE_TYPES?.map((option, index) => (
                  <option id={index} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel>Section</FormLabel>
              <Select
                placeholder="Select Section"
                value={form.section}
                onChange={handleChange}
                name="section"
                isDisabled={!form.page_type}
              >
                {HOMEPAGE_SECTIONS[form?.page_type]?.map((option, index) => (
                  <option key={index} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={3} isRequired>
              <CitySelectInput
                isRequired
                name="city"
                label="City"
                onChange={handleCityChange}
                result_type={LOCATION_TYPE.CITY}
                selectedValue={form?.city}
              />
            </FormControl>
            {loading && <CenteredSpinner />}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button
              colorScheme="green"
              borderRadius={8}
              onClick={() => handleFormSubmit(true)}
            >
              Update All Data
            </Button>
           <Box>
           <Button
              colorScheme="blue"
              mr={3}
              borderRadius={8}
              onClick={handleFormSubmit}
              isDisabled={isButtonDisabled}
            >
              Update
            </Button>
            <Button borderRadius={8} onClick={onClose}>Cancel</Button>
           </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateHomepageDataModal;
