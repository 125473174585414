import { Box, Flex, FormLabel } from "@chakra-ui/react";
import MultipleImageUpload from "./multipleImageUpload";
import { DeleteIcon } from "@chakra-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import ErrorMessage from "../ErrorMessage";
import CheckboxComponent from "../Checkbox";
import qs from "qs";
import { getCoverStatus } from "constants/commonFunctions";

const Image = ({ onChange, name, multiple = true, error, label, accept, maxLenght, value=[] }) => {
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    const isSelectedImagesEmptyOrAllEmptyStrings = !selectedImages?.length || selectedImages.every(img => img === '');
    if(isSelectedImagesEmptyOrAllEmptyStrings && value?.length>0){
      setSelectedImages(value);
    }
  }, [value]);

  const removeImage = useCallback(
    (index) => {
      setSelectedImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        const defaultValue = updatedImages.length === 0 && maxLenght === '1' ? '' : updatedImages;
        onChange({
          target: {
            name,
            value: defaultValue,
          },
        });
        return updatedImages;
      });
    },
    [name, onChange]
  );

const handleCheckboxChange = useCallback(
  (e, image) => {
    const { checked: isChecked } = e.target;

    const updatedImages = selectedImages.map((img) => {
      
      const imagePath = img?.name ?? img
      const [path, query] = imagePath.split("?");
      const params = qs.parse(query);

      if (imagePath === image || imagePath === image?.name) {
        isChecked ? (params.cover = "true") : (params.cover = "false");
      } else {
        delete params.cover;
      }

      const newPath = `${path}${
        Object.keys(params).length ? `?${qs.stringify(params)}` : ""
      }`

      if (img?.name) {
        return new File([img], newPath, {
          type: img.type,
          lastModified: img.lastModified,
        });
      } else{
        return newPath
      }
    });

    setSelectedImages(updatedImages);
    onChange({
      target: {
        name,
        value: updatedImages,
      },
    });
  },
  [onChange, name]
);

  const renderImage = (image) => {
    const baseUrl = process.env.REACT_APP_IMAGE_CDN_URL;
  
    const renderCheckboxAndLink = (
      url,
      label,
      selectedValues,
      imageName,
      imageData,
      isDisabled = false
    ) => (
      <Box display="flex" alignItems="center" width={"90%"} marginTop={2} gap={"3"}>
          <CheckboxComponent
            options={[{ label: "Mark Cover", value: "true" }]}
            name="isCover"
            selectedValues={selectedValues}
            onChange={(e) => handleCheckboxChange(e, image)}
            label={label}
            textSize="lg"
            checkboxSize="lg"
            disabled={isDisabled}
          />

        <Box width={"50%"}>
          <img src={imageData ? imageData : url} alt="Uploaded image" />
        </Box>
      </Box>
    );
  
    // if (image?.name) {
    //   return image?.name; 
    // }
    const isCover = getCoverStatus(image?.name ? image?.name : image );
    const objectURL = image?.name ? URL.createObjectURL(image) : null
    return renderCheckboxAndLink(
      image?.name ? image?.name :`${baseUrl}${image}`,
      "",
      isCover ? "true" : undefined,
      image?.name,
      objectURL
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="w-1/3 h-[180px]">
        <FormLabel>{label ? label : "Upload Images (max 10)"}</FormLabel>
          <MultipleImageUpload
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            onChange={onChange}
            name={name}
            accept={accept}
            maxLenght={maxLenght}
            multiple={multiple}
          />
        </div>
        {selectedImages?.length > 0 && (
          <div className="flex flex-col gap-6">
            {Array.isArray(selectedImages) && selectedImages?.map((image, index) => (
              <>
                <Flex alignItems={"center"}>
                    {renderImage(image)}
                    <Box
                      as="span"
                      color="red.500"
                      ml={1}
                      onClick={() => removeImage(index)}
                    >
                      <DeleteIcon />
                    </Box>
                </Flex>
              <ErrorMessage error={error && error[index]}/>
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default Image;
