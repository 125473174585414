import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import ExpiryUpdateForm from "./ExpiryUpdateForm";
import { useState } from "react";
import { submitForm } from "../services/expiryUpdateService";
import useApi from "hooks/useApi";
import { showToast } from "components/toast/Toast";
import { formatDateToUTC } from "constants/commonFunctions";

const initialFormData = {
  _id: "",
  expires_on: "",
  type: "",
};

const ExpiryUpdateModal = ({ isOpen, onClose, selectedRowsData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { apiCall } = useApi();
  console.log("formdata", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedDate = formatDateToUTC(value);
    setFormData((prev) => ({
      ...prev,
      [name]: formattedDate,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmittingForm(true);
      const payload = {
        updateData: selectedRowsData.map((row) => ({
          _id: row._id,
          expires_on: formData.expires_on,
          type: row.collection_type,
        })),
      };
      const response = await submitForm({ apiCall, payload });
      if (response.data) {
        setIsSubmittingForm(false);
      }
    } catch (error) {
      console.error("Error submitting expiry date:", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction={{ base: "column", md: "column" }} gap={2}>
            <ExpiryUpdateForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              disabled={isSubmittingForm}
              adsList={selectedRowsData}
              isSubmittingForm={isSubmittingForm}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExpiryUpdateModal;
