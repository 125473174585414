import React from "react";
import { Switch, FormControl, FormLabel, VStack } from "@chakra-ui/react";

const ToggleSwitch = ({
  isChecked,
  onChange,
  colorScheme = "blue",
  size = "sm",
  name,
  label,
}) => {
  return (
    <FormControl>
      <VStack align="start" spacing={1}>
        <FormLabel
          htmlFor="toggle-switch"
          color="red.500"
          fontWeight="bold"
          fontSize="md"
        >
          {label}
        </FormLabel>
        <Switch
          id="toggle-switch"
          isChecked={isChecked}
          onChange={onChange}
          colorScheme={colorScheme}
          size={size}
          name={name}
        />
      </VStack>
    </FormControl>
  );
};

export default ToggleSwitch;
