import { getTagsForSelectedInput } from "utils/common";
import { PROJECT_PROPERTY_TYPE, PROPERTY_COLLECTIONS } from "./dbEnums";

export const adType = {
  Sale: "sell",
  Rent: "rent",
};

export const transactionType = {
  Residential: "residential_property",
  Commercial: "commercial_property",
  Plot: "plot",
  PG: "pg",
  Share_Flat: "share_flat",
};

export const COLLECTION_TYPE = {
  Builder: "builders",
  Company: "company_account",
  Agent: "agents"
}

export const AGENT_TYPES = {
  COMPANY_AGENT: "Agent with Company",  
  INDIVIDUAL_AGENT: "Independent Agent" 
};

export const SEARCH_FOR = {
  USER: "user",
  COMPANY: "company",
  BUILDER: "builder",
  BROKER_GROUP: "brokerGroup",
};

export const PROPERTY_TYPE_OPTIONS = getTagsForSelectedInput([
  PROJECT_PROPERTY_TYPE.RESIDENTIAL_PROPERTY,
  PROJECT_PROPERTY_TYPE.COMMERCIAL_PROPERTY,
  PROPERTY_COLLECTIONS.PG
]);

export const USER_REQUIREMENT_ACTIONS = {
  ADD_REQUIREMENT: "Add Requirement",
  EDIT_REQUIREMENT: "Edit Requirement",
  VIEW_REQUIREMENT: "View Requirement",
  SEND_LEAD: "Send Lead",
  DELETE_REQUIREMENT: "Delete Requirement"
};

export const LEAD_FILTER_TYPES = {
  ALL_LEADS: "all_leads",
  SHARED: "shared",
  UNSHARED: "unshared",
};

export const LEAD_TYPES_TAGS = {
  all_leads: "All Leads",
  shared: "Shared",
  unshared: "Unshared",
};

export const INTERESTED_IN_SHARED_ACCOMMODATION = [
  {value: "true", label: "Interested in Shared Accommodation"},
];

export const SHOW_SHAREFLAT_FORM = [
  {value: "true", label: "Show Room/Apartment Form"},
];