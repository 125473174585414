import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Menu, MenuButton, IconButton, MenuList, MenuItem } from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";

const UserActivityTable = ({ data = [], columns, onMakeImportant, onOptionClick, actions = [] }) => {
  return (
    <Table
      variant="simple"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
    >
      <Thead>
        <Tr>
          {columns.map((column, index) => (
            <Th key={index} padding="0.2rem">
              {column.label}
            </Th>
          ))}
          {actions.length > 0 && <Th>Actions</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={index}>
            {columns.map((column, index) => (
              <Td key={index} padding="0.2rem" lineHeight="1.2" fontSize={13}>
                {(column.render
                  ? column.render(row, onMakeImportant)
                  : row[column.accessor]) || "-"}
              </Td>
            ))}
            {actions.length > 0 && row?.user_data?._id && (
              <Td padding="0.2rem" textAlign="right">
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<BsThreeDotsVertical />}
                    variant="ghost"
                    aria-label="Actions"
                  />
                  <MenuList>
                    {actions.map((action, actionIndex) => (
                      <MenuItem
                        key={actionIndex}
                        onClick={() => onOptionClick(row, action.action)}
                      >
                        {action.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Td>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default UserActivityTable;
