import { showToast } from "components/toast/Toast";
import { CHANGE_SUBSCRIPTION_API, BROKER_GROUP_DETAILS, BROKER_GROUP_API } from "constants/endpoints";

export const submitForm = async ({ apiCall, payload, plans }) => {
  try {
    const method = payload.group_id ? "patch" : "post";
    const url = BROKER_GROUP_API;
    if(plans){
      const plan = plans.find(plan => plan.name === "Brokers" || (plan.features && plan.features.hasOwnProperty('admin_lead')));
    await apiCall.post(CHANGE_SUBSCRIPTION_API, {
      plan_id: plan._id,
      user_ids: payload?.users,
    })
    }
    const response = await apiCall[method](url, payload);
    if (response) {
      showToast({
        message: payload?._id
          ? response.data.message
          : response.data.message,
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating requirement:", error);
    showToast({
      message: error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};

export const filterUnusedData = (data) => {
  const locationKey = data.address ? 'address' : data.location ? 'location' : null;
  if(locationKey && data[locationKey]){
    const filteredLocation = Object.fromEntries(
      Object.entries(data[locationKey]).filter(([key, value]) => value !== "")
    );
    data[locationKey] = filteredLocation;
  }
  return data;
};

export const getFormData = async ({ apiCall, groupId }) => {
  try {
    const url = `${BROKER_GROUP_DETAILS}?group_id=${groupId}`;
    const response = await apiCall.get(url);
    return response?.data;
  } catch (error) {
    console.error("Error fetching form:", error);
    showToast({
      message: error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error;
  }
};
