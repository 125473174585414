import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  Text,
  Flex,
  Textarea,
  Checkbox,
  Input,
} from "@chakra-ui/react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    actions: [],
    showInputBox: false,
    showEmailCheckBox: false,
    customListingScore: 0,
    showListingScoreInput: false
  });
  const [comment, setComment] = useState("")
  const [sendEmail, setSendEmail] = useState(false);

  const openModal = (config) => {
    if(config?.comment) setComment(config?.comment);
    setSendEmail(false);
    setModalConfig({
      isOpen: true,
      ...config,
    });
  };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  }

  const closeModal = () => {
    setModalConfig((prev) => ({ ...prev, isOpen: false }));
    setComment("");
  };

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const buttonColorScheme = useColorModeValue("blue", "teal");

  const isButtonDisabled = useMemo(() => {
    const { showListingScoreInput, customListingScore } = modalConfig;
    if(showListingScoreInput && (customListingScore > 100 || customListingScore < 0)) return true;
    return false;
  },[modalConfig])

  return (
    <ModalContext.Provider value={{ openModal, closeModal, comment, handleInputChange }}>
      {children}
      <Modal
        isOpen={modalConfig.isOpen}
        onClose={closeModal}
        isCentered
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent
          bg={bgColor}
          boxShadow="xl"
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          mx={4} // Add margin for small screens
          p={4} // Add padding inside the modal content
          width="100%" // Ensure the modal content takes full width
          maxWidth="500px" // Restrict the maximum width of the modal
        >
          <ModalHeader fontSize="lg" fontWeight="bold" color="black">
            {modalConfig.title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={"column"} gap={"10px"}>
            <Text
              fontSize="md"
              color="gray.600"
              dangerouslySetInnerHTML={{ __html: modalConfig.bodyText }}
            />
            {modalConfig.showListingScoreInput && (
              <Flex flexDirection={"column"} gap={"5px"}>
              <Text mb="8px">Update Listing score:</Text>
              <Input type="number" value={modalConfig.customListingScore} onChange={(e) => setModalConfig((prev) => ({...prev, customListingScore: e.target.value}))} />
            </Flex>
            )}
            {modalConfig.showInputBox && (
              <Flex flexDirection={"column"} gap={"5px"}>
              <Text mb="8px">Comment</Text>
              <Textarea
                value={comment}
                onChange={handleInputChange}
                placeholder="Please add comment"
                size="sm"
                resize={"none"}
              />
              {
                modalConfig?.showEmailCheckBox && <Checkbox value='send_email' onChange={() => setSendEmail((prev) => !prev)}>Send Email</Checkbox>
              }
            </Flex>
            )}
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            {modalConfig.actions.map((action, index) => (
              <Button
                key={index}
                colorScheme={buttonColorScheme}
                disabled={isButtonDisabled}
                onClick={() => {
                  action.onClick(comment, sendEmail, modalConfig.customListingScore);
                  closeModal();
                }}
                mx={1} // Add margin between buttons
                background="linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"
                _hover={{
                  background:
                    "linear-gradient(180deg, #1A94D4 0%, #016299 100%)",
                }}
                _active={{
                  background:
                    "linear-gradient(180deg, #158AC2 0%, #01517E 100%)",
                }}
              >
                {action.label}
              </Button>
            ))}
            <Button variant="outline" onClick={closeModal} mx={1}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
