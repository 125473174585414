import React from "react";
import ValidatedInput from "../ValidatedInput";
import { Box, Flex, FormLabel } from "@chakra-ui/react";
import SelectInput from "../SelectInput";
import MultiSelect from "../MultiSelect";
import CheckboxComponent from "../Checkbox";
import AmenitiesInput from "../AmenitiesInput";
import CalendarInput from "../CalendarInput";
import {
  GENDER,
  PROFESSION_OPTIONS,
  ROOM_TYPE_OPTIONS,
  OTHER_ROOM_OPTIONS,
  FURNISH_TYPE,
  INDOOR_AMENITIES,
  PROPERTY_MANAGED_BY,
  MANAGER_STAYS_AT_PG,
  SECURITY_DEPOSIT_TYPE,
  is_price_negotiable,
  is_electricity_and_water_charge,
  INCLUDED_SERVICES,
  MEALS_AVAILABLE,
  MEALS_TYPE,
  MEALS,
  NOTICE_PERIOD,
  LOCK_IN_PERIOD,
} from "constants/dbEnums";
import { ROOM_TYPE } from "constants/dbEnums";
import AutoSuggestComponent from "../location/locality";
import Image from "../imageUpload/index";
import { PG_RULES } from "constants/dbEnums";
import { AMENITIES } from "constants/dbEnums";
import { GATE_TIMING } from "constants/dbEnums";
import { COMMON_AREAS } from "constants/dbEnums";
import DescriptionGenerator from "../autoGenerateDescription";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const pgForm = {
  pg_name: (props) => (
    <ValidatedInput
      name="pg_name"
      label="PG Name"
      value={props.value}
      placeholder="PG/Hostel name"
      type="text"
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  preferred_tenant: (props) => (
    <SelectInput
      name="preferred_tenant"
      label="PG/Hostel For"
      options={GENDER}
      propertyType="preferred_tenant"
      selectedValue={props.value}
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  suitable_for: (props) => (
    <MultiSelect
      name="suitable_for"
      label="Best Suitable for"
      options={PROFESSION_OPTIONS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      isRequired={true}
      error={props.error}
    />
  ),
  total_floors: (props) => (
    <ValidatedInput
      name="total_floors"
      label="Total Floors"
      value={props.value}
      placeholder="Enter no. of Floors"
      type="number"
      isRequired={true}
      {...props}
      valueInNumber={true}
      error={props.error}
    />
  ),
  room_sharing_type: (props) => (
    <MultiSelect
      name="room_sharing_type"
      label="Room Sharing Type"
      options={ROOM_TYPE_OPTIONS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      isRequired={true}
      error={props.error}
    />
  ),
  capacity_and_availability: (props) => {
    const { formData, onChange, error } = props;
    const handleRoomChange = (e, roomType) => {
      const { value: updatedvalue, name, checked } = e.target;
      onChange({
        target: {
          name: "capacity_and_availability",
          value: {
            ...formData.capacity_and_availability,
            [roomType]: {
              ...formData?.capacity_and_availability[roomType],
              ...(name === "amenities"
                ? { [updatedvalue]: checked }
                : { [name]: updatedvalue }),
            },
          },
        },
      });
    };
    return (
      <>
        {formData?.room_sharing_type?.map((roomType) => {
           const selectedValuesArray = Object.keys(formData?.capacity_and_availability?.[roomType] || {})
           .filter(key => formData?.capacity_and_availability?.[roomType][key] === true);
          return (
            <Flex gap={4}>
              <div style={{ flex: 2 }}>
                <ValidatedInput
                  name="rooms"
                  placeholder="Enter number of rooms available"
                  type="number"
                  isRequired={true}
                  onChange={(e) => handleRoomChange(e, roomType)}
                  label={`Available no. of ${ROOM_TYPE[roomType]} rooms in PG`}
                  valueInNumber={true}
                  error={error?.[roomType]?.rooms}
                  value={formData?.capacity_and_availability?.[roomType]?.rooms}
                />
              </div>
              <div style={{ flex: 3 }}>
                <CheckboxComponent
                  options={OTHER_ROOM_OPTIONS}
                  name="amenities"
                  selectedValues={selectedValuesArray}
                  onChange={(e) => handleRoomChange(e, roomType)}
                />
              </div>
            </Flex>
          );
        })}
      </>
    );
  },
  furnish_type: (props) => (
    <SelectInput
      name="furnish_type"
      label="Select the Furnish Type"
      options={FURNISH_TYPE}
      propertyType="furnish_type"
      selectedValue={props.value}
      isRequired={true}
      {...props}
    />
  ),
  furnish_type_items: (props) => {
    const { formData, onChange, error } = props;
    if (formData.furnish_type !== "unfurnished" && formData.furnish_type) {
      return (
        <AmenitiesInput
          options={INDOOR_AMENITIES}
          onChange={onChange}
          name="furnish_type_items"
          furnishType={formData?.furnish_type}
          error={error}
          selectedValue={formData?.furnish_type_items ? formData?.furnish_type_items : {}}
        />
      );
    }
  },
  covered_parking: (props) => (
    <ValidatedInput
      name="covered_parking"
      value={props.value}
      placeholder="No. of Covered Parking"
      type="number"
      onChange={props.onChange}
      label="Covered Parking"
      valueInNumber={true}
      error={props.error}
    />
  ),
  open_parking: (props) => (
    <ValidatedInput
      name="open_parking"
      value={props.value}
      placeholder="No. of Open Parking"
      type="number"
      onChange={props.onChange}
      label="Open Parking"
      valueInNumber={true}
      error={props.error}
    />
  ),
  property_managed_by: (props) => (
    <SelectInput
      name="property_managed_by"
      label="Property managed By"
      options={PROPERTY_MANAGED_BY}
      propertyType="furnish_type"
      selectedValue={props.value}
      error={props.error}
      {...props}
    />
  ),
  manager_stays_at_pg: (props) => {
    const { onChange, error, formData } = props;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name: name,
          value: checked === true ? "yes" : "no",
        },
      });
    };
    return (
      <CheckboxComponent
        options={MANAGER_STAYS_AT_PG}
        name="manager_stays_at_pg"
        {...(formData?.manager_stays_at_pg && formData?.manager_stays_at_pg === "yes" && {selectedValues: "yes"})}
        onChange={(e) => handlChange(e)}
        label="Manager Stays at PG"
        textSize="lg"
        checkboxSize="lg"
        error={error}
      />
    );
  },
  available_from: (props) => (
    <CalendarInput
      name="available_from"
      label="Available From"
      value={props.value}
      isRequired={true}
      error={props.error}
      {...props}
    />
  ),
  pricing: (props) => {
    const { formData, onChange, value, error } = props;

    const handleChange = (e, roomType) => {
      const { name, value: updatedValue } = e.target;
      const updatedPricing = {
        ...formData.pricing,
        [roomType]: {
          ...formData.pricing[roomType],
          ...(name === "price"
            ? { [name]: Number(updatedValue) }
            : {
                security_deposit: {
                  ...formData.pricing[roomType]?.security_deposit,
                  [name]: name === "type" ? updatedValue : Number(updatedValue),
                  ...(name === "month" && {
                    amount: formData.pricing[roomType].price * Number(updatedValue),
                  }),
                },
              }),
        },
      };
      onChange({
        target: {
          name: "pricing",
          value: updatedPricing,
        },
      });
    };
    return (
      <>
        {formData?.room_sharing_type?.map((roomType) => {
          const securityDepositTypes = formData?.pricing?.[roomType]?.security_deposit?.type === "fixed" ? "amount" : "month";
          return (
            <>
              <FormLabel>Pricing details of {ROOM_TYPE[roomType]} rooms{" "}</FormLabel>
              <Flex gap={4}>
                <div style={{ flex: 2 }}>
                  <ValidatedInput
                    name="price"
                    placeholder="Rent amount per room"
                    type="number"
                    onChange={(e) => handleChange(e, roomType)}
                    label="Rent Amount (Rs)"
                    isRequired={true}
                    valueInNumber={true}
                    error={error?.[roomType]?.price ? error?.[roomType]?.price : error?.[roomType]}
                    value={formData?.pricing?.[roomType]?.price}
                  />
                </div>
                <div style={{ flex: 3 }}>
                  <Flex direction="column" alignItems="center" gap={4}>
                    <CheckboxComponent
                      options={SECURITY_DEPOSIT_TYPE}
                      name="type"
                      selectedValues={
                        formData?.pricing?.[roomType]?.security_deposit?.type ??
                        ""
                      }
                      onChange={(e) => handleChange(e, roomType)}
                      label="Security Deposit"
                      disabled={!formData?.pricing?.[roomType]?.price}
                    />
                    {formData?.pricing?.[roomType]?.security_deposit?.type &&
                      formData?.pricing?.[roomType]?.security_deposit?.type !==
                        "none" && (
                        <ValidatedInput
                          name={
                            formData?.pricing?.[roomType]?.security_deposit
                              ?.type === "fixed"
                              ? "amount"
                              : "month"
                          }
                          placeholder={
                            formData?.pricing?.[roomType]?.security_deposit
                              ?.type === "fixed"
                              ? "Enter the amount in rs"
                              : "Enter no. of months (max 36)"
                          }
                          type="number"
                          onChange={(e) => handleChange(e, roomType)}
                          label={formData?.pricing?.[roomType]?.security_deposit
                            ?.type === "fixed"
                            ? "Amount in RS"
                            : "Months (max 36)"}
                          valueInNumber={true}
                          isRequired={true}
                          error={error?.[roomType]?.security_deposit?.[securityDepositTypes]}
                          value={formData?.pricing?.[roomType]?.security_deposit
                            ?.type === "fixed" ? formData?.pricing?.[roomType]?.security_deposit?.amount : formData?.pricing?.[roomType]?.security_deposit?.month}
                        />
                      )}
                  </Flex>
                </div>
              </Flex>
            </>
          );
        })}
      </>
    );
  },
  maintenance_charges: (props) => (
    <ValidatedInput
      name="maintenance_charges"
      value={props.value}
      placeholder="Enter maintenance charges"
      type="number"
      onChange={props.onChange}
      label="Maintenance Charges (per month)"
      valueInNumber={true}
      error={props.error}
    />
  ),
  booking_amount: (props) => (
    <ValidatedInput
      name="booking_amount"
      value={props.value}
      placeholder="Enter booking amount"
      type="number"
      onChange={props.onChange}
      label="Booking Amount (Rs)"
      valueInNumber={true}
      error={props.error}
    />
  ),
  other_charges: (props) => (
    <ValidatedInput
      name="other_charges"
      value={props.value}
      placeholder="Enter other charges"
      type="number"
      onChange={props.onChange}
      label="Other Charges (Rs)"
      valueInNumber={true}
    />
  ),
  negotiable: (props) => (
    <Flex gap={4}>
      <div style={{ flex: 2 }}>
        <CheckboxComponent
          options={is_price_negotiable}
          name="negotiable"
          {...(props?.formData?.negotiable && {selectedValues: "true"})}
          onChange={props.onChange}
          label="Is price negotiable"
          textSize="lg"
          checkboxSize="lg"
        />
      </div>
      <div style={{ flex: 3 }}>
        <CheckboxComponent
          options={is_electricity_and_water_charge}
          name="is_electricity_and_water_charge"
          {...(props?.formData?.is_electricity_and_water_charge && {selectedValues: "true"})}
          onChange={props.onChange}
          label="Is electricity charges included"
          textSize="lg"
          checkboxSize="lg"
        />
      </div>
    </Flex>
  ),
  included_services: (props) => (
    <MultiSelect
      name="included_services"
      label="Select Included Services"
      options={INCLUDED_SERVICES}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
    />
  ),
  meals_available: (props) => (
    <SelectInput
      name="meals_available"
      label="Meals Available"
      options={MEALS_AVAILABLE}
      propertyType="meals_available"
      selectedValue={props.value}
      isRequired={true}
      {...props}
    />
  ),
  meals_available_data: (props) => {
    const { formData, onChange } = props;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      onChange({
        target: {
          name,
          value: updatedValue,
        },
      });
    };
    if (formData.meals_available !== "no") {
      return (
        <Flex gap={4}>
          <div style={{ flex: 2 }}>
            <SelectInput
              name="meal_type"
              label="Meal Type"
              options={MEALS_TYPE}
              propertyType="meal_type"
              selectedValue={formData?.meal_type}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div style={{ flex: 3 }}>
            <MultiSelect
              name="meal_on_weekdays"
              label="Meal Available on Weekdays"
              options={MEALS}
              displayValue="name"
              value={formData?.meal_on_weekdays}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </Flex>
      );
    }
  },
  meals_amount: (props) => {
    const { formData, onChange } = props;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      onChange({
        target: {
          name,
          value: name === "meal_amount" ? Number(updatedValue) : updatedValue,
        },
      });
    };
    if (formData.meals_available !== "no") {
      return (
        <Flex gap={4}>
          <div stype={{ flex: 2 }}>
            <MultiSelect
              name="meal_on_weekends"
              label="Meal Available on Weekends"
              options={MEALS}
              displayValue="name"
              value={formData?.meal_on_weekends}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {formData.meals_available === "need_to_pay_extra" && (
            <div style={{ flex: 2 }}>
              <ValidatedInput
                name="meal_amount"
                placeholder="Enter meal amount"
                type="number"
                onChange={(e) => handleChange(e)}
                label="Meal Amount (Rs)"
                isRequired={true}
              />
            </div>
          )}
        </Flex>
      );
    }
  },
  notice_period: (props) => {
    const { onChange } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: Number(value),
        },
      });
    };
    return (
      <>
        <SelectInput
          name="notice_period"
          label="Notice Period (In months)"
          options={NOTICE_PERIOD}
          propertyType="notice_period"
          selectedValue={props.value}
          onChange={(e) => handleChange(e)}
        />
      </>
    );
  },
  lock_in_period: (props) => {
    const { formData, onChange, value } = props;

    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      const updatedData = {
        ...formData.lock_in_period,
        [name]: updatedValue,
      };
      onChange({
        target: {
          name: "lock_in_period",
          value: updatedData,
        },
      });
    };
    return (
      <Flex gap={4}>
        <div>
          <SelectInput
            name="type"
            label="Lock in Period"
            options={LOCK_IN_PERIOD}
            propertyType="lock_in_period"
            selectedValue={value.type}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {formData?.lock_in_period?.type === "custom" && (
          <div>
            <ValidatedInput
              name="period"
              value={props.value.period}
              placeholder="Enter no. of months"
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
              label="Months (max 30)"
              isRequired={true}
              valueInNumber={true}
            />
          </div>
        )}
      </Flex>
    );
  },
  location: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData?.location?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "location",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find((city) => city.name === formData?.location?.city);
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "location",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "location",
            value: {
              ...formData.location,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.location?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={formData?.location?.city ? formData.location?.city : selectedValue?.city}
              error={error}
              location={formData.location}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Enter appartment or area)"
              value={formData?.location?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  photos: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          value={formData?._id ? formData?.photos : []}
        />
      </>
    );
  },
  pg_rules: (props) => {
    const { onChange, value } = props;
    return (
      <>
        <MultiSelect
          name="pg_rules"
          label="PG Rules"
          options={PG_RULES}
          displayValue="name"
          value={value}
          onChange={onChange}
        />
      </>
    );
  },
  amenities: (props) => (
    <MultiSelect
      name="amenities"
      label="Select Amenities"
      options={AMENITIES}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
    />
  ),
  gate_timing: (props) => (
    <SelectInput
      name="gate_timing"
      label="Last Entry Time"
      options={GATE_TIMING}
      propertyType="gate_timing"
      selectedValue={props.value}
      onChange={props.onChange}
    />
  ),
  common_areas: (props) => (
    <MultiSelect
      name="common_areas"
      label="Common Areas"
      options={COMMON_AREAS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
    />
  ),
  description: (props) => {
    const { value, onChange, formData, error } = props;
    return (
      <DescriptionGenerator
        onChange={onChange}
        formData={formData}
        value={value}
        name="description"
        isRequired={true}
        label="Description"
        placeholder="Enter description or generate by AI"
        error={error}
      />
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <>
        {formData?._id && (
          <ValidatedInput
            name="slug_url"
            label="Slug Url"
            placeholder="Add Slug Url"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            value={formData.slug_url}
            error={error}
          />
        )}
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Project name"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter about"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
};

export default pgForm;
