import React, { useState, useEffect } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast,
  Box,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import useApi from "hooks/useApi";
import UserRequirementForm from "./UserRequirementForm";
import UserRequirementList from "./UserRequirementList";
import { submitRequirement } from "../../services/userRequirementsService";
import { showToast } from "components/toast/Toast";
import { USER_REQUIREMENTS_API } from "constants/endpoints";
import { filterUnusedData } from "views/admin/form/formHandlers";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";
import ShareFlatModal from "./ShareFlat/ShareFlatModal";
import { handleShareFlatSubmit } from "../../services/shareFlatService";
import { getFormData } from "views/admin/form/apiService";

const initialFormData = {
  property_type: "",
  ad_type: "",
  comment: "",
  location: {},
  shared_accommodation_interest: false,
  showShareFlat: false
};

const initialShareFormValue = {
  date_of_birth: "",
  gender: "",
  occupation: "",
  available_rooms: "",
  partner_gender: "",
  description: "",
  min_partner_age: 0,
  max_partner_age: 0,
  min_approx_price: 0,
  max_approx_price: 0,
  photos: [],
  designation: "",
  hobbies: "",
  languages: "",
  studying: "",
  social_links: {
    facebook: "",
    instagram: "",
    linkedin: ""
  },
  preferences: [],
  meta_title: "",
  meta_description: "",
  location: {},
  amenities: [],
  partner_occupation: [],
  total_floors: "",
  your_floor: ""
};

const UserRequirementModal = ({
  isOpen,
  onClose,
  userId,
  selectedRequirement,
  afterRequirementsUpdate,
  selectedData,
  disableSubmitButton,
}) => {
  const [requirements, setRequirements] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [shareFlatData, setShareFlatData] = useState({});
  console.log('shareFlatData...', shareFlatData);
  const [shareFlatErrors, setShareFlatErrors] = useState({});
  const toast = useToast();
  const { apiCall } = useApi();
  console.log('requirement data', formData);

  const fetchRequirements = async () => {
    try {
      const url = `${USER_REQUIREMENTS_API}?user_id=${userId}`;
      const response = await apiCall.get(url);
      setRequirements(response.data.requirements);
    } catch (error) {
      console.error("Error fetching requirements:", error);
    }
  };

  const getShareFlatFn = async (requirement) => {
    try {
      const response = await getFormData({
        apiCall,
        propertyId: userId,
        collectionType: "share_flat",
        searchBy: "owner_id",
      });
      if(response){
       // Open share flat modal on every edit
       setFormData({...requirement, showShareFlat: true});
       setShareFlatData(response);
      }
    } catch (error) {
      console.log("Error fetching shareflat data", error);
    }
 };

  useEffect(() => {
    if (isOpen) {
      fetchRequirements();
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        [name]: value,
      };
      /**If property_type is 'plot', set ad_type to 'sale'**/
      if (name === "property_type") {
        if(value === PROPERTY_COLLECTIONS.PLOT) {
          updatedFormData.ad_type = AD_TYPE_TAGS.SELL;
        }
        if(value === PROPERTY_COLLECTIONS.PG) {
          updatedFormData.ad_type = AD_TYPE_TAGS.RENT;
        }
      }
      if(name === "ad_type" && value === REQUIREMENT_TYPE_TAGS.BUY){
        updatedFormData.shared_accommodation_interest = false;
      }
      return updatedFormData;
    });
  };

  const handleSubmit = async () => {
    try {
        setIsSubmittingForm(true);
        const payload = {
            property_type: formData.property_type,
            ad_type: formData.ad_type,
            comment: formData.comment,
            location: formData.location,
            ...(formData._id ? { requirement_id: formData?._id } : { user_id: userId }),
            shared_accommodation_interest: formData.shared_accommodation_interest
        };
        filterUnusedData(payload)
      if (formData.showShareFlat) {
        const userData = { ...formData, userId };
        try {
          const response = await handleShareFlatSubmit({
            shareFlatData,
            setShareFlatData,
            initialShareFormValue,
            userData,
            setShareFlatErrors,
            apiCall,
          });
          if (response?.error && Object.keys(response?.error).length > 0) {
            console.log("Shareflat Validation errors:", response);
            setIsSubmittingForm(false);
            return;
          }
        } catch (error) {
          console.error("Error during share form submission:", error);
          setIsSubmittingForm(false);
          showToast({
            message: error,
            success: false,
            customStyle: {
              background: "#F24052",
              color: "#fff",
              padding: "10px",
            },
          });
        }
      }
      const response = await submitRequirement({apiCall, payload});
      if(response.data){
        setIsSubmittingForm(false);
        handleReset();
        await fetchRequirements();
        if(afterRequirementsUpdate){
          afterRequirementsUpdate(response.data);
        }
      }
    } catch (error) {
      console.error("Error submitting requirement:", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const handleEdit = (requirement) => {
    setFormData({...requirement, showShareFlat: requirement.shared_accommodation_interest});
    if(requirement._id){
      getShareFlatFn(requirement);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/user-requirements/${id}`
      );
      fetchRequirements();
      toast({
        title: "Requirement deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting requirement:", error);
    }
  };

  const handleLocation = (e) => {
    const { value: selectedValue } = e.target;
    const updatedValue = {
      city: selectedValue.name,
    };
    setFormData((prev) => ({
      ...prev,
      ["location"]: updatedValue,
    }));
  };

  const handleLocality = (e) => {
    const { name, value: selectedValue } = e.target;
    if (name === "locality") {
      const data = JSON.parse(selectedValue);
      setFormData((prev) => ({
        ...prev,
        ["location"]: data,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        ["location"]: {
          ...formData.location,
          [name]: selectedValue,
        },
      }));
    }
  };
  useEffect(() => {
    if (selectedRequirement && Object.keys(selectedRequirement).length > 0) {
      handleEdit(selectedRequirement);
    }
  }, [selectedRequirement]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction={{ base: "column", md: "column" }} gap={2}>
            {/* Form Section */}
            <UserRequirementForm
              formData={formData}
              handleChange={handleChange}
              handleLocation={handleLocation}
              handleLocality={handleLocality}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              editingId={formData._id}
              isDisabled={isSubmittingForm || disableSubmitButton}
              selectedData={selectedData}
              isSubmittingForm={isSubmittingForm}
              shareFlatErrors={shareFlatErrors}
            />

            {/* Share Flat Form */}
            {formData?.showShareFlat && (
              <>
                <Box my={3}>
                  <Divider borderColor="gray.700" />
                </Box>
                <ShareFlatModal
                  userData={{ ...formData, userId }}
                  setShareFlatData={setShareFlatData}
                  shareFlatData={shareFlatData}
                  shareFlatErrors={shareFlatErrors}
                />
              </>
            )}

            {/* List Section */}
            {requirements?.length > 0 && (
              <UserRequirementList
                requirements={requirements}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserRequirementModal;
