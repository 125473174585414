import { showToast } from "components/toast/Toast";
import { PROPERTY_TYPE } from "constants/dbEnums";
import { SEND_LEAD_API } from "constants/endpoints";
import { Text } from "@chakra-ui/react";
import { REQUIREMENT_TYPE } from "constants/dbEnums";

export const submitForm = async ({ apiCall, payload }) => {
  try {
    const url = SEND_LEAD_API;
    const response = await apiCall.post(url, payload);
    if (response) {
      showToast({
        message: response.data,
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error sending lead", error);
    showToast({
      message: error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};

export const renderGroupInfo = (item) => {
  return (
    <Text>
      Group Name:{" "}
      <Text as="span" fontWeight="bold">
        {item?.name}
      </Text>{" "}
      - Total Members:{" "}
      <Text as="span" color="red.500" fontWeight="bold">
        {item?.total_members}
      </Text>{" "}
      {item?.property_type && (
        <>
          - Property Type:{" "}
          <Text as="span" fontWeight="bold">
            {PROPERTY_TYPE[item?.property_type]}
          </Text>{" "}
        </>
      )}
      {item?.ad_type && (
        <>
          - Ad Type:{" "}
          <Text as="span" fontWeight="bold">
            {REQUIREMENT_TYPE[item?.ad_type]}
          </Text>{" "}
        </>
      )}
    </Text>
  );
};

export const validateUserRequirements = (usersList) => {
  return usersList.map(user => {
    let missingFields = [];
    
    // Check missing fields
    if (!user.ad_type) missingFields.push("Ad Type");
    if (!user.property_type) missingFields.push("Property Type");
    if (!user.city) missingFields.push("City");

    return {
      user_id: user.user_id,
      missingFields,
      name: user.user_name
    };
  }).filter(user => user.missingFields.length > 0);
};
