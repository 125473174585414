import React, { useCallback } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { useAuth } from 'contexts/AuthContext';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

function SignIn() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const { handleGoogleLogin } = useAuth();

  const handleVerifyMail = useCallback(
    async (signInMethod, credential) => {
      try {
        const response = await handleGoogleLogin({
          credential
        });

        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    [handleGoogleLogin]
  );

  const handleGoogleSuccess = useCallback(
    (credentialResponse) => {
      handleVerifyMail('google', credentialResponse?.credential);
      console.log(credentialResponse);
    },
    [handleVerifyMail]
  );

  const handleGoogleFail = () => {
    console.log('Login Failed');
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Sign in with Google to continue!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
          >
            <div className="flex flex-col  w-full xs:max-w-[440px]">
              <div className="flex flex-col ">
                <div className="flex flex-col gap-5 sm:gap-4">
                  <GoogleLogin
                    size="large"
                    text="continue_with"
                    theme="outline"
                    logo_alignment="center"
                    shape="rectangular"
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleFail}
                  />
                </div>
              </div>
            </div>
          </GoogleOAuthProvider>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
