import { AVAILABLE_ROOMS } from "constants/dbEnums";
import { ROOM_DETAILS } from "constants/dbEnums";
import { GENDER_TYPE } from "constants/dbEnums";
import { BHK_TAGS } from "constants/dbEnums";
import { AMENITIES_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { INDOOR_AMENITIES_TAGS } from "constants/dbEnums";
import { PREFERENCES_TAGS } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE_TAGS } from "constants/dbEnums";
import { OCCUPATION } from "constants/dbEnums";
import { FURNISH_TYPE_TAGS } from "constants/dbEnums";
import { SHARING_TYPE } from "constants/dbEnums";
import Joi from "joi";

const locationSchema = Joi.object({
  type: Joi.string(),
  label: Joi.string(),
  place_type: Joi.string(),
  coordinates: Joi.array().items(Joi.number()).optional(),
  city: Joi.string().required().trim(),
  state: Joi.string(),
  state_code: Joi.string(),
  country_code: Joi.string(),
  country: Joi.string(),
  locality: Joi.string().required(),
  sub_locality: Joi.string(),
  street: Joi.string().allow("").trim(),
  building: Joi.string(),
  postal_code: Joi.string(),
});

const shareFlatSchema = {
  create: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    sharing_type: Joi.string()
      .valid(...Object.values(SHARING_TYPE))
      .required(),
    description: Joi.string().required().trim(),
    available_from: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.date().min(new Date().setUTCHours(0, 0, 0, 0)).required(),
      otherwise: Joi.forbidden(),
    }),
    need_to_shift: Joi.when("sharing_type", {
      is: Joi.valid("need_flat"),
      then: Joi.date().min(new Date().setUTCHours(0, 0, 0, 0)),
      otherwise: Joi.forbidden(),
    }),
    furnish_type: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.string()
        .valid(...Object.values(FURNISH_TYPE_TAGS))
        .required(),
      otherwise: Joi.forbidden(),
    }),
    room_details: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.array().items(
        Joi.string().valid(...Object.values(ROOM_DETAILS))
      ),
      otherwise: Joi.forbidden(),
    }),
    occupation: Joi.string().valid(...Object.values(OCCUPATION)).required(),
    gender: Joi.string()
      .valid(...Object.values(GENDER_TYPE))
      .required(),
    partner_gender: Joi.string().valid(...Object.values(GENDER_TYPE)),
    partner_occupation: Joi.array().items(
      Joi.string().valid(...Object.values(OCCUPATION))
    ),
    min_partner_age: Joi.number().integer().min(18).max(60).required(),
    max_partner_age: Joi.number().integer().min(18).max(60).required(),
    date_of_birth: Joi.date().iso().required(),
    min_approx_price: Joi.when("sharing_type", {
      is: Joi.valid(SHARING_TYPE.NEED_FLAT),
      then: Joi.number().min(1000).required(),
      otherwise: Joi.forbidden(),
    }),
    max_approx_price: Joi.number().min(0).max(1000000).required(),
    negotiable: Joi.boolean(),
    electricity_charges_included: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.boolean(),
      otherwise: Joi.forbidden(),
    }),
    maintenance_charges: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    security_deposit: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.object({
        type: Joi.string().valid(
          ...Object.values(SECURITY_DEPOSIT_TYPE_TAGS),
          ""
        ),
        amount: Joi.when("type", {
          is: ["fixed", "multiple_of_rents"],
          then: Joi.number().min(0).required(),
          otherwise: Joi.number().valid(0),
        }),
      }),
      otherwise: Joi.forbidden(),
    }),
    other_charges: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    booking_amount: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    total_floors: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number().min(0).max(99).required(),
      otherwise: Joi.forbidden(),
    }),
    your_floor: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number()
        .required()
        .max(Joi.ref("total_floors"))
        .message('"your_floor" must be less than or equal to "total_floors"'),
      otherwise: Joi.forbidden(),
    }),
    bhk: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.string()
        .valid(...Object.values(BHK_TAGS))
        .required(),
      otherwise: Joi.forbidden(),
    }),
    preferences: Joi.array().items(
      Joi.string()
        .valid(...Object.values(PREFERENCES_TAGS))
        .trim()
    ),
    amenities: Joi.array().items(
      Joi.string().valid(...Object.values(AMENITIES_TAGS))
    ),
    photos: Joi.array().items(Joi.string().trim()),
    location: locationSchema.required(),
    furnish_type_items: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.when("furnish_type", {
        is: "fully_furnished",
        then: Joi.object()
          .pattern(
            Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
            Joi.number().min(1)
          )
          .min(5)
          .required(),
        otherwise: Joi.when("furnish_type", {
          is: "semi_furnished",
          then: Joi.object()
            .pattern(
              Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
              Joi.number().min(1)
            )
            .min(3)
            .required(),
          otherwise: Joi.forbidden(),
        }),
      }),
      otherwise: Joi.forbidden(),
    }),
  }).unknown(),
  update: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    sharing_type: Joi.string()
      .valid(...Object.values(SHARING_TYPE))
      .required(),
    description: Joi.string().required().trim(),
    available_from: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.date().min(new Date().setUTCHours(0, 0, 0, 0)).required(),
      otherwise: Joi.forbidden(),
    }),
    need_to_shift: Joi.when("sharing_type", {
      is: Joi.valid("need_flat"),
      then: Joi.date().min(new Date().setUTCHours(0, 0, 0, 0)),
      otherwise: Joi.forbidden(),
    }),
    furnish_type: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.string()
        .valid(...Object.values(FURNISH_TYPE_TAGS))
        .required(),
      otherwise: Joi.forbidden(),
    }),
    room_details: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.array().items(
        Joi.string().valid(...Object.values(ROOM_DETAILS))
      ),
      otherwise: Joi.forbidden(),
    }),
    occupation: Joi.string().valid(...Object.values(OCCUPATION)).required(),
    gender: Joi.string()
      .valid(...Object.values(GENDER_TYPE))
      .required(),
    partner_gender: Joi.string().valid(...Object.values(GENDER_TYPE)),
    partner_occupation: Joi.array().items(
      Joi.string().valid(...Object.values(OCCUPATION))
    ),
    min_partner_age: Joi.number().integer().min(18).max(60).required(),
    max_partner_age: Joi.number().integer().min(18).max(60).required(),
    date_of_birth: Joi.date().iso().required(),
    min_approx_price: Joi.when("sharing_type", {
      is: Joi.valid(SHARING_TYPE.NEED_FLAT),
      then: Joi.number().min(1000).required(),
      otherwise: Joi.forbidden(),
    }),
    max_approx_price: Joi.number().min(0).max(1000000).required(),
    negotiable: Joi.boolean(),
    electricity_charges_included: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.boolean(),
      otherwise: Joi.forbidden(),
    }),
    maintenance_charges: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    security_deposit: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.object({
        type: Joi.string().valid(
          ...Object.values(SECURITY_DEPOSIT_TYPE_TAGS),
          ""
        ),
        amount: Joi.when("type", {
          is: ["fixed", "multiple_of_rents"],
          then: Joi.number().min(0).required(),
          otherwise: Joi.number().valid(0),
        }),
      }),
      otherwise: Joi.forbidden(),
    }),
    other_charges: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    booking_amount: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number(),
      otherwise: Joi.forbidden(),
    }),
    total_floors: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number().min(0).max(99).required(),
      otherwise: Joi.forbidden(),
    }),
    your_floor: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.number()
        .required()
        .max(Joi.ref("total_floors"))
        .message('"your_floor" must be less than or equal to "total_floors"'),
      otherwise: Joi.forbidden(),
    }),
    bhk: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.string()
        .valid(...Object.values(BHK_TAGS))
        .required(),
      otherwise: Joi.forbidden(),
    }),
    preferences: Joi.array().items(
      Joi.string()
        .valid(...Object.values(PREFERENCES_TAGS))
        .trim()
    ),
    amenities: Joi.array().items(
      Joi.string().valid(...Object.values(AMENITIES_TAGS))
    ),
    photos: Joi.array().items(Joi.string().trim()),
    location: locationSchema.required(),
    furnish_type_items: Joi.when("sharing_type", {
      is: Joi.valid("need_flatmate"),
      then: Joi.when("furnish_type", {
        is: "fully_furnished",
        then: Joi.object()
          .pattern(
            Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
            Joi.number().min(1)
          )
          .min(5)
          .required(),
        otherwise: Joi.when("furnish_type", {
          is: "semi_furnished",
          then: Joi.object()
            .pattern(
              Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
              Joi.number().min(1)
            )
            .min(3)
            .required(),
          otherwise: Joi.forbidden(),
        }),
      }),
      otherwise: Joi.forbidden(),
    }),
  }).unknown(),
};

export default shareFlatSchema;
