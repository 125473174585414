import formValidation from "components/addForm/validation";
import { customFormKeys } from "components/addForm/validation";
import { customFormkeysArray } from "components/addForm/validation";
import toast from "react-hot-toast";
import { createForm } from "views/admin/form/apiService";
import { filterUnusedData } from "views/admin/form/formHandlers";

const validateForm = ({ updatedFormData }) => {
  const { formType, ...payload } = updatedFormData;
  let schema;
  if (updatedFormData._id) {
    schema = formValidation[formType].update;
  } else {
    schema = formValidation[formType].create;
  }
  if (!schema) {
    console.error(`No validation schema found for form type: ${formType}`);
    toast.error(`No validation schema found for form type: ${formType}`);
    return { newErrors: {}, payload };
  }
  const { error } = schema.validate(payload, { abortEarly: false });
  const newErrors = {};
  let part;
  let message;
  let localityErrors = [];

  if (error) {
    error.details.forEach((detail) => {
      const path = detail.path;
      let current = newErrors;
      const key = detail?.context?.key;
      const keysArray = customFormkeysArray[formType];
      const newKey = customFormKeys[formType];
      message = detail.message.replace(detail.context.label, key);
      if (keysArray?.includes(key)) {
        if (!current[newKey[key]]) {
          current[newKey[key]] = {};
        }
        current[newKey[key]][key] = message;
      }
      if (path.includes("location") || path.includes("address")) {
        if (path.includes("locality")) {
          localityErrors.push("Locality is required");
        } else if (path.length === 1) {
          current["locality"] = "Locality is not allowed to be empty";
        } else {
          localityErrors.push(message);
        }
      }
      for (let i = 0; i < path.length; i++) {
        part = path[i];
        if (i === path.length - 1) {
          current[part] = message;
        } else {
          if (!current[part]) {
            current[part] = {};
          }
          current = current[part];
        }
      }
      if (localityErrors.length > 0) {
        newErrors["locality"] = localityErrors.join(", ");
      }
    });
  }
  return { newErrors, payload };
};

export const handleShareFlatSubmit = async ({
  shareFlatData,
  setShareFlatData,
  initialShareFormValue,
  userData,
  setShareFlatErrors,
  apiCall,
}) => {
  try {
    const formType = "share_flat";
    const updatedFormData = {
      ...shareFlatData,
      sharing_type: "need_flat",
      location: userData.location,
      owner_id: userData.userId,
      type: formType,
      formType: formType,
    };
    filterUnusedData(updatedFormData)
    const { newErrors, payload } = validateForm({ updatedFormData });
    if (
      newErrors &&
      typeof newErrors === "object" &&
      Object.keys(newErrors)?.length > 0
    ) {
      setShareFlatErrors(newErrors);
      toast.error("Please fill all the shareflat required fields.");
      return {
        error: newErrors,
      };
    }
    const response = await createForm({
      apiCall,
      formData: payload,
      propertyType: formType,
    });
    if (response) {
      setShareFlatData(initialShareFormValue);
    }
    setShareFlatErrors({});
    return {
      response,
      error: newErrors,
    };
  } catch (error) {
    console.log("share flat error", error);
    return {
      error: { error },
    };
  }
};
