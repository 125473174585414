import { Button, Flex, Text } from '@chakra-ui/react';
import StatusBadge from 'components/badge/StatusBadge';
import { USER_ROLES } from 'constants/dbEnums';

export const userColumns = [
  {
    Header: 'NAME',
    accessor: 'name',
    Cell:(props)=>{
     return  <a href={`/#/admin/ads?search_text=${props?.row?.values?.phone}`}>{props.value}</a>
    } 
  },

  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'PHONE',
    accessor: 'phone',
    Cell:({value})=>(
      <a href={`/#/admin/ads?search_text=${value}`}>{value}</a>
    )
  },
  {
    Header: 'ROLE',
    accessor: 'role',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={USER_ROLES[value]} />
        </Text>
      </Flex>
    ),
  },
  {
    Header: 'STATUS',
    accessor: '_id',
    Cell: (props) => {
      return (
        <a
          _hover={{ textDecoration: 'underline' }}
          href={`/#/admin/users/${props?.value}`}
          target="_blank"
          rel="noreferrer"
          cursor="pointer"
          whiteSpace={'nowrap'}
          style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
        >
          <Button colorScheme="purple" size="xs">
            view stats
          </Button>
        </a>
      );
    },
  },
  {
    Header: 'PHONE VERIFIED',
    accessor: 'phone_verified',
  },
  {
    Header: 'EMAIL VERIFIED',
    accessor: 'email_verified',
  },
  {
    Header: 'SLUG URL',
    accessor: 'slug_url',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'ONBOARDING',
    accessor: 'onboarding_completed',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  {
    Header: 'PLAN',
    accessor: 'plan',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={value} />
        </Text>
      </Flex>
    ),
  },
  {
    Header: 'ACTIVE PROPERTIES',
    accessor: 'active_properties',
  },
  {
    Header: 'CREATED_AT',
    accessor: 'created_at',
    
  },
];

export const userColumnConfig = [
  {
    Header: 'FIRST NAME',
    accessor: 'first_name',
  },
  {
    Header: 'LAST NAME',
    accessor: 'last_name',
  },
  {
    Header: 'PHONE',
    accessor: 'phone',
  },
  {
    Header: 'PHONE VERIFIED',
    accessor: 'phone_verified',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'EMAIL VERIFIED',
    accessor: 'email_verified',
  },
  {
    Header: 'ROLE',
    accessor: 'role',
  },
  {
    Header: 'PLAN',
    accessor: 'plan',
  },
  {
    Header: 'ONBOARDING COMPLETED',
    accessor: 'onboarding_completed',
  },
  {
    Header: 'CREATED AT',
    accessor: 'created_at',
  },
  {
    Header: 'CREATED BY',
    accessor: 'created_by',
  },
  {
    Header: 'IS CAREER ACCOUNT',
    accessor: 'is_career_account',
  },
];

