import React from "react";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text
} from "@chakra-ui/react";
import ErrorMessage from "./ErrorMessage";

const CheckboxComponent = ({ options, name, selectedValues, onChange, label, checkboxSize = "md", textSize = "md", isRequired, error, disabled = false }) => {
  
  const safeSelectedValues = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
  const handleChange = (e) => {
    onChange(e);
  };
  
  return (
    <FormControl display={"flex"} width={"fit-content"} paddingY={2}>
        <FormLabel htmlFor={name}>{label}{isRequired && <Box as="span" color="red.500" ml={1}>*</Box>}</FormLabel>
        <CheckboxGroup value={safeSelectedValues}>
          <Flex>
            {options?.map((option) => (
              <Flex
              key={option.value}
              align="center"
              mr={4}
            >
              <Text fontSize={textSize} mr={2}>
                {option.label}
              </Text>
              <Checkbox
                value={option.value}
                size={checkboxSize}
                onChange={handleChange}
                name={name}
                isDisabled={disabled}
              />
            </Flex>
            ))}
          </Flex>
        </CheckboxGroup>
        <ErrorMessage error={error}/>
    </FormControl>
  );
};

export default CheckboxComponent;
