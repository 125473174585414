import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup
} from "@chakra-ui/react";
import useApi from "hooks/useApi";
import { HttpStatusCode } from "axios";
import {
  SORT_TYPES,
  SORT_DIRECTIONS,
} from "constants/filterDropdownData";
import Pagination from "components/pagination/Pagination";
import ErrorDisplay from "components/errorDisplay/ErrorDisplay";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { showToast } from "components/toast/Toast";
import { OverlayLoaderComponent } from "components/loader/Loader";
import SidebarFilter from "components/sidebarFilter/SidebarFilter";
import { getFilterFromLocalStorage } from "utils/common";
import { ENTITY_FILTER_TYPES } from "utils/common";
import { setFilterToLocalStorage } from "utils/common";
import ManageColumns from "components/manageColumns/ManageColumns";
import NoDataFound from "components/noDataFound/NoDataFound";
import { areAllStatusesSame } from "utils/common";
import { ENTITY_COLUMNS_TYPES } from "utils/common";
import { FiChevronsDown, FiFilter } from "react-icons/fi";
import CustomTable from "components/table/CustomTable";
import Menu from "components/menu/MainMenu";
import { useLocation } from "react-router-dom";
import { formatBrokerGroupTableData } from "utils/common";
import { PROJECT_LIST_STATUS } from "constants/filterDropdownData";
import MultiSelectMenu from "components/menu/MultiSelectMenu";
import { UPDATE_PROJECTS_STATUS } from "constants/endpoints";
import { getTopCities } from "utils/common";
import { BROKER_GROUP_API } from "constants/endpoints";
import { brokerGroupsColumn } from "./variables";
import BrokerGroupModal from "./modal/BrokerGroupModal";
import { PROPERTY_TYPE_LIST } from "constants/filterDropdownData";
import { REQUIREMENT_TYPE_DATA } from "constants/filterDropdownData";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";

const PAGE_SIZE = 10;
const sortableColumns = ["listing_score", "created_at", "expires_on"];
const initialFiltersData = {
  propertyType: "",
  adType: ""
};

export default function BrokerGroups() {
  const [brokerGroupData, setBrokerGroupData] = useState([]);
  const { apiCall, loading = true, error } = useApi();
  const [selectedAction, setSelectedAction] = useState("");
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(10);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [openBrokerGroupForm, setOpenBrokerGroupForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [sortConfig, setSortConfig] = useState({
    sortType: "created_at",
    sortDirection: "descending",
  });
  const lsAdsDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USER_REQUIREMENTS_DATE_RANGE
  );
  const [startDate, setStartDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[0] : ""
  );
  const [endDate, setEndDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[1] : ""
  );
  const lsFilters = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER
  );
  const { search } = useLocation();
  const queryObject = Object.fromEntries(new URLSearchParams(search));
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState(initialFiltersData);
  const {
    sortType = "",
    sortDirection = "",
    propertyType = "",
    adType = ""
  } = filters || {};
  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filterRemoveFlagCount, setFilterRemoveFlagCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const fetchBrokergroupsData = useCallback(
    async (text) => {
      try {
        const body = {
          search_text: text || searchText,
          city: selectedCity,
          property_type: propertyType,
          ad_type: adType,
          page: currentPage,
          limit: pageSize,
        };
        const res = await apiCall.get(`${BROKER_GROUP_API}`, body);
        if (res?.statusCode === HttpStatusCode.Ok) {
          setBrokerGroupData(res?.data?.groups);
          setTotalPages(Math.ceil(res?.data?.count / pageSize));
          setTotalCount(res?.data?.count);
        }
      } catch (error) {
        console.log("Error while fetching requirements: ", error.message);
      }
    },
    [currentPage, pageSize, searchText, selectedCity, propertyType, adType]
  );

  useEffect(() => {
    const fetchCities = async () => {
      const topCities = await getTopCities();
      setCityOptions(topCities || []);
    };
    fetchCities();
  }, []);

  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);

      fetchBrokergroupsData(text);
    },
    [fetchBrokergroupsData]
  );

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setCurrentPage(1);
  }, []);

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];

    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(
      ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER,
      filters
    );
    setCurrentPage(1);

    setSelectedRowsData([]);
    fetchBrokergroupsData();
  };

  const handleRemoveAllFilter = () => {
    setFilteredData([]);
    setFilters(initialFiltersData);
    setEndDate("");
    setStartDate("");
    setSelectedCity("");
  };

  const handleRequirementsUpdate = async ({ id, status }) => {
    try {
      const data = brokerGroupData.find((item) => item._id === id);
      if (!data) {
        console.error(`No data found for ID: ${id}`);
        return;
      }
        setOpenBrokerGroupForm(true);
        setSelectedUser({
          name: data.name,
          property_type: data.property_type,
          ad_type: data.ad_type,
          location: data.location,
          comment: data.comment,
          _id: data._id,
        });
    } catch (error) {
      console.log("error updating user requirements", error);
    }
  };

  const dropdownFilters = [
    {
      name: "propertyType",
      placeholder: "Property Type",
      value: filters?.propertyType,
      data: PROPERTY_TYPE_LIST,
    },
    {
      name: "adType",
      placeholder: "Select Ad Type",
      value: filters?.adType,
      data: REQUIREMENT_TYPE_DATA,
      isDisabled: (filters.propertyType === PROPERTY_COLLECTIONS.PLOT || filters.propertyType === PROPERTY_COLLECTIONS.PG),
    },
  ];

  const dropdownSorts = [
    {
      name: "sortType",
      placeholder: "",
      value: sortType,
      data: SORT_TYPES,
    },
    {
      name: "sortDirection",
      placeholder: "",
      value: sortDirection,
      data: SORT_DIRECTIONS,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    sorts: dropdownSorts,
  };

  const columns = useMemo(() => brokerGroupsColumn, [brokerGroupsColumn]);
  const data = useMemo(
    () => formatBrokerGroupTableData(brokerGroupData),
    [brokerGroupData]
  );

  const excludedColumnVisiblity = [
    "meta_title",
    "meta_description",
    "owner_email",
  ];
  const [columnVisibility, setColumnVisibility] = useState(
    columns.reduce((acc, column) => {
      acc[column.accessor] = !excludedColumnVisiblity.includes(column.accessor);
      return acc;
    }, {})
  );

  const [manageColumnSelectAllCheckbox, setManageColumnSelectAllCheckbox] =
    useState(false);

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.PROJECTS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  useEffect(() => {
    if (queryObject?.search_text) {
      setSearchText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      setSearchText("");
      fetchBrokergroupsData();
      setFilterToLocalStorage(
        ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER,
        filters
      );
    }
  }, [
    filterRemoveFlagCount,
    queryObject?.search_text,
    pageSize,
    currentPage,
    sortConfig,
  ]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();

    setCurrentPage(1);
    fetchBrokergroupsData(searchText);
  };

  const filterBySlugUrl = (smallArray, fullArray) => {
    return fullArray.filter((fullItem) =>
      smallArray.some((smallItem) => smallItem.slug_url === fullItem.slug_url)
    );
  };

  const handleMultipleUpdateStatus = async ({ updatedStatus }) => {
    const statusArray = selectedRowsData?.map((item) => item?.status);

    if (!areAllStatusesSame(statusArray)) {
      showToast({
        message: "All selected must have the same status",
        customStyle: {
          background: "#ff4d4f",
          color: "#fff",
          padding: "10px",
        },
      });
      return;
    }

    const filterdToBeUpdatedData = filterBySlugUrl(
      selectedRowsData,
      brokerGroupData
    );

    const updatedData = filterdToBeUpdatedData.map((item) => {
      return {
        _id: item?._id,
        status: updatedStatus,
      };
    });

    try {
      await apiCall.patch(UPDATE_PROJECTS_STATUS, {
        updateData: updatedData,
      });

      setFilterRemoveFlagCount((prev) => prev + 1);

      showToast({
        message: "Status updated successfully",
        customStyle: {
          background: "green",
          color: "#fff",
          padding: "10px",
        },
      });

      setSelectedRowsData([]);
    } catch (error) {}
  };

  /*SELECT ROW LOGIC***/
  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find(
        (item) => item.slug_url === row.slug_url
      );

      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item.slug_url !== findData.slug_url);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  const selectedRowsSlug = selectedRowsData.map((item) => item.slug_url);

  const selectedData = useMemo(
    () =>
      selectedRowsSlug.map((slug) => data.find((row) => row.slug_url === slug)),
    [selectedRowsSlug, data]
  );

  const handleAfterBrokerGroupUpdate = (res) => {
    setOpenBrokerGroupForm(false);
    if (res) {
      setBrokerGroupData((prev) => {
        const index = prev.findIndex(
          (requirement) => requirement._id === res._id
        );
        if (index !== -1) {
          return prev.map((requirement) =>
            requirement._id === res._id
              ? { ...requirement, ...res }
              : requirement
          );
        } else {
          return [res, ...prev];
        }
      });
    }
  };

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <Box height="100vh" bg="#F8FAFB">
        <Flex
          justify="space-between"
          align="center"
          p="2"
          boxShadow="sm"
          borderRadius="sm"
          pos="fixed"
          style={{ width: "calc(100vw - 96px)" }}
          zIndex={1000}
        >
          <HStack spacing="4" p={2}>
            <Box position="relative" display="inline-block">
              <Button
                paddingX={6}
                leftIcon={<FiFilter />}
                rightIcon={<FiChevronsDown />}
                colorScheme="teal"
                variant="outline"
                borderRadius="md"
                size="sm"
                onClick={() => {
                  toggleSidebar();
                  setFilters(lsFilters);
                }}
              >
                Filter
              </Button>
              {filteredData.length > 0 && (
                <Badge
                  position="absolute"
                  top="-3"
                  right="0"
                  colorScheme="teal"
                  borderRadius="full"
                  px={2}
                  fontSize="0.8em"
                >
                  {filteredData.length}
                </Badge>
              )}
            </Box>
            <ManageColumns
              columns={columns}
              handleColumnChange={handleColumnChange}
              columnVisibility={columnVisibility}
              setManageColumnSelectAllCheckbox={
                setManageColumnSelectAllCheckbox
              }
              manageColumnSelectAllCheckbox={manageColumnSelectAllCheckbox}
            />
            <form onSubmit={handleInputFormSubmit}>
              <InputGroup size="sm">
                <SearchBar
                  background="#F9F9FB"
                  color="#637381"
                  setSearchText={(e) => setSearchText(e.target.value)}
                  searchText={searchText}
                  borderRadius="5px"
                  borderWidth="0.5px"
                  borderColor="#637381"
                />
              </InputGroup>
            </form>
          </HStack>
          <Button
            borderRadius="5px"
            borderColor="#637381"
            background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal"
            onClick={() => {
              setOpenBrokerGroupForm(true);
              setSelectedUser({});
            }}
          >
            Create Group
          </Button>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => {
              setSelectedRowsData([]);
              setCurrentPage(value);
            }}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPageSizeToLocalStorage={(value) =>
              setFilterToLocalStorage(
                ENTITY_FILTER_TYPES.USER_REQUIREMENTS_PAGE_SIZE,
                value
              )
            }
          />
        </Flex>
        <SidebarFilter
          filters={allFiltersProps}
          handleFilterChange={handleFilterChange}
          handleApplyFilter={handleApplyFilter}
          isOpen={isOpenFilterSidebar}
          onClose={toggleSidebar}
          appliedFilters={filters}
          setFilters={setFilters}
          lsFilters={lsFilters}
          handleRemoveAllFilter={handleRemoveAllFilter}
          // onFilterDateRangeSubmit={onFilterDateRangeSubmit}
          //date filter
          isCalenderFilter={false}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          ////location filter
          locationCitiesFilter={cityOptions}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
        {brokerGroupData.length !== 0 ? (
          <>
            <CustomTable
              columns={visibleColumns}
              data={data}
              onSelectedAction={setSelectedAction}
              selectedRowsData={selectedRowsData}
              selectedRowsSlug={selectedRowsSlug}
              selectedData={selectedData}
              handleRowSelect={handleRowSelect}
              setSelectedRowsData={setSelectedRowsData}
              sortableColumns={sortableColumns}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              single
              menu
              props
              menuComponent={
                <Menu
                  data={Object.values(PROJECT_LIST_STATUS)}
                  onUpdate={() => {}}
                  onUpdatePropertyStatus={handleRequirementsUpdate}
                  selectedAction={selectedAction}
                />
              }
              multiSelectMenuComponent={
                selectedRowsSlug?.length > 0 &&
                areAllStatusesSame(
                  selectedData.map((item) => item?.status)
                ) && (
                  <MultiSelectMenu
                    multiSelectCurrentValue={selectedData[0]?.status}
                    onMultipleUpdateStatus={handleMultipleUpdateStatus}
                    selectedAction={selectedAction}
                    selectedRowsData={selectedRowsData}
                    bgColor="#B2F5EA"
                  />
                )
              }
            />
          </>
        ) : !loading ? (
          <NoDataFound />
        ) : null}
        <OverlayLoaderComponent isOpen={loading} />
      </Box>
      <BrokerGroupModal
        isOpen={openBrokerGroupForm}
        onClose={() => setOpenBrokerGroupForm(false)}
        selectedBrokerGroup={selectedUser}
        afterBrokerGroupUpdate={(res) => handleAfterBrokerGroupUpdate(res)}
      />
    </>
  );
}
