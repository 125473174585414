import {
  Box,
  Button,
  FormControl,
  Flex,
  Heading,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import CalendarInput from "components/addForm/CalendarInput";
import { CenteredSpinner } from "components/loader/Loader";
import { formatDate } from "constants/commonFunctions";

const ExpiryUpdateForm = ({
  handleChange,
  formData,
  handleSubmit,
  disabled,
  adsList,
  isSubmittingForm,
}) => {
  const { expires_on } = formData;

  return (
    <>
      <Box>
        <Flex justify="space-between" align="center" mb={1} mt={1}>
          <Heading size="md">Update Expiry Dates</Heading>
          <Flex align="center" gap={19}>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={handleSubmit}
              size="sm"
              disabled={disabled}
            >
              Update
            </Button>
            <ModalCloseButton position="relative" top="0" />
          </Flex>
        </Flex>
        <Flex gap={2}>
          <FormControl flex={1}>
            <CalendarInput
              name="expires_on"
              label="Expiration Date"
              value={expires_on}
              isRequired={true}
              onChange={(e) => handleChange(e)}
              disablePastDate={false}
            />
          </FormControl>
        </Flex>
        {isSubmittingForm && <CenteredSpinner />}
        {/* Ads List */}
        <Box mb={3}>
          <Text fontSize="md" mb={2}>
            Updating the expiry for the following ads (Identified by their{" "}
            <Text as="span" fontWeight="bold">
              slug URLs
            </Text>
            ):
          </Text>
          <Box
            maxWidth="800px"
            overflowY="auto"
            p={3}
            border="1px solid #E2E8F0"
            borderRadius="md"
            backgroundColor="gray.50"
          >
            {adsList && adsList.length > 0 ? (
              adsList.map((ad) => (
                <Text key={ad.id} fontSize="sm" mb={1}>
                  <Text as="span" fontWeight="bold">
                    {ad.slug_url && `${ad.slug_url}  :`}{" "}
                    {ad.expires_on && formatDate(ad.expires_on)}
                  </Text>
                </Text>
              ))
            ) : (
              <Text color="gray.500">No ads selected.</Text>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExpiryUpdateForm;
