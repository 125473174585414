import React from "react";
import {
  Box,
  Button,
  FormControl,
  Flex,
  Grid,
  Heading,
  ModalCloseButton,
  Text
} from "@chakra-ui/react";
import SelectInput from "components/addForm/SelectInput";
import CitySelectInput from "components/addForm/location/citySelectInput";
import AutoSuggestComponent from "components/addForm/location/locality";
import ValidatedInput from "components/addForm/ValidatedInput";
import {
  LOCATION_TYPE
} from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { CenteredSpinner } from "components/loader/Loader";
import { PROPERTY_TYPE_OPTIONS } from "constants/defaultState";
import { REQUIREMENT_TYPE } from "constants/dbEnums";
import CheckboxComponent from "components/addForm/Checkbox";
import { INTERESTED_IN_SHARED_ACCOMMODATION } from "constants/defaultState";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";
import ErrorMessage from "components/addForm/ErrorMessage";
import { SHOW_SHAREFLAT_FORM } from "constants/defaultState";

const UserRequirementForm = ({
  formData,
  handleChange,
  handleLocation,
  handleLocality,
  handleSubmit,
  handleReset,
  editingId,
  isDisabled,
  selectedData,
  isSubmittingForm,
  shareFlatErrors
}) => {
  const isFormEmpty =
    !formData?.property_type &&
    !formData?.ad_type &&
    !formData?.location?.city &&
    !formData?.location?.label &&
    !formData?.comment;

  return (
    <Box flex={1}>
      {/* Heading and Buttons */}
      <Flex justify="space-between" align="center" mb={2}>
        <Heading size="md">
          <Text as="span" fontWeight="bold">
            {editingId ? "Edit Requirement" : "Add Requirement"}
          </Text>
          &nbsp;for&nbsp;
          <Text as="span" fontWeight="semibold" color="blue.600">
            {selectedData?.full_name}
          </Text>
        </Heading>
        <Flex align="center" gap={19}>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={isFormEmpty || isDisabled}
            variant="ghost"
          >
            {editingId ? "Update" : "Add"}
          </Button>
          <Button variant="ghost" onClick={handleReset} disabled={isDisabled}>
            Reset
          </Button>
          <ModalCloseButton position="relative" top="0" />
        </Flex>
      </Flex>
      {isSubmittingForm && <CenteredSpinner />}
      <FormControl mb={4}>
        <SelectInput
          name="property_type"
          label="Property Type"
          options={PROPERTY_TYPE_OPTIONS}
          onChange={(e) => handleChange(e)}
          selectedValue={formData?.property_type}
        />
      </FormControl>
      <FormControl mb={4}>
        <SelectInput
          name="ad_type"
          label="Ad type"
          options={REQUIREMENT_TYPE}
          onChange={(e) => handleChange(e)}
          selectedValue={formData?.ad_type}
          disabled={[
            PROPERTY_COLLECTIONS.PLOT,
            PROPERTY_COLLECTIONS.PG,
          ].includes(formData.property_type)}
        />
      </FormControl>
      <FormControl mb={4}>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleLocation(e)}
          result_type={LOCATION_TYPE.CITY}
          selectedValue={formData.location.city}
          error={shareFlatErrors?.location?.city}
        />
        {typeof shareFlatErrors?.city === "string" && (
          <ErrorMessage error={shareFlatErrors?.city} />
        )}
      </FormControl>
      <FormControl>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <AutoSuggestComponent
            name="locality"
            selectedValue={formData?.location?.label}
            placeholder="Search by locality, area"
            onChange={(e) => handleLocality(e)}
            label="Locality (Area)"
            location={formData.location}
            result_type={LOCATION_TYPE.LOCALITY}
            selectedCity={formData?.location?.city}
            error={shareFlatErrors?.location?.locality}
          />
          <ValidatedInput
            name="building"
            placeholder="Enter society or area"
            type="text"
            onChange={(e) => handleLocality(e)}
            label="Building (Apartment)"
            value={formData?.location?.building}
          />
        </Grid>
      </FormControl>
      <FormControl mb={4}>
        <ValidatedInput
          name="comment"
          placeholder="Add Comment"
          type="text"
          onChange={(e) => handleChange(e)}
          label="Comment"
          value={formData.comment}
        />
      </FormControl>
      <FormControl mb={4} flex={1}>
        <Flex direction="row" gap={4} alignItems="center">
          <CheckboxComponent
            options={INTERESTED_IN_SHARED_ACCOMMODATION}
            name="shared_accommodation_interest"
            {...(formData?.shared_accommodation_interest === true && {
              selectedValues: "true",
            })}
            onChange={(e) => {
              /**Update the value before sending it to handleChange**/
              const updatedValue = !formData.shared_accommodation_interest;
              handleChange({
                ...e,
                target: {
                  ...e.target,
                  value: updatedValue,
                  name: "shared_accommodation_interest",
                },
              });
            }}
            label="Accommodation Sharing Preference"
            textSize="lg"
            checkboxSize="lg"
            disabled={
              formData.ad_type === REQUIREMENT_TYPE_TAGS.BUY ||
              !formData.ad_type
            }
          />
          <CheckboxComponent
            options={SHOW_SHAREFLAT_FORM}
            name="showShareFlat"
            {...(formData?.showShareFlat === true && {
              selectedValues: "true",
            })}
            onChange={(e) => {
              /**Update the value before sending it to handleChange**/
              const updatedValue = !formData.showShareFlat;
              handleChange({
                ...e,
                target: {
                  ...e.target,
                  value: updatedValue,
                  name: "showShareFlat",
                },
              });
            }}
            label="Would you like to add Room/Apartment form?"
            textSize="lg"
            checkboxSize="lg"
            disabled={!formData.shared_accommodation_interest}
          />
        </Flex>
      </FormControl>
    </Box>
  );
};

export default UserRequirementForm;
