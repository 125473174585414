import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  FormControl,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import useApi from "hooks/useApi";
import {
  CREATE_USER,
  CREATE_COMPANY,
  BUILDERS_LISTING_API,
} from "constants/endpoints";
import { ALL_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import ErrorMessage from "./addForm/ErrorMessage";

const MultiSearchInput = ({
  name,
  label,
  isRequired,
  placeholder,
  onChange,
  error: formError,
  propertyType,
  value = [],
  disabled,
}) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState(value);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { apiCall } = useApi();
  const dropdownRef = useRef();

  const fetchSuggestions = useMemo(
    () =>
      debounce(async (query) => {
        const trimmedQuery = query.trim();
        if (!trimmedQuery) return [];
        try {
          const apiUrl =
            propertyType === ALL_PROPERTY_TYPE_TAGS.projects
              ? BUILDERS_LISTING_API
              : name === "company_id"
              ? CREATE_COMPANY
              : CREATE_USER;
          const url = `${apiUrl}?search_text=${trimmedQuery}`;
          const response = await apiCall.get(url);
          setResults(
            response?.data?.users ||
              response?.data?.data ||
              response?.data ||
              []
          );
          setDropdownVisible(true);
        } catch (err) {
          console.error("Fetch error:", err);
        }
      }, 300),
    [apiCall]
  );

  const handleChange = (event) => {
    setQuery(event.target.value);
    setDropdownVisible(true);
  };

  useEffect(() => {
    if (
      Array.isArray(value) &&
      value.length > 0 &&
      value.every((item) => typeof item === "object")
    ) {
      const formattedItems = value.map((item) => ({
        ...item,
        displayName: getProfileName(item),
      }));
      setSelectedItems(formattedItems);
    }
  }, [value]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchSuggestions(query);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const isAlreadySelected = selectedItems.some(
      (item) => item.user_id === suggestion._id
    );
    if (!isAlreadySelected) {
      const newSelectedItems = [
        ...selectedItems,
        {
          ...suggestion,
          user_id: suggestion._id,
          displayName: getProfileName(suggestion),
        },
      ];
      setSelectedItems(newSelectedItems);
      onChange({
        target: {
          name,
          value: newSelectedItems.map((item) => item.user_id),
        },
      });
    }
    setQuery("");
    setDropdownVisible(false);
  };

  const getProfileName = (result) => {
    if (propertyType === ALL_PROPERTY_TYPE_TAGS.projects) {
      return `${result?.company?.name} + ${result._id}`;
    } else if (name === "company_id") {
      return `${result?.name} + ${result._id}`;
    } else {
      return `(${result.first_name} ${result.last_name}) + ${result.phone} + ${result.role}`;
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedItems = selectedItems.filter(
      (item) => item.user_id !== itemToRemove.user_id
    );
    setSelectedItems(updatedItems);
    onChange({
      target: {
        name,
        value: updatedItems.map((item) => item.user_id),
      },
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FormControl isInvalid={!!formError} mb={4}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Box ref={dropdownRef}>
        <Input
          placeholder={placeholder}
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <Wrap spacing={2} mt={2}>
          {selectedItems.map((item) => (
            <Tag key={item._id} size="md" variant="solid" colorScheme="teal">
              <TagLabel>{item?.displayName}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveItem(item)} />
            </Tag>
          ))}
        </Wrap>
        {dropdownVisible && query && (
          <Box
            position="absolute"
            top="100%"
            left="0"
            right="0"
            mt={2}
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
            maxH="200px"
            overflowY="auto"
            bg="white"
            zIndex="10"
          >
            {results?.length > 0 ? (
              <List spacing={2}>
                {results.map((result, index) => (
                  <ListItem
                    key={index}
                    padding="8px"
                    borderBottom="1px solid #eaeaea"
                    cursor="pointer"
                    onClick={() => handleSuggestionClick(result)}
                  >
                    {getProfileName(result)}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box color="red.500" p={2}>
                No results found
              </Box>
            )}
          </Box>
        )}
      </Box>
      {formError && <ErrorMessage error={formError} />}
    </FormControl>
  );
};

export default MultiSearchInput;
