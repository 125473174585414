import React, { useState } from "react";
import { Button, Textarea, Box, Flex, FormLabel } from "@chakra-ui/react";
import { AUTO_GENERATE_DES_API } from "constants/endpoints";
import useApi from "hooks/useApi";
import ErrorMessage from "./ErrorMessage";
import { handleAiFormData } from "./aiFormData";

const DescriptionGenerator = ({
  value,
  onChange,
  formData,
  name='description',
  isRequired,
  label,
  placeholder,
  error,
  showButton=true,
  maxLength=1500,
  isDisabled=false
}) => {
  const { apiCall } = useApi();
  const [loading, setLoading] = useState(false);

  const sanitizeDescription = (description) => {
    if (!description) return '';
    return description
      .replace(/\\n/g, ' ')
      .replace(/\\r/g, '')
      .replace(/\\t/g, ' ')
      .replace(/\\'/g, "'")
      .replace(/\\"/g, '')
      .replace(/\s{2,}/g, ' ')
      .trim();
  };    

  const autogenerateDesFn = async () => {
    setLoading(true);
    try {
      const payload = handleAiFormData(formData);
      const response = await apiCall.post(AUTO_GENERATE_DES_API, payload);
      const sanitizedDescription = sanitizeDescription(response.data.description);
      onChange({
        target: {
          name,
          value: sanitizedDescription,
        },
      });
    } catch (error) {
      console.error("Error generating description:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = async () => {
    await autogenerateDesFn();
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue?.length <= maxLength  || newValue?.length < value?.length) {
      onChange(e);
    }
  };

  return (
    <Box>
      <Flex align="center" mb={4}>
        <FormLabel htmlFor="description" mb={0} mr={4}>
          {label}
          {isRequired && (
            <Box as="span" color="red.500" ml={1}>
              *
            </Box>
          )}
        </FormLabel>
        {showButton && <Button
          bg="blue.500"
          onClick={handleGenerate}
          isLoading={loading}
          loadingText="Generating..."
          width="152px"
          height="27px"
          size="sm"
          isDisabled={isDisabled}
        >
          Auto Generate By AI
        </Button>}
      </Flex>
      <Textarea
        id="description"
        name={name}
        value={value}
        placeholder={placeholder}
        height="100px"
        onChange={handleChange}
        onPaste={(e) => {
          const pastedText = e.clipboardData.getData('Text');
          if (value?.length + pastedText?.length > maxLength) {
            e.preventDefault();
          }
        }}
      />
      <Box>{value?.length}/{maxLength}</Box>
      <ErrorMessage error={error}/>
    </Box>
  );
};

export default DescriptionGenerator;
