import pgForm from "./pg.config";
import plotForm from "./plot.config";
import commercialOfficeForm from "./commercial.config";
import residentialForm from "./residential.config";
import projectsForm from "./projects.config";
import shareFlatForm from "./shareFlat.config";

const fieldConfig = {
  PG: pgForm,
  residential_plot: plotForm,
  commercial_plot: plotForm,
  office: commercialOfficeForm,
  "flat/apartment": residentialForm,
  projects: projectsForm,
  "independent_house": residentialForm,
  "vila/farm_house": residentialForm,
  "floor": residentialForm,
  "1_rk_studio": residentialForm,
  residential_others: residentialForm,
  retail_shop: commercialOfficeForm,
  showroom: commercialOfficeForm,
  warehouse: commercialOfficeForm,
  commercial_other: commercialOfficeForm,
  "need_flat": shareFlatForm,
  need_flatmate: shareFlatForm
};

export default fieldConfig;
