import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import useApi from 'hooks/useApi';
import { jobApplicationColumn } from 'views/admin/jobs/variables';
import Pagination from 'components/pagination/Pagination';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { formatJobApplicationData } from 'utils/common';
import Menu from 'components/menu/jobStatusMenu';
import { showToast } from 'components/toast/Toast';
import { OverlayLoaderComponent } from 'components/loader/Loader';
import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { ENTITY_FILTER_TYPES } from 'utils/common';
import { getFilterFromLocalStorage } from 'utils/common';
import { setFilterToLocalStorage } from 'utils/common';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import ManageColumns from 'components/manageColumns/ManageColumns';
import NoDataFound from 'components/noDataFound/NoDataFound';
import { SORT_DIRECTIONS } from 'constants/filterDropdownData';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';
import { FiChevronsDown, FiFilter } from 'react-icons/fi';
import CustomTable from 'components/table/CustomTable';
import { formatDateToDDMMYYYY } from 'utils/common';
import { JOB_APPLICATIONS_API } from 'constants/endpoints';
import { JOB_APPLICATION_STATUS_DATA } from 'constants/filterDropdownData';
import MultiSelectMenu from 'components/menu/MultiSelectMenu';
import { areAllStatusesSame } from 'utils/common';
import { DEV_ASSIGNEE_API } from 'constants/endpoints';
import { STATIC_DATA_PATH } from 'constants/endpoints';
import { SEND_ASSIGNMENT_REMINDER_API } from 'constants/endpoints';
import { MISCELLANEOUS } from 'constants/dbEnums';
import { JOB_WORK_PREFERENCE_DATA } from 'constants/filterDropdownData';
import { JOB_APPLICATION_EXPERIENCE_DATA } from 'constants/filterDropdownData';

const PAGE_SIZE = 50;

export default function JobApplications() {
  const { apiCall, error, loading } = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [jobApplicationData, setJobApplicationData] = useState([]);
  const lsUsersDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USERS_DATE_RANGE
  );
  const [startDate, setStartDate] = useState(
    lsUsersDateRange ? lsUsersDateRange[0] : ''
  );
  const [endDate, setEndDate] = useState(
    lsUsersDateRange ? lsUsersDateRange[1] : ''
  );
  const lsFiltersUsersPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USERS_PAGE_SIZE
  );
  const [pageSize, setPageSize] = useState(
    lsFiltersUsersPageSize ? lsFiltersUsersPageSize : PAGE_SIZE
  );

  const lsFilters = getFilterFromLocalStorage(ENTITY_FILTER_TYPES.USERS_FILTER);

  const initialFIlterData = {
    status: [],
    work_type_preference: [],
    experience: [],
    dev_asignee: '',
    job_id: [],
    sortType: 'created_at',
    show_empty_comment: false,
    sortDirection: 'descending',
  };

  const [filters, setFilters] = useState(
    lsFilters ? lsFilters : { ...initialFIlterData }
  );

  const { sortType = "", sortDirection = "", status = [], work_type_preference = [], experience = [], dev_asignee = "", job_id= [], show_empty_comment = false } = filters || {};

  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [filterRemoveFlagCount, setFilterRemoveFlagCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [jobIds, setJobIds] = useState([])
  const [devAssignee, setDevAssignee] = useState([])

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const { search } = useLocation();

  const queryObject = Object.fromEntries(new URLSearchParams(search));

  const [text, setText] = useState('');

  const [manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox, setManageColumnSelectAllCheckbox] =
    useState(false);

    const fetchDevAssignee = useCallback(async () => {
      try {
        const response = await apiCall.get(DEV_ASSIGNEE_API, {});
        if(response?.data){
          const formattedData = response?.data?.map((dev, index) => {
            return {
              key:index,
              label: dev.full_name,
              value: dev._id 
            }
          })
          setDevAssignee(formattedData)
        }
      } catch (error) {
        console.log(error);
      }
    }, []);

    const fetchJobIds = useCallback(async () => {
      try {
        const response = await apiCall.get(`${STATIC_DATA_PATH}/careers.json`, {});
          const formattedData = response?.data?.jobs?.map((job, index) => {
            return {
              key:index,
              label: job.job_title,
              value: job.id
            }
          })
          setJobIds(formattedData)
      } catch (error) {
        console.log(error);
      }
    }, []);

  const fetchData = useCallback(
    async (search) => {
      try {
        const data = await apiCall.get(`${JOB_APPLICATIONS_API}`, {
          status: status?.join(','),
          experience: experience?.join(','),
          work_type_preference: work_type_preference?.join(','),
          dev_asignee,
          job_id: job_id?.join(','),
          search_text: search || text,
          page: currentPage,
          limit: pageSize,
          sort_by: sortType,
          sort_order: sortDirection,
          start_date: formatDateToDDMMYYYY(startDate),
          end_date: formatDateToDDMMYYYY(endDate),
          show_empty_comment
        });

        const formatData = data?.data?.data;
        setTotalPages(Math.ceil(data?.data?.count / pageSize));
        setTotalCount(data?.data?.count);
        setJobApplicationData(formatData);
      } catch (error) {
        console.log(error);
      }
    },
    [
      text,
      currentPage,
      pageSize,
      sortType,
      sortDirection,
      filters,
      startDate,
      endDate,
      job_id
    ]
  );

  useEffect(() => {
    if (queryObject?.search_text) {
      setText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      fetchData();
    }
  }, [queryObject?.search_text, currentPage, pageSize, filterRemoveFlagCount]);

  useEffect(()=> {
    if(!devAssignee.length){
      fetchDevAssignee()
    }
    if(!jobIds.length){
      fetchJobIds()
    }
  },[])

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));

    setCurrentPage(1);
  }, []);

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];
    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.USERS_FILTER, filters);
    setCurrentPage(1);
    fetchData();
  };

  const handleRemoveAllFilter = () => {
    setFilteredData([]);
    setFilters(initialFIlterData);
    setEndDate('');
    setStartDate('');
  };

  const dropdownFilters = [
    {
      name: 'status',
      data: JOB_APPLICATION_STATUS_DATA,
      placeholder: 'Select Application Status',
      value: status,
    },
    {
      name: 'dev_asignee',
      data: devAssignee,
      placeholder: 'Select Dev Assignee',
      value: dev_asignee,
    },
    {
      name: 'job_id',
      data: jobIds,
      placeholder: 'Select job',
      value: job_id,
    },
    {
      name: 'experience',
      data: JOB_APPLICATION_EXPERIENCE_DATA,
      placeholder: 'Select Experience',
      value: experience,
    },
    {
      name: 'work_type_preference',
      data: JOB_WORK_PREFERENCE_DATA,
      placeholder: 'Select Work Type Preference',
      value: work_type_preference,
    },
  ];

  const SORT_TYPES = [
    {
      label: 'Created At',
      value: 'created_at',
    },
  ];

  const dropdownSorts = [
    {
      name: 'sortType',
      placeholder: '',
      value: sortType,
      data: SORT_TYPES,
    },
    {
      name: 'sortDirection',
      placeholder: '',
      value: sortDirection,
      data: SORT_DIRECTIONS,
    },
  ];

  const checkBoxFilter = [
    {
      name: "show_empty_comment",
      label: "Show Only With No Comments",
      value: show_empty_comment
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    sorts: dropdownSorts,
    checkBox: checkBoxFilter
  };

  const columns = useMemo(() => jobApplicationColumn, [jobApplicationColumn]);
  const data = useMemo(
    () => formatJobApplicationData(jobApplicationData),
    [jobApplicationData]
  );

  const lsLocalStorageManageColumns = getFilterFromLocalStorage(
    ENTITY_COLUMNS_TYPES.USERS_COLUMNS
  );
  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = true;
          return acc;
        }, {})
  );

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.USERS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );

  const handleFetchSearch = useCallback(
    (search) => {
      setCurrentPage(1);
      fetchData(search);
    },
    [fetchData]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();
    handleFetchSearch(text);
  };

  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find((item) => item._id === row._id);

      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item._id !== findData._id);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  const selectedRowsId = selectedRowsData.map((item) => item._id);
  const selectedData = useMemo(
    () => selectedRowsId.map((id) => data.find((row) => row._id === id)),
    [selectedRowsId, data]
  );

  const filterById = (selectedArray, allData) => {
    return allData.filter((data) =>
      selectedArray.some((item) => item._id === data._id)
    );
  };

  const handleMultipleUpdateStatus = async ({ updatedStatus, comment, sendEmail }) => {
    const filterdToBeUpdatedData = filterById(
      selectedRowsData,
      jobApplicationData
    );
    try {
      if (updatedStatus === MISCELLANEOUS.send_remider_mail) {
        const ids = filterdToBeUpdatedData.map((item) => {
              return item._id;
            });
        await apiCall.post(SEND_ASSIGNMENT_REMINDER_API, {
          ids,
        });
        setFilterRemoveFlagCount((prev) => prev + 1);
        showToast({
          message: "Reminder mails sent successfully",
          customStyle: {
            background: "green",
            color: "#fff",
            padding: "10px",
          },
        });
      } else {
        const updatedData = filterdToBeUpdatedData.map((item) => {
          const status = {
            _id: item._id,
            status: updatedStatus,
            send_email: sendEmail
          };
          if(updatedStatus === MISCELLANEOUS.add_comment){
            delete status?.status
          }
          if (comment) {
            status.comment = comment;
          }
          return status;
        });
        await apiCall.patch(JOB_APPLICATIONS_API, {
          updateData: updatedData,
        });
        setFilterRemoveFlagCount((prev) => prev + 1);
        showToast({
          message: "Application status updated successfully",
          customStyle: {
            background: "green",
            color: "#fff",
            padding: "10px",
          },
        });
      }

      setSelectedRowsData([]);
    } catch (error) {
      showToast({
        message: error.message,
        customStyle: {
          background: "#ff4d4f",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };
  
  const handleUpdateStatus = async ({ updatedApplicationStatus, _id, comment, sendEmail }) => {
    try {
      if (updatedApplicationStatus === MISCELLANEOUS.send_remider_mail) {
        await apiCall.post(SEND_ASSIGNMENT_REMINDER_API, {
          ids: [_id],
        });
        setFilterRemoveFlagCount((prev) => prev + 1);
        showToast({
          message: "Reminder mail sent successfully",
          customStyle: {
            background: "green",
            color: "#fff",
            padding: "10px",
          },
        });
      }else{
        const status = {
          status: updatedApplicationStatus,
          _id,
          send_email: sendEmail
        }
        if(comment){
          status.comment = comment
        }
        if(updatedApplicationStatus === MISCELLANEOUS.add_comment){
          delete status?.status;
        }
        await apiCall.patch(JOB_APPLICATIONS_API, {
          updateData: [status],
        });
        setFilterRemoveFlagCount((prev) => prev + 1);
        showToast({
          message: 'Application status updated successfully',
          customStyle: {
            background: 'green',
            color: '#fff',
            padding: '10px',
          },
        });
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <Box height="100vh" bg="#F8FAFB">
        <Flex
          justify="space-between"
          align="center"
          p="2"
          boxShadow="sm"
          borderRadius="sm"
          pos="fixed"
          style={{ width: 'calc(100vw - 96px)' }}
          zIndex={1000}
        >
          <HStack spacing="4" p={2}>
            <Box position="relative" display="inline-block">
              <Button
                paddingX={6}
                leftIcon={<FiFilter />}
                rightIcon={<FiChevronsDown />}
                colorScheme="teal"
                variant="outline"
                borderRadius="md"
                size="sm"
                onClick={() => {
                  toggleSidebar();
                  setFilters(lsFilters);
                }}
              >
                Filter
              </Button>
              {filteredData?.length > 0 && (
                <Badge
                  position="absolute"
                  top="-3"
                  right="0"
                  colorScheme="teal"
                  borderRadius="full"
                  px={2}
                  fontSize="0.8em"
                >
                  {filteredData?.length}
                </Badge>
              )}
            </Box>

            <ManageColumns
              columns={columns}
              handleColumnChange={handleColumnChange}
              columnVisibility={columnVisibility}
              setManageColumnSelectAllCheckbox={
                setManageColumnSelectAllCheckbox
              }
              manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
            />

            <form onSubmit={handleInputFormSubmit}>
              <InputGroup size="sm">
                <SearchBar
                  background="#F9F9FB"
                  color="#637381"
                  setSearchText={(e) =>{
                    setText(e.target.value)
                    console.log("value", e.target.value)
                  }}
                  searchText={text}
                  borderRadius="5px"
                  borderWidth="0.5px"
                  borderColor="#637381"
                />
              </InputGroup>
            </form>
          </HStack>
          <Text
            borderRadius="5px"
            borderWidth="0.5px"
            borderColor="#637381"
            background="#F9F9FB"
            color="#637381"
            p={1}
          >
            Total Applications: {totalCount}
          </Text>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => setCurrentPage(value)}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageSizeToLocalStorage={(value) =>
              setFilterToLocalStorage(
                ENTITY_FILTER_TYPES.USERS_PAGE_SIZE,
                value
              )
            }
          />
        </Flex>

        <SidebarFilter
          filters={allFiltersProps}
          handleFilterChange={handleFilterChange}
          handleApplyFilter={handleApplyFilter}
          isOpen={isOpenFilterSidebar}
          onClose={toggleSidebar}
          appliedFilters={filters}
          lsFilters={lsFilters}
          setFilters={setFilters}
          handleRemoveAllFilter={handleRemoveAllFilter}
          isCalenderFilter={true}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          enableMultiselect={true}
          multiSelectKey={["status", "job_id", "work_type_preference", "experience"]}
        />
        {jobApplicationData?.length !== 0 ? (
          <>
            <CustomTable
              columns={visibleColumns}
              data={data}
              onOpen={onOpen}
              onClose={onClose}
              isOpen={isOpen}
              handleRowSelect={handleRowSelect}
              onSelectedAction={setSelectedAction}
              selectedData={selectedData}
              selectedRowsData={selectedRowsData}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsSlug={selectedRowsId}
              menuComponent={
                <Menu
                  onUpdate={() => {}}
                  onUpdatePropertyStatus={handleUpdateStatus}
                  selectedAction={selectedAction}
                />
              }
              multiSelectMenuComponent={
                selectedRowsId?.length > 0 &&
              (
                  <MultiSelectMenu
                    multiSelectCurrentValue={selectedData[0]?.status}
                    onMultipleUpdateStatus={handleMultipleUpdateStatus}
                    selectedAction={selectedAction}
                    selectedRowsData={selectedRowsData}
                    bgColor="#B2F5EA"
                    dataType="jobApplications"
                  />
                )
              }
            />
          </>
        ) : !loading ? (
          <NoDataFound />
        ) : null}

        <OverlayLoaderComponent isOpen={loading} />
      </Box>
    </>
  );
}
