import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, HStack, Link, Spinner } from "@chakra-ui/react";
import SelectInput from "components/addForm/SelectInput";
import GenericForm from "components/BuilderForm/GenericForm";
import { BUILDER_FORM_TYPE } from "constants/dbEnums";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "hooks/useApi";
import SearchInput from "components/addForm/searchInput";
import { applyUploadedImagesToCompanyData } from "./formHandlers";
import formValidation, { customFormkeysArray, customFormKeys } from "components/BuilderForm/Validation";
import toast from "react-hot-toast";
import { createForm } from "./apiService";
import { BUILDER_FORM_TYPE_TAGS } from "constants/dbEnums";
import { getFormData } from "../form/apiService";
import { useQueryParams } from "utils/common";
import { COLLECTION_TYPE } from "constants/defaultState";
import { AGENT_TYPES } from "constants/defaultState";
import { SEARCH_FOR } from "constants/defaultState";

const CreateBuilder = () => {
  const initialFormData = { formType: "" };
  const [formData, setFormData] = useState(initialFormData);
  const [formType, setFormType] = useState("");
  const history = useHistory();
  const { apiCall } = useApi();
  const [errors, setErrors] = useState({});
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { propertyId: formId, collectionType, builderId, companyName, slugUrl, agentId } = useQueryParams();
  const isAgentForm = formType === BUILDER_FORM_TYPE_TAGS.Agent;
  const isBuilderForm = formType === BUILDER_FORM_TYPE_TAGS.Builder;
  console.log("formData", formData, formType);

  const handleFormTypeChange = (e) => {
    const { name, value } = e.target;
    setFormType(value);
    const selectedFormId = value === BUILDER_FORM_TYPE_TAGS.Builder ? builderId : value === BUILDER_FORM_TYPE_TAGS.Agent ? agentId : formId;
    if (selectedFormId) {
      getFormDataFn(value, selectedFormId);
    } else {
      setFormData({ ...initialFormData, [name]: value });
    }
  };
  const handleFormChange = (e) => {
    const { name, value, company_id, slug_url, companyName, user_id } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[name] = value;
      if (name === "builder_id") {
        if (company_id) {
          updatedData["company_id"] = company_id;
        }
      }
      if (name === "company_id") {
        updatedData["company_name"] = companyName;
        updatedData["slug_url"] = slug_url;
        updatedData["user_id"] = user_id;

      }
      if (name === "user_id") {
        updatedData["slug_url"] = slug_url;
      }
      return updatedData;
    });
  };

  const validateForm = ({updatedFormData}) => {
    const payload = updatedFormData;
    let schema;
    if(formData._id){
      schema = formValidation[formType].update;
    } else {
      schema = formValidation[formType].create;
    }
    if (!schema) {
      console.error(`No validation schema found for form type: ${formType}`);
      toast.error(`No validation schema found for form type: ${formType}`);
      setIsSubmittingForm(false);
      return { newErrors: {}, payload };
    }
    const { error } = schema.validate(payload, { abortEarly: false });
    const newErrors = {};
    let part;
    let message;
    let localityErrors = [];

    if (error) {
      error.details.forEach((detail) => {
        const path = detail.path;
        let current = newErrors;
        const key = detail?.context?.key;
        const keysArray = customFormkeysArray[formType];
        const newKey = customFormKeys[formType];
        message = detail.message.replace(detail.context.label, key);
        if (keysArray?.includes(key)) {
          if (!current[newKey[key]]) {
            current[newKey[key]] = {};
          }
          current[newKey[key]][key] = message;
        }
        if (path.includes('address')) {
          if (path.includes('locality')) {
            localityErrors.push("Locality is required");
          } else if (path.length === 1) {
            current["locality"] = "Locality is not allowed to be empty";
          } else {
            localityErrors.push(message);
          }
        }
        for (let i = 0; i < path.length; i++) {
          part = path[i];
          if (i === path.length - 1) {
            current[part] = message;
          } else {
            if (!current[part]) {
              current[part] = {};
            }
            current = current[part];
          }
        }
        if (localityErrors.length > 0) {
          newErrors["locality"] = localityErrors.join(", ");
        }
      });
    }
    return { newErrors, payload };
  };
  const commonFields = ['_id', 'user_id'];
  const builderFields = [
    'company_id', 'experience', 'operating_cities', 'dealing_property_types',
    'meta_title', 'meta_description', 'slug_url'
  ];
  const companyFields = [
    'name', 'registered_name', 'cin', 'registration_date', 'about', 'address',
    'gst_no', 'RERA_ID', 'rera_certificate', 'gst_certificate', 'total_members',
    'expertise_in', 'website', 'email', 'trade_license_registration', 'photo'
  ];
  const agentFields = [
    'company_id', 'deals_in', 'dealing_property_type', 'areas_of_operation',
    'nar_registration', 'loan_offers', 'rera_certified', 'meta_title', 'meta_description', 'slug_url'
  ];

  const handleFormSubmit = async () => {
    try {
      setIsSubmittingForm(true);
      let updatedFormData = formData;
      if(formType === BUILDER_FORM_TYPE_TAGS.Company){
        updatedFormData = await applyUploadedImagesToCompanyData({ apiCall, formData });
        const filterEmptyFields = (data) =>
          data ? Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== "")) : {};

        updatedFormData.address = filterEmptyFields(updatedFormData.address);
      }  
      if (formType === BUILDER_FORM_TYPE_TAGS.Agent) {
        const filterEmptyFields = (data) =>
          data ? Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== "")) : {};
        if (Array.isArray(updatedFormData.areas_of_operation)) {
          updatedFormData.areas_of_operation = updatedFormData.areas_of_operation.map((addressObj) => filterEmptyFields(addressObj));
        }
      }
      const buildValidateData = (fields) => {
        return fields.reduce((acc, field) => {
          const value = updatedFormData?.[field];
          if (value !== undefined && value !== "" && value !== null) {
            acc[field] = updatedFormData[field];
          }
          return acc;
        }, {});
      };  
      const validateData = {
        ...buildValidateData(commonFields),
        ...(isBuilderForm ? buildValidateData(builderFields) : isAgentForm ? buildValidateData(agentFields) : buildValidateData(companyFields)),
      };
      if (formType === BUILDER_FORM_TYPE_TAGS.Company && formData._id && agentId) {
        validateData.isAgent = true;
      }
      const {newErrors, payload} = validateForm({updatedFormData: validateData});
      if (Object.keys(newErrors)?.length > 0) {
        setErrors(newErrors);
        console.error('form submit error', newErrors);
        toast.error("Please fill in all the required fields.");
        setIsSubmittingForm(false);
        return;
      }
      const response = await createForm({ apiCall, payload, formType });
      console.log("response", response);
      if (response.data) {
        setFormType("");
        setFormData(initialFormData);
        if(response?.data?.company_id){
          history.push(`/admin/form?property_type=projects`)
        }
      setIsSubmittingForm(false);
      }
    } catch (error) {
      console.log("error creating builder form", error);
      setIsSubmittingForm(false);
    }
  };
  const handleRouteRedirect = () => {
    history.push(`/admin/form?property_type=projects`)
  };

  const getFormDataFn = async (selectedFormType, selectedFormId) => {
    try {
      const renderFormId = selectedFormId || formId;
      const renderFormType = selectedFormType || BUILDER_FORM_TYPE_TAGS.Company;
      const isBuilderFormType = renderFormType === BUILDER_FORM_TYPE_TAGS.Builder;
      const isAgentFormType = renderFormType === BUILDER_FORM_TYPE_TAGS.Agent;
      const apiType = isBuilderFormType ? COLLECTION_TYPE.Builder : isAgentFormType ? COLLECTION_TYPE.Agent : collectionType;
      const response = await getFormData({ apiCall, propertyId: renderFormId, collectionType: apiType });
      
      setFormType(renderFormType);
      setFormData({
        ...response,
        ...((isBuilderFormType || isAgentFormType) ? { company_name: companyName, slug_url: slugUrl, 
        ...(isAgentFormType && { agent_type: companyName ? 'COMPANY_AGENT' : 'INDIVIDUAL_AGENT' })  } : { user_id: response.created_by })
      });
    } catch (error) {
      console.log("Error fetching property data", error);
    }
  };

  useEffect(() => {
    if (formId && collectionType) {
      getFormDataFn();
    }
  }, [formId, collectionType]);

  return (
    <Box
      p={4}
      px={{ base: 4, md: 8 }}
      maxW={{ base: "100%", md: "992px" }}
      mx="auto"
    >
      <Box
        onClick={() => history.push("/admin/builders")}
        style={{ cursor: "pointer" }}
      >
        <ArrowBackIcon />
      </Box>
      <Box textAlign="center" mb={6}>
        <Heading>Create Builder/Company</Heading>
      </Box>
      <Box textAlign="right" mb={6}>
        <Link onClick={handleRouteRedirect} _hover={{ textDecoration: "underline" }} color="blue.500">
          <Heading size="md">Create Projects</Heading>
        </Link>
      </Box>
      <HStack spacing={10} mb={10}>
        <SelectInput
          name="formType"
          label="Form Type"
          placeholder="Search by Phone Number"
          options={BUILDER_FORM_TYPE}
          selectedValue={formType}
          isRequired={true}
          onChange={handleFormTypeChange}
          error={errors.formType}
        />
        {isAgentForm && 
        <SelectInput
        name="agent_type"
        label="Agent Type"
        placeholder="Select Agent Type"
        options={AGENT_TYPES}
        selectedValue={formData.agent_type}
        isRequired={true}
        onChange={handleFormChange}
        error={errors.agent_type}
        disabled={formData?._id && formData.agent_type === 'COMPANY_AGENT'}
      />}
        {(isBuilderForm || isAgentForm) && (
          <SearchInput
            label="Company Name"
            name="company_id"
            onChange={handleFormChange}
            placeholder="Search by Phone Number"
            type="text"
            isRequired={!isAgentForm}
            propertyType={formType}
            setErrors={setErrors}
            error={errors.company_id}
            value={collectionType ? formData?.company_name : '' }
            disabled={formData.agent_type === 'INDIVIDUAL_AGENT'}
            searchFor={SEARCH_FOR.COMPANY}
          />
        )}
      </HStack>
      <GenericForm
        formType={formType}
        formData={formData}
        onChange={handleFormChange}
        errors={errors}
        setErrors={setErrors}
      />
      {isSubmittingForm && <Spinner size='lg' />}
      <HStack mt={4} justifyContent="center" alignItems="center" spacing={4}>
        <Button
          colorScheme="teal"
          size="lg"
          px={8}
          py={6}
          fontSize="lg"
          bg="purple.500"
          borderRadius="5px"
          borderColor="#637381"
          background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
          color="#ffffff"
          onClick={handleFormSubmit}
          disabled={isSubmittingForm || !formType}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  );
};

export default CreateBuilder;
