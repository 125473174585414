import { COMMERCIAL_PROPERTY_TYPE } from "constants/dbEnums";
import { DEALS_IN } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { RESIDENTIAL_PROPERTY_TYPE } from "constants/dbEnums";

const Joi = require("joi");

const locationSchema = Joi.object({
  type: Joi.string(),
  label: Joi.string(),
  place_type: Joi.string(),
  coordinates: Joi.array().items(Joi.number()).required(),
  city: Joi.string().required().trim(),
  state: Joi.string(),
  state_code: Joi.string(),
  country_code: Joi.string(),
  country: Joi.string(),
  locality: Joi.string().required(),
  sub_locality: Joi.string(),
  street: Joi.string().allow("").trim(),
  building: Joi.string(),
  postal_code: Joi.string(),
});

const agentSchema = {
  create: Joi.object({
    user_id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .messages({
        "string.base": "user_id must be a string",
        "string.empty": "user_id cannot be empty",
      }),
    company_id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .messages({
        "string.base": "company_id must be a string"
      }),
    deals_in: Joi.array().items(Joi.string().valid(...Object.values(DEALS_IN))),
    dealing_property_type: Joi.array()
      .items(
        Joi.string()
          .valid(
            ...Object.values(RESIDENTIAL_PROPERTY_TYPE),
            ...Object.values(COMMERCIAL_PROPERTY_TYPE),
            PROPERTY_COLLECTIONS.PLOT
          )
      ),
    areas_of_operation: Joi.array().items(locationSchema).min(1).required(),
    nar_registration: Joi.boolean(),
    loan_offers: Joi.array().items(Joi.string()),
    rera_certified: Joi.boolean(),
    meta_title: Joi.string().trim().required(),
    meta_description: Joi.string().trim().required(),
    slug_url: Joi.string().trim().required(),
  }),
  update: Joi.object({
    _id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .messages({
        "string.base": "user_id must be a string",
        "string.empty": "user_id cannot be empty",
      }),
    user_id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required()
      .messages({
        "string.base": "user_id must be a string",
        "string.empty": "user_id cannot be empty",
      }),
    company_id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .messages({
        "string.base": "company_id must be a string"
      }),
    deals_in: Joi.array().items(Joi.string().valid(...Object.values(DEALS_IN)).required()).required(),
    dealing_property_type: Joi.array()
      .items(
        Joi.string()
          .valid(
            ...Object.values(RESIDENTIAL_PROPERTY_TYPE),
            ...Object.values(COMMERCIAL_PROPERTY_TYPE),
            PROPERTY_COLLECTIONS.PLOT
          )
          .required()
      )
      .required(),
    areas_of_operation: Joi.array().items(locationSchema).min(1).required(),
    nar_registration: Joi.boolean(),
    loan_offers: Joi.array().items(Joi.string()),
    rera_certified: Joi.boolean(),
    meta_title: Joi.string().trim().required(),
    meta_description: Joi.string().trim().required(),
    slug_url: Joi.string().trim().required(),
  }),
};

export default agentSchema;
