import React, { useState, useEffect } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody
} from "@chakra-ui/react";
import useApi from "hooks/useApi";
import { showToast } from "components/toast/Toast";
import { filterUnusedData } from "views/admin/form/formHandlers";
import BrokerGroupForm from "./BrokerGroupForm";
import { getFormData, submitForm } from "../services/brokerGroupService";
import { useAuth } from "contexts/AuthContext";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";

const initialFormData = {
  name: "",
  property_type: "",
  ad_type: "",
  comment: "",
  location: {},
};

const BrokerGroupModal = ({ isOpen, onClose, selectedBrokerGroup, afterBrokerGroupUpdate }) => {
  const {plans} = useAuth()
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { apiCall } = useApi();
  console.log('form data', formData);

  const getFormDataFn = async (id) => {
    try {
      const response = await getFormData({ apiCall, groupId: id });
      const updatedFormData = {
        ...response,
        users: response.brokers.map((broker) => broker.user_id),
      };
      setFormData(updatedFormData);
    } catch (error) {
      console.log("Error fetching broker group details", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        [name]: value,
      };
      /**If property_type is 'plot', set ad_type to 'sale'**/
      if (name === "property_type") {
        if(value === PROPERTY_COLLECTIONS.PLOT) {
          updatedFormData.ad_type = AD_TYPE_TAGS.SELL;
        }
        if(value === PROPERTY_COLLECTIONS.PG) {
          updatedFormData.ad_type = AD_TYPE_TAGS.RENT;
        }
      }
      return updatedFormData;
    });
  };

  const handleSubmit = async () => {
    try {
        setIsSubmittingForm(true);
        const payload = {
            name: formData.name,
            property_type: formData.property_type,
            ad_type: formData.ad_type,
            location: formData.location,
            comment: formData.comment,
            ...(formData.users?.length > 0 && { users: formData.users }),
            ...(formData._id && { group_id: formData._id })
        };
        filterUnusedData(payload)
      const response = await submitForm({apiCall, payload, plans});
      if(response.data){
        setIsSubmittingForm(false);
        handleReset();
        if(afterBrokerGroupUpdate){
          afterBrokerGroupUpdate(response.data.data);
        }
      }
    } catch (error) {
      console.error("Error submitting requirement:", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const handleLocation = (e) => {
    const { value: selectedValue } = e.target;
    const updatedValue = {
      city: selectedValue.name,
    };
    setFormData((prev) => ({
      ...prev,
      ["location"]: updatedValue,
    }));
  };

  const handleLocality = (e) => {
    const { name, value: selectedValue } = e.target;
    if (name === "locality") {
      const data = JSON.parse(selectedValue);
      setFormData((prev) => ({
        ...prev,
        ["location"]: data,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        ["location"]: {
          ...formData.location,
          [name]: selectedValue,
        },
      }));
    }
  };

  useEffect(() => {
    if (selectedBrokerGroup?._id) {
      getFormDataFn(selectedBrokerGroup?._id);
    } else {
      setFormData(initialFormData);
    }
  }, [selectedBrokerGroup]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction={{ base: "column", md: "column" }} gap={2}>
            {/* Form Section */}
            <BrokerGroupForm
              formData={formData}
              handleChange={handleChange}
              handleLocation={handleLocation}
              handleLocality={handleLocality}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              editingId={formData._id}
              isDisabled={isSubmittingForm}
              isSubmittingForm={isSubmittingForm}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BrokerGroupModal;
