import ShareFlatForm from "./ShareFlatForm";

const ShareFlatModal = ({ userData, setShareFlatData, shareFlatData, shareFlatErrors }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareFlatData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <ShareFlatForm
        onChange={handleChange}
        formData={shareFlatData}
        errors={shareFlatErrors}
        userData={userData}
      />
    </div>
  );
};

export default ShareFlatModal;
