import { showToast } from "components/toast/Toast";
import { UPDATE_EXPIRY } from "constants/endpoints";

export const submitForm = async ({ apiCall, payload }) => {
  try {
    const url = UPDATE_EXPIRY;
    const response = await apiCall.patch(url, payload);
    if (response) {
      showToast({
        message: response.data.message,
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error updating expiry", error);
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};
