import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import useApi from "hooks/useApi";
import { CREATE_USER } from "constants/endpoints";
import { showToast } from 'components/toast/Toast';
import { CenteredSpinner } from "components/loader/Loader";

const roleOptions = [
  { id: "1", label: "Individual", value: "owner" },
  { id: "2", label: "Agent", value: "agent_dealer" },
  { id: "3", label: "Builder", value: "builder" },
];
const initialFormState = {
  first_name: '',
  last_name: '',
  email: null,
  phone: '',
  role: 'owner',
};

const SignupFormModal = ({
  isOpen,
  onClose,
  setOpenLoginForm,
  selectedUser,
  setSelectedUser,
  setUserData
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { apiCall, loading } = useApi();
  const [form, setForm] = useState({ ...initialFormState });

  useEffect(() => {
    if (!isOpen) {
      setOpenLoginForm(false);
      setForm(initialFormState);
      setSelectedUser({});
    } 
    if(selectedUser){
      setForm(selectedUser);
    }
  }, [isOpen, setOpenLoginForm]);

  const handleFormSubmit = async () => {
    const isUpdating = form?._id ? true : false;
    const method = isUpdating ? 'patch' : 'post';
    try {
      const user = await apiCall[method](CREATE_USER, form);
      if (user?.data) {
        setUserData(prevUserData => {
          if (isUpdating) {
            return prevUserData.map(item =>
              item._id === form._id ? { ...item, ...form } : item
            );
          }
          return [user.data.userData, ...prevUserData];
        });
        setForm({});
        showToast({
          message: isUpdating ? 'Account updated Successfully' : 'Account Created Successfully',
          success: true,
          customStyle: {
            background: '#52c41a',
            color: '#fff',
            padding: '10px',
          },
        });
        onClose();
        setOpenLoginForm(false);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
      showToast({
        message: `Error: ${errorMessage}`,
        success: false,
        customStyle: {
          background: '#F24052',
          color: '#fff',
          padding: '10px',
        },
      });
      if (error.response.status !== 409 && error.response.status !== 400) {
        onClose();
        setOpenLoginForm(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleRoleChange = (value) => {
    setForm({ ...form, role: value });
  };

  const isButtonDisabled = () => {
    return !(form.first_name && form.last_name && form.phone && form.phone.length === 10 && !loading);
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create user account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            <FormControl>
              <FormLabel>Role</FormLabel>
              <RadioGroup value={form.role} onChange={handleRoleChange}>
                <Stack direction="row" spacing={4} width="100%" justifyContent="space-between">
                  {roleOptions.map((option) => (
                    <Radio key={option.id} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel>First name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="First name"
                value={form.first_name}
                onChange={handleChange}
                name="first_name"
              />
            </FormControl>

            <FormControl mt={3} isRequired>
              <FormLabel>Last name</FormLabel>
              <Input
                placeholder="Last name"
                value={form.last_name}
                onChange={handleChange}
                name="last_name"
              />
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email"
                value={form.email}
                onChange={handleChange}
                name="email"
              />
            </FormControl>
            {loading && <CenteredSpinner/>}
            <FormControl mt={3} isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="Phone Number"
                value={form.phone}
                onChange={handleChange}
                name="phone"
                maxLength={10}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
              color="#ffffff"
              mr={3}
              onClick={handleFormSubmit}
              disabled={isButtonDisabled()}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignupFormModal;
