import { Box, Button, IconButton, Flex, Text, Select, Checkbox } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { CloseIcon } from "@chakra-ui/icons";
import Dropdown from "components/dropdown/Dropdown";
import FilterCalendar from "components/calendar/FilterCalendar";
import { formatDateToDDMONTHNAMEYYYY } from "utils/common";
import { setFilterToLocalStorage } from "utils/common";
import { ENTITY_FILTER_TYPES } from "utils/common";
import CitySelectInput from "components/addForm/location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";


const SidebarFilter = ({
  isOpen,
  onClose,
  filters,
  handleFilterChange,
  handleApplyFilter,
  setFilters,
  lsFilters,
  isCalenderFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCity,
  setSelectedCity,
  locationCitiesFilter,
  handleRemoveAllFilter,
  enableMultiselect = false,
  multiSelectKey,
  appliedFilters,
}) => {
  const { dateRange = {} } = appliedFilters || {};
  const selectedStartDate = dateRange?.startDate ? dateRange?.startDate : startDate;
  const selectedEndDate = dateRange?.endDate ? dateRange?.endDate : endDate; 
  
  const handleApplyFilterSubmit = () => {
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.ADS_DATE_RANGE, [
      selectedStartDate,
      selectedEndDate,
    ]);
    handleApplyFilter();
    onClose();
  };

  const handleFilterCalenderChange = (e) => {
    const { name, value } = e.target;
    handleFilterChange(name, value);
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: isOpen ? 300 : 0 }}
      transition={{ duration: 0.5 }}
      style={{ overflow: "hidden" }}
      boxShadow="md"
    >
      <Box
        w="400px"
        h="100vh"
        bg="gray.50"
        color="black"
        p="4"
        pt="0"
        pos="fixed"
        top="0"
        right="0"
        display={isOpen ? "block" : "none"}
        boxShadow="md"
        zIndex={1000}
        overflowY="auto"
        overflowX={"hidden"}
        pb={"200px"}
      >
        <Flex gap="4" direction={"column"}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                onClick={() => {
                  onClose();
                  setFilters(lsFilters);
                }}
                size="sm"
                position="fixed"
                top="3"
                right="360px"
                zIndex={1000}
              />
              <Text fontSize="lg" fontWeight="bold" ms="8">
                Filters
              </Text>
            </Box>
            <Text
              ms="10"
              color="#0177B4"
              fontSize="16px"
              cursor={"pointer"}
              onClick={handleRemoveAllFilter}
            >
              Clear Filters
            </Text>
          </Box>
        </Flex>
        <Flex direction="column" gap={4} mt="4">
          {filters?.dropdowns?.map((dropdown, index) => (
            <Dropdown
              key={index}
              DROPDOWN_DATA={dropdown?.data}
              placeholder={dropdown.placeholder}
              onChange={(e) => handleFilterChange(dropdown.name, e)}
              variant="primary"
              size="sm"
              value={dropdown.value}
              isDisabled={dropdown.isDisabled}
              multiSelect={
                enableMultiselect && multiSelectKey.includes(dropdown?.name)
              }
            />
          ))}
        </Flex>
        {filters?.checkBox?.map((data, index) => (
          <Flex key={index} marginTop={5}>
            <Text
              cursor={data.isDisabled ? "not-allowed" : "pointer"}
              onClick={() => {
                if (!data.isDisabled) {
                  handleFilterChange(data.name, !data?.value);
                }
              }}
              fontWeight={"semibold"}
              color={data.isDisabled ? "gray.500" : "inherit"}
            >
              {data?.label}
            </Text>
            <Checkbox
              marginLeft={3}
              isChecked={data?.value}
              onChange={() => handleFilterChange(data.name, !data?.value)}
              isDisabled={data.isDisabled}
            />
          </Flex>
        ))}
        <Flex direction="column" mt={4}>
          <Text fontSize="lg" fontWeight="bold">
            Posted on
          </Text>
          {isCalenderFilter && (
            <>
              <FilterCalendar
                startDate={selectedStartDate}
                setStartDate={setStartDate}
                endDate={selectedEndDate}
                setEndDate={setEndDate}
                onChange={(e)=>handleFilterCalenderChange(e)}
              />
              <Flex mt={2} direction="column">
                <Text
                  fontSize="sm"
                  width="full"
                  border="1px solid black"
                  borderRadius={"md"}
                  p="2"
                >
                  Start Date: {formatDateToDDMONTHNAMEYYYY(selectedStartDate)}
                </Text>
                <Text
                  fontSize="sm"
                  width="full"
                  border="1px solid black"
                  borderRadius={"md"}
                  p="2"
                  mt="1"
                >
                  End Date: {formatDateToDDMONTHNAMEYYYY(selectedEndDate)}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        {locationCitiesFilter?.length > 0 && (
          <Flex direction="column" mt={8}>
            <CitySelectInput
              name="city"
              label="Location Filter"
              onChange={(e) => setSelectedCity(e.target.value.title)}
              result_type={LOCATION_TYPE.CITY}
              selectedValue={selectedCity}
            />
          </Flex>
        )}
        <Button
          style={{ position: "fixed", bottom: "5px", right: "5" }}
          borderRadius={"md"}
          variant="solid"
          bg="blue.600"
          color="white"
          width={"360px"}
          onClick={handleApplyFilterSubmit}
          background="linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"
          _hover={{
            background: "linear-gradient(180deg, #1A94D4 0%, #016299 100%)",
          }}
          _active={{
            background: "linear-gradient(180deg, #158AC2 0%, #01517E 100%)",
          }}
        >
          Apply
        </Button>
      </Box>
    </motion.div>
  );
};

export default SidebarFilter;
