import {
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { useTable, useGlobalFilter } from 'react-table';
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { cloneElement } from 'react';
import { LEAD_TYPES_TAGS } from 'constants/defaultState';

const customRowStyle = {
  fontSize: '13px',
  fontWeight: '400',
  color: '#404040',
  padding: '10px 4px',
};

const customColumnHeaderStyle = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '21px',
  color: '#404040',
  textAlign: 'center',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  whiteSpace: 'nowrap',
  padding: '10px 4px',
  position: 'sticky',
  top: '0',
  zIndex: 200,
  backgroundColor: '#f8f8f8',
};

const customActionColumnStyle = {
  position: 'sticky',
  right: 10,
  backgroundColor: '#f8f8f8',
  minWidth: '120px',
  maxWidth: '120px',
};

const CustomTable = ({
  columns,
  data,
  onSelectedAction,
  selectedRowsData,
  setSelectedRowsData,
  selectedRowsSlug,
  handleRowSelect,
  selectedData,
  menuComponent,
  multiSelectMenuComponent,
  sortableColumns = [], // List of sortable column IDs
  sortConfig,
  setSortConfig,
}) => {

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgHeader = useColorModeValue('gray.100', 'gray.700');
  const bgAction = useColorModeValue('gray.50', 'gray.800');

  const handleSelectAll = () => {
    if (selectedRowsData?.length === data?.length) {
      setSelectedRowsData([]);
    } else {
      setSelectedRowsData(data);
    }
  };

  const handleSort = async (columnId) => {
    if (!sortableColumns.includes(columnId)) return;

    let newSortConfig = {
      sort_by: columnId,
      sort_order: 'ascending',
    };

    if (
      sortConfig.sort_by === columnId &&
      sortConfig.sort_order === 'ascending'
    ) {
      newSortConfig.sort_order = 'descending';
    }

    setSortConfig(newSortConfig);
  };

  return (
    <Box
      overflowX="auto"
      mt="80px"
      style={{
        height: 'calc(100vh - 80px)',
      }}
      width="99%"
      scrollBehavior={'smooth'}
    >
      <Flex
        justify="flex-end"
        mb="4"
        pos="fixed"
        right={12}
        top={12}
        zIndex={999}
      >
        {multiSelectMenuComponent &&
          cloneElement(multiSelectMenuComponent, {
            multiSelectCurrentValue: selectedData[0]?.property_status
              ? selectedData[0]?.property_status
              : selectedData[0]?.status,
          })}
      </Flex>
      <Table
        {...getTableProps()}
        colorScheme="gray"
        size="sm"
        minWidth="1200px"
        css={{
          '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#f8f8f8',
          },
          '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'white',
          },
          '& tbody tr:hover': {
            backgroundColor: 'blue.50',
          },
        }}
      >
        <Thead>
          {headerGroups.map((headerGroup,index) => {
           
            return (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={index}
                position="sticky"
                top="0"
                zIndex={10}
                padding={'10px'}
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                bg={'gray.100'}
              >
                <Th>
                  <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    justify="center"
                    fontSize="12px"
                    color="gray.400"
                    whiteSpace={'nowrap'}
                    style={{ minWidth: '80px' }}
                  >
                    <Checkbox
                      isChecked={selectedRowsData?.length === data?.length}
                      onChange={handleSelectAll}
                    />
                    <Text>
                      Select{' '}
                      {selectedRowsData?.length > 0
                        ? `(${selectedRowsData?.length})`
                        : ''}
                    </Text>
                  </Flex>
                </Th>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      key={column.id}
                      borderColor={borderColor}
                      bg={bgHeader}
                      style={customColumnHeaderStyle}
                      onClick={() => handleSort(column.id)}
                    >
                      <Flex
                        align="center"
                        justify="center"
                        fontSize="12px"
                        color="gray.400"
                      >
                        {column.render('Header')}

                        {sortableColumns.includes(column.id) && (
                          <>
                            {sortConfig[column.id]?.isDesc ? (
                              <TriangleDownIcon ml={1} />
                            ) : (
                              <TriangleUpIcon ml={1} />
                            )}
                          </>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
                <Th
                  bg={bgHeader}
                  style={{
                    ...customActionColumnStyle,
                    zIndex: 10000,
                    fontSize: '12px',
                  }}
                >
                  Action
                </Th>
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const isSelected = selectedRowsSlug?.includes(
              row.original.slug_url || row.original._id
            );

            return (
              <Tr
                {...row.getRowProps()}
                key={row.id}
                style={{ height: '48px' }}
              >
                <Td
                  borderColor="transparent"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  height="48px"
                >
                  <Checkbox
                    isChecked={isSelected}
                    onChange={() => handleRowSelect(row.original)}
                  />
                </Td>
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps()}
                    key={cell.column.id}
                    borderColor={borderColor}
                    style={customRowStyle}
                    whiteSpace={'nowrap'}
                    textAlign={'center'}
                  >
                    {cell.render('Cell')}
                  </Td>
                ))}
                <Td
                  borderColor="transparent"
                  bg={bgAction}
                  style={customActionColumnStyle}
                  whiteSpace={'nowrap'}
                  me={24}
                >
                  {menuComponent
                    ? cloneElement(menuComponent, {
                        currentValue: row.original?.property_status
                          ? row.original?.property_status
                          : row.original?.leads?.length > 0 ? LEAD_TYPES_TAGS.shared : row.original?.status,
                        onSelectedAction: () => onSelectedAction(row.original),
                      })
                    : null}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomTable;
