export const ROLE_DROPDOWN_DATA = [
  {
    id: 1,
    label: 'Owner',
    value: 'owner',
  },
  {
    id: 2,
    label: 'Agent',
    value: 'agent',
  },
  {
    id: 3,
    label: 'Builder',
    value: 'builder',
  },
  {
    id: 4,
    label: 'Admin',
    value: 'admin',
  },
];

// plan: ["Freemium", "Premium", "Hexa Premium"]

export const PLAN_DROPDOWN_DATA = [
  {
    id: 1,
    label: 'Freemium',
    value: 'Freemium',
  },
  {
    id: 2,
    label: 'Premium',
    value: 'Premium',
  },
  {
    id: 3,
    label: 'Hexa Premium',
    value: 'Hexa Premium',
  },
];

export const EMAIL_TYPE_FILTER_OPTIONS = [
  {
    id: 1,
    label: 'Email Verified',
    value: 'verified',
  },
  {
    id: 2,
    label: 'Email Not Verified',
    value: 'not_verified',
  },
  {
    id: 3,
    label: 'Must Have Email',
    value: 'required',
  },
];

export const PHONE_VERIFIED_DROPDOWN_DATA = [
  {
    id: 1,
    label: 'Phone Verified',
    value: true,
  },
  {
    id: 2,
    label: 'Phone Not Verified',
    value: false,
  },
];

export const ONBOARDING_DROPDOWN_DATA = [
  {
    id: 1,
    label: 'OnBoarded',
    value: 'true',
  },
  {
    id: 2,
    label: 'Not OnBoarded',
    value: 'false',
  },
];

export const PLATFORM_DROPDOWN_DATA = [
  {
    id: 1,
    label: 'Web',
    value: 'web',
  },
  {
    id: 2,
    label: 'iOS',
    value: 'iOS',
  },
  {
    id: 3,
    label: 'Android',
    value: 'android',
  },
];

export const SELL_OR_RENT_TRANSACTION_TYPE_DATA = [
  {
    id: 1,
    label: 'Residential',
    value: 'residential_property',
  },
  {
    id: 2,
    label: 'Commercial',
    value: 'commercial_property',
  },
];

export const TRANSACTION_TYPE_DATA = [
  {
    id: 1,
    label: 'Residential',
    value: 'residential_property',
  },
  {
    id: 2,
    label: 'Commercial',
    value: 'commercial_property',
  },
  {
    id: 3,
    label: 'Plot',
    value: 'plot',
  },
  {
    id: 4,
    label: 'PG',
    value: 'PG',
  },
  {
    id: 5,
    label: 'Share Flat',
    value: 'share_flat',
  },
];

export const AD_TYPE_DATA = [
  {
    id: 1,
    label: 'Rent',
    value: 'rent',
  },
  {
    id: 2,
    label: 'Sale',
    value: 'sell',
  },
];

export const RENT_RESIDENTIAL_PROPERTY_TYPE = [
  {
    id: 1,
    label: 'Apartment',
    value: 'flat/apartment',
  },
  {
    id: 2,
    label: 'Individual Floor',
    value: 'floor',
  },
  {
    id: 3,
    label: 'Independent House',
    value: 'independent_house',
  },
  {
    id: 4,
    label: 'Independent Villa',
    value: 'vila/farm_house',
  },
  {
    id: 5,
    label: '1Rk/Studio House',
    value: '1_rk_studio',
  },
  {
    id: 6,
    label: 'Others',
    value: 'others',
  },
];

export const SELL_RESIDENTIAL_PROPERTY_TYPE = [
  {
    id: 1,
    label: 'Apartment',
    value: 'flat/apartment',
  },
  {
    id: 2,
    label: 'Individual Floor',
    value: 'floor',
  },
  {
    id: 3,
    label: 'Independent House',
    value: 'independent_house',
  },
  {
    id: 4,
    label: 'Independent Villa',
    value: 'vila/farm_house',
  },
  {
    id: 5,
    label: 'Plot/Land',
    value: 'plot',
  },
  {
    id: 6,
    label: 'Others',
    value: 'others',
  },
];

export const COMMERCIAL_PROPERTY_TYPE = [
  {
    id: 1,
    label: 'Office',
    value: 'office',
  },
  {
    id: 2,
    label: 'Retail Shop',
    value: 'retail_shop',
  },
  {
    id: 3,
    label: 'Showroom',
    value: 'showroom',
  },
  {
    id: 4,
    label: 'Warehouse',
    value: 'warehouse',
  },
  {
    id: 5,
    label: 'Others',
    value: 'others',
  },
];

export const PROPERTY_VERIFIED_TYPE = [
  {
    id: 1,
    label: 'Verified',
    value: true,
  },
  {
    id: 2,
    label: 'Not Verified',
    value: false,
  },
];

export const PROPERTY_STATUS = [
  {
    id: 1,
    label: 'Active',
    value: 'active',
  },
  {
    id: 2,
    label: 'Expired',
    value: 'expired',
  },
  {
    id: 3,
    label: 'Pending',
    value: 'pending_verification',
  },
  {
    id: 4,
    label: 'Deleted',
    value: 'deleted',
  },
  {
    id: 5,
    label: 'Inactive',
    value: 'inactive',
  },
  {
    id: 6,
    label: 'Rejected',
    value: 'rejected',
  },
  {
    id: 7,
    label: 'Edit',
    value: '/admin/form',
  },
];

export const PROJECT_LIST_STATUS = [
  {
    id: 1,
    label: 'Active',
    value: 'active',
  },
  {
    id: 3,
    label: 'Pending',
    value: 'pending_verification',
  },
  {
    id: 5,
    label: 'Inactive',
    value: 'inactive',
  },
  {
    id: 6,
    label: 'Rejected',
    value: 'rejected',
  },
  {
    id: 7,
    label: 'Edit',
    value: '/admin/form',
  },
];

export const USER_STATUS = [
  {
    id: 1,
    label: 'Active',
    value: 'active',
  },

  {
    id: 3,
    label: 'Pending',
    value: 'pending_verification',
  },

  {
    id: 5,
    label: 'Inactive',
    value: 'inactive',
  },
  {
    id: 6,
    label: 'Rejected',
    value: 'rejected',
  },
];

export const LISTING_SCORE_SORT = [
  {
    id: 1,
    label: 'High to Low',
    value: 'descending',
  },
  {
    id: 2,
    label: 'Low to High',
    value: 'ascending',
  },
];

export const POSTED_ON_SORT = [
  {
    id: 1,
    label: 'Latest',
    value: 'descending',
  },
  {
    id: 2,
    label: 'Earlier',
    value: 'ascending',
  },
];

export const SORT_TYPES = [
  {
    id: 1,
    label: 'Total Listing Score',
    value: 'total_listing_score',
  },
  {
    id: 2,
    label: 'Created At',
    value: 'created_at',
  },
  {
    id: 3,
    label: 'Expires On',
    value: 'expires_on',
  },
];

export const SORT_DIRECTIONS = [
  {
    id: 1,
    label: 'Descending',
    value: 'descending',
  },
  {
    id: 2,
    label: 'Ascending',
    value: 'ascending',
  },
];

export const JOB_APPLICATION_STATUS_DATA = [
  {
    id: 1,
    label: 'Need To Complete Assignment',
    value: 'need_to_complete_assignment',
  },
  {
    id: 2,
    label: 'Assignment Submitted',
    value: 'assignment_submitted',
  },
  {
    id: 3,
    label: 'Assignment Reviewed',
    value: 'assignment_reviewed',
  },
  {
    id: 4,
    label: 'Rejected',
    value: 'rejected',
  },
  {
    id: 5,
    label: 'Shortlisted',
    value: 'shortlisted',
  },
];
export const JOB_APPLICATION_EXPERIENCE_DATA = [
  {
    id: 1,
    label: 'Fresher',
    value: 'fresher',
  },
  {
    id: 2,
    label: '0 to 1 Years',
    value: '0_to_1',
  },
  {
    id: 3,
    label: '1 to 3 Years',
    value: '1_to_3',
  },
  {
    id: 4,
    label: '3 to 5 Years',
    value: '3_to_5',
  },
  {
    id: 5,
    label: '5 to 10 Years',
    value: '5_to_10',
  },
  {
    id: 6,
    label: '10 + Years',
    value: '10_plus_years',
  },
];

export const JOB_WORK_PREFERENCE_DATA = [
  {
    id: 1,
    label: 'Work From Office',
    value: 'wfo',
  },
  {
    id: 2,
    label: 'Hybrid',
    value: 'hybrid',
  }
];

export const PROJECT_STATUS_DATA = [
  {
    id: 1,
    label: 'Ready to Move',
    value: 'ready_to_move',
  },
  {
    id: 2,
    label: 'Under Construction',
    value: 'under_construction',
  },
  {
    id: 3,
    label: 'Upcoming',
    value: 'upcoming',
  },
];

export const USER_ACTIVITY_ROLE_DATA = [
  {
    id: 1,
    label: 'Owner',
    value: 'owner',
  },
  {
    id: 2,
    label: 'Admin',
    value: 'admin,marketing_admin,hr_admin',
  },
  {
    id: 3,
    label: 'Agent',
    value: 'agent_dealer',
  },
  {
    id: 4,
    label: 'Builder',
    value: 'builder',
  },
];

export const PROPERTY_TYPE_LIST = [
  {
    id: 1,
    label: 'Apartment',
    value: 'flat/apartment',
  },
  {
    id: 2,
    label: 'Individual Floor',
    value: 'floor',
  },
  {
    id: 3,
    label: 'Independent House',
    value: 'independent_house',
  },
  {
    id: 4,
    label: 'Independent Villa',
    value: 'vila/farm_house',
  },
  {
    id: 5,
    label: '1Rk/Studio House',
    value: '1_rk_studio',
  },
  {
    id: 6,
    label: 'Office',
    value: 'office',
  },
  {
    id: 7,
    label: 'Warehouse',
    value: 'warehouse',
  },
  {
    id: 8,
    label: 'Retail Shop',
    value: 'retail_shop',
  },
  {
    id: 9,
    label: 'Showroom',
    value: 'showroom',
  },
  {
    id: 10,
    label: 'Plot',
    value: 'plot',
  },
  {
    id: 11,
    label: 'PG',
    value: 'PG',
  }
]

export const USER_LEAD_FILTER_OPTIONS = [
  {
    id: 1,
    label: 'All Shared Pending',
    value: 'all_leads',
  },
  {
    id: 2,
    label: 'Shared',
    value: 'shared',
  },
  {
    id: 3,
    label: 'Unshared',
    value: 'unshared',
  },
];

export const REQUIREMENT_TYPE_DATA = [
  {
    id: 1,
    label: 'Rent',
    value: 'rent',
  },
  {
    id: 2,
    label: 'Buy',
    value: 'sell',
  }
]