import { CREATE_ADS_API } from "constants/endpoints";
import { showToast } from "../../../components/toast/Toast";
import { CREATE_PROJECTS } from "constants/endpoints";
import { ALL_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";

export const createForm = async ({ apiCall, formData, propertyType }) => {
  const isProject = propertyType === ALL_PROPERTY_TYPE_TAGS.projects;
  try {
    const method = formData._id ? 'patch' : 'post';
    const url = isProject ? CREATE_PROJECTS : CREATE_ADS_API;
    const response = await apiCall[method](url, formData);
    if (response) {
      showToast({
        message: formData?._id ? "Form updated successfully." : "Form has been successfully submitted",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating form:", error);
    showToast({
      message: error?.response?.data?.error?.message ? error?.response?.data?.error?.message  : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message ? error?.response?.data?.error?.message  : error?.response?.data?.error
  }
};

export const getStaticData = async ({ apiCall, formData, url }) => {
  try {
    const response = await apiCall.get(url, formData);
    return response;
  } catch (error) {
    console.error("Error fetching banks", error);
    showToast({
      message: error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error;
  }
};

export const getFormData = async ({ apiCall, propertyId, collectionType, searchBy }) => {
  const isProject = collectionType === ALL_PROPERTY_TYPE_TAGS.projects;
  try {
    const url = `/admin/${collectionType}/${propertyId}${searchBy ? `?searchBy=${searchBy}` : ""}`;
    const response = await apiCall.get(url);
    return response?.data;
  } catch (error) {
    console.error("Error fetching form:", error);
    const errorMessage = isProject ? error?.response?.data?.error?.message : error?.response?.data?.error;
    if(errorMessage){
      showToast({
        message: errorMessage,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    throw errorMessage ? errorMessage : error;
  }
};

export function removeKeyValueFromPayload(payload) {
  const {type, ad_type} = payload;
  let updatedPayload = payload;
  if(type === PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY){
    if(ad_type === AD_TYPE_TAGS.SELL){
      const {preferred_tenant, ...restPayload} = payload
      updatedPayload = restPayload;
    };
  }
  return updatedPayload;
}