import React, { useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import ManageColumns from "components/manageColumns/ManageColumns";
import useApi from "hooks/useApi";
import { EXPORT_USER } from "constants/endpoints";
import { showToast } from "components/toast/Toast";
import { getVisibleColumns } from "views/admin/users/services/userRequirementsService";
import { userColumnConfig } from "views/admin/users/variables";
import { CenteredSpinner } from "components/loader/Loader";

const initialVisibleColumns = 
userColumnConfig.reduce((acc, column) => {
  acc[column.accessor] = true;
  return acc;
}, {});

const DownloadUsersModal = ({ isOpen, onClose, filters }) => {
  const [allVisibleColumns, setAllVisibleColumns] = useState(true);
  const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);
  const { apiCall, loading } = useApi();

  const visibleColumnNames = useMemo(
    () => getVisibleColumns(visibleColumns),
    [visibleColumns, allVisibleColumns]
  );

  const handleColumnChange = (accessor) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  const handleDownloadUsers = async () => {
    try {
      const { role, plan, dateRange  } = filters;
      const payload = {
        selectedRows: visibleColumnNames,
        role,
        plan,
        start_date: dateRange?.startDate,
        end_date: dateRange?.endDate
      };
      const response = await apiCall.post(
        EXPORT_USER,
        payload,
        null,
        "arraybuffer"
      );
      console.log("Users Download API response received:", response);

      /**Create a Blob object from the response data**/
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      /**Create a URL for the Blob**/
      const urlObject = URL.createObjectURL(blob);
      /**Create a temporary link element for triggering the download**/
      const link = document.createElement("a");
      link.href = urlObject;
      link.download = "users.xlsx";

      /**Append link to the document, trigger the download, and remove the link**/
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject);
      showToast({
        message: "Users File downloaded successfully.",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
      console.log("Users File download triggered successfully.");
    } catch (error) {
      console.error("Error while downloading users:", error);
      showToast({
        message: "Failed to download user data",
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent minHeight="80vh" maxHeight="90vh" maxWidth="500px">
          <ModalHeader pt={2} pb={1} pl={6} pr={6}>
             Select Columns to Download
          </ModalHeader>
          <ModalCloseButton />
          {loading && <CenteredSpinner/>}
          <ModalBody>
            <Flex direction="row" justify="space-between" height="100%">
              <Box flex="1">
                <ManageColumns
                  columns={userColumnConfig}
                  handleColumnChange={handleColumnChange}
                  columnVisibility={visibleColumns}
                  setManageColumnSelectAllCheckbox={setAllVisibleColumns}
                  manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={allVisibleColumns}
                  isMenuAlwaysOpen={true}
                />
              </Box>
              <Box>
                <Button onClick={handleDownloadUsers} width="full" isDisabled={loading || !visibleColumnNames?.length}>
                  Download Users
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DownloadUsersModal;
