import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useApi from 'hooks/useApi';
import { LOGIN_API } from 'constants/endpoints';
import { showToast } from 'components/toast/Toast';
import { TOKEN_VERIFICATION_API } from 'constants/endpoints';
import { GET_PLANS } from 'constants/endpoints';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [role, setRole] = useState(() => localStorage.getItem("role"))
  const { apiCall, loading, error } = useApi();
  const [plans, setPlans] = useState([]);

  const history = useHistory();

  const getPlans = async ()=>{
    const response = await apiCall.get(GET_PLANS);
    setPlans(response?.data?.data)
  }

  useEffect(() => {
    if (token) {
      getPlans();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const verifyToken = async () => {
        try {
          await apiCall.get(`${TOKEN_VERIFICATION_API}`);
        } catch (error) {
          let errorMessage = 'An error occurred';
          let customStyle = {
            background: '#ff4d4f',
            color: '#fff',
            padding: '10px',
          };

          if (error.response) {
            // Server responded with a status code out of the 2xx range
            switch (error.response.status) {
              case 400:
                errorMessage = 'Bad request. Please check your input.';
                break;
              case 401:
                errorMessage = 'Unauthorized. Please log in again.';
                break;
              case 403:
                errorMessage =
                  'Forbidden. You do not have the required permissions.';
                break;
              case 404:
                errorMessage =
                  'Not found. The requested resource could not be found.';
                break;
              case 500:
                errorMessage = 'Internal server error. Please try again later.';
                break;
              default:
                errorMessage =
                  error.response.data?.error || 'Something went wrong.';
                break;
            }
          } else if (error.request) {
            // No response received from the server
            errorMessage =
              'No response from the server. Please check your internet connection.';
          } else {
            // Error setting up the request
            errorMessage = error.message;
          }

          if (error?.response?.status === 401) {
            history.push('/auth');
            localStorage.removeItem('token');
            setToken(null);
          }

          showToast({
            message: errorMessage,
            customStyle: customStyle,
          });
        }
      };

      verifyToken();
    }
  }, [token, history]);

  const handleGoogleLogin = async (credentials) => {
    try {
      const data = await apiCall.post(`${LOGIN_API}`, credentials);

      localStorage.setItem('token', data?.data?.token);
      localStorage.setItem('role', data?.data?.role);
      setToken(data?.data?.token);
      setRole(data?.data?.role);
      history.push('/admin/default');
    } catch (error) {
      showToast({
        message:
          error?.response?.data?.error?.message ||
          error?.response?.data?.error ||
          error?.message,
        customStyle: {
          background: '#ff4d4f',
          color: '#fff',
          padding: '10px',
        },
      });
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        role,
        logout,
        handleGoogleLogin,
        loading,
        error,
        plans
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
