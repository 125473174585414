import React, { useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  Flex,
  Heading,
  ModalCloseButton,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  IconButton
} from "@chakra-ui/react";
import CitySelectInput from "components/addForm/location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";
import SelectInput from "components/addForm/SelectInput";
import { CenteredSpinner } from "components/loader/Loader";
import debounce from "lodash.debounce";
import { BROKER_GROUP_API } from "constants/endpoints";
import useApi from "hooks/useApi";
import { renderGroupInfo, validateUserRequirements } from "../services/sendLeadService";
import { CloseIcon } from "@chakra-ui/icons";
import { REQUIREMENT_TYPE } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";

const SendLeadForm = ({
  handleChange,
  handleSubmit,
  formData,
  handleLocation,
  handleApplyFilter,
  listData,
  handleItemClick,
  handleClearFilter,
  brokerGroup,
  isSubmittingForm,
  userList,
  setBrokerGroupData,
  setBrokerGroup,
  setFormData,
  handleClearGroup
}) => {
  const groupNameMissing = !formData.group_id;
  const disableFilter = !formData?.location?.city && !formData?.ad_type;
  const { apiCall } = useApi();

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchGroups(brokerGroup);
    }
  };

  const handleGroupChange = (e) => {
    const { value} = e.target;
    setBrokerGroup(value);
    setFormData((prev) => ({
      ...prev,
      ['group_id']: "",
    }));
  }

  const fetchGroups = useMemo(
    () =>
      debounce(async (query) => {
        const trimmedQuery = query.trim();
        if (!trimmedQuery) return [];
        try {
          const apiUrl = BROKER_GROUP_API;
          const url = `${apiUrl}?search_text=${trimmedQuery}`;
          const response = await apiCall.get(url);
          setBrokerGroupData(response?.data?.groups || []);
        } catch (err) {
          console.error("Fetch error:", err);
        }
      }, 300),
    [apiCall]
  );

  const validationErrors = validateUserRequirements(userList);
  const hasRequirementError = validationErrors.length > 0;

  return (
    <>
      {/* Filters Section */}
      <Box>
        <Flex justify="space-between" align="center" mb={1} mt={1}>
          <Heading size="md">Apply Filter</Heading>
          <Flex align="center" gap={19}>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={handleApplyFilter}
              size="sm"
              disabled={disableFilter}
            >
              Apply
            </Button>
            <Button
              colorScheme="grey"
              variant="ghost"
              onClick={handleClearFilter}
              size="sm"
            >
              Clear
            </Button>
            <ModalCloseButton position="relative" top="0" />
          </Flex>
        </Flex>
        <Flex gap={2}>
          <FormControl flex={1}>
            <CitySelectInput
              name="city"
              label="Location"
              onChange={(e) => handleLocation(e)}
              result_type={LOCATION_TYPE.CITY}
              selectedValue={formData.location.city}
            />
          </FormControl>
          <FormControl flex={1}>
            <SelectInput
              name="ad_type"
              label="Ad type"
              options={REQUIREMENT_TYPE}
              onChange={(e) => handleChange(e)}
              selectedValue={formData?.ad_type}
            />
          </FormControl>
        </Flex>
      </Box>
      {/* User Name List */}
      <Box mb={3}>
        <Text fontSize="md" fontWeight="bold" mb={2}>
          Sharing the following users' requirements:
        </Text>
        <Box
          maxWidth="800px"
          overflowY="auto"
          p={3}
          border="1px solid #E2E8F0"
          borderRadius="md"
          backgroundColor="gray.50"
        >
          {userList && userList.length > 0 ? (
            <Text>
              {userList.map((user, index) => (
                <span key={user.id}>
                  <Text as="span" fontWeight="bold">
                    {user.full_name}
                  </Text>
                  {"  "}
                  {index !== userList.length - 1 && ", "}
                </span>
              ))}
            </Text>
          ) : (
            <Text color="gray.500">No user requirements selected.</Text>
          )}
        </Box>
      </Box>

      {/* User Requirements Errors */}
      {hasRequirementError && (
        <Box
          p={1}
          border="1px solid red"
          borderRadius="md"
        >
          <Text fontSize="sm" fontWeight="bold" color="red.500">
            Fix the user requirement errors before sending Leads:
          </Text>
          <Box>
            {validationErrors.map((user) => (
              <Box key={user.user_id}>
                <Text fontSize="sm" color="red.600">
                  User: {user.name}, <span style={{ margin: "0 5px" }}></span>{" "}
                  Missing Fields: {user.missingFields.join(", ")}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {/* Broker group */}
      <Flex justify="space-between" align="center" mb={2}>
        <Heading size="md">Select Broker Group</Heading>
        {isSubmittingForm && <CenteredSpinner />}
        <Flex align="center" gap={10}>
          <Button
            colorScheme="blue"
            variant="ghost"
            onClick={handleSubmit}
            disabled={groupNameMissing || hasRequirementError}
            size="sm"
          >
            Send Lead
          </Button>
        </Flex>
      </Flex>
      <Box flex={1}>
        <FormControl mb={2}>
          <InputGroup>
            <Input
              name="groupName"
              placeholder="Search Group By Name"
              type="text"
              onChange={(e) => handleGroupChange(e)}
              valueInNumber={true}
              onKeyDown={handleKeyDown}
              value={brokerGroup}
            />
            {brokerGroup && (
              <InputRightElement>
                <IconButton
                  size="sm"
                  variant="ghost"
                  icon={<CloseIcon />}
                  onClick={handleClearGroup}
                  aria-label="Clear input"
                />
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
      </Box>
      {/* Group List Data */}
      {listData?.length > 0 && (
        <>
          <Box fontSize="md" fontWeight="bold" mb={1}>
            Group List
          </Box>
          <Box
            maxWidth="800px"
            maxHeight="300px"
            overflowY="auto"
            border="1px solid #E2E8F0"
            borderRadius="md"
            p={2}
          >
            {listData.map((item, index) => (
              <Box
                key={item._id}
                p={2}
                borderBottom={
                  index !== listData.length - 1 ? "0.5px solid #E2E8F0" : "none"
                }
                borderRadius="md"
                onClick={() => handleItemClick(item)}
                cursor="pointer"
              >
                {renderGroupInfo(item)}
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default SendLeadForm;
