import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { requirementsColumn } from "views/admin/userRequirements/variables";
import useApi from "hooks/useApi";
import { HttpStatusCode } from "axios";
import {
  SORT_TYPES,
  SORT_DIRECTIONS,
} from "constants/filterDropdownData";
import Pagination from "components/pagination/Pagination";
import ErrorDisplay from "components/errorDisplay/ErrorDisplay";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { OverlayLoaderComponent } from "components/loader/Loader";
import SidebarFilter from "components/sidebarFilter/SidebarFilter";
import { getFilterFromLocalStorage } from "utils/common";
import { ENTITY_FILTER_TYPES } from "utils/common";
import { setFilterToLocalStorage } from "utils/common";
import ManageColumns from "components/manageColumns/ManageColumns";
import NoDataFound from "components/noDataFound/NoDataFound";
import { areAllStatusesSame } from "utils/common";
import { ENTITY_COLUMNS_TYPES } from "utils/common";
import { FiChevronsDown, FiFilter } from "react-icons/fi";
import CustomTable from "components/table/CustomTable";
import Menu from "components/menu/MainMenu";
import { useLocation } from "react-router-dom";
import { formatUserRequirementsTableData } from "utils/common";
import { PROJECT_LIST_STATUS } from "constants/filterDropdownData";
import MultiSelectMenu from "components/menu/MultiSelectMenu";
import { getTopCities } from "utils/common";
import { USER_REQUIREMENTS_API } from "constants/endpoints";
import UserRequirementModal from "../users/components/modal/UserRequirementModal";
import SendLeadModal from "./modal/SendLeadModal";
import { PROPERTY_TYPE_LIST } from "constants/filterDropdownData";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { USER_REQUIREMENT_ACTIONS } from "constants/defaultState";
import { formatDateToDDMMYYYY } from "utils/common";
import { REQUIREMENT_TYPE_DATA } from "constants/filterDropdownData";
import { USER_LEAD_FILTER_OPTIONS } from "constants/filterDropdownData";
import { LEAD_FILTER_TYPES } from "constants/defaultState";
import { handleApiRequest } from "utils/common";
import { REQUIREMENT_TYPE_TAGS } from "constants/dbEnums";

const PAGE_SIZE = 50;
const sortableColumns = ["listing_score", "created_at", "expires_on"];
const initialFiltersData = {
  propertyType: "",
  adType: "",
  showLeads: LEAD_FILTER_TYPES.ALL_LEADS,
  sharedAccommodation: false
};
const excludedColumnVisiblity = [
  'email',
  'updated_at',
  'created_by'
];

export default function UserRequirements() {
  const [requirementsData, setRequirementsData] = useState([]);
  const { apiCall, loading = true } = useApi();
  const [selectedAction, setSelectedAction] = useState("");
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(10);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [openRequirementForm, setOpenRequirementForm] = useState(false);
  const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [sortConfig, setSortConfig] = useState({
    sortType: "created_at",
    sortDirection: "descending",
  });
  const lsAdsDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USER_REQUIREMENTS_DATE_RANGE
  );
  const [startDate, setStartDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[0] : ""
  );
  const [endDate, setEndDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[1] : ""
  );
  const lsFilters = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER
  );
  const { search } = useLocation();
  const queryObject = Object.fromEntries(new URLSearchParams(search));
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState(initialFiltersData);
  const {
    sortType = "",
    sortDirection = "",
    propertyType,
    adType,
    showLeads,
    sharedAccommodation
  } = filters || {};
  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const fetchRequirementsData = useCallback(
    async (text) => {
      try {
        const body = {
          page: currentPage,
          limit: pageSize,
          search_text: text || searchText,
          city: selectedCity,
          show_leads: showLeads,
          property_type: propertyType,
          ad_type: adType,
          start_date: formatDateToDDMMYYYY(startDate),
          end_date: formatDateToDDMMYYYY(endDate),
          shared_accommodation: sharedAccommodation
        };
        const res = await apiCall.get(`${USER_REQUIREMENTS_API}`, body);
        if (res?.statusCode === HttpStatusCode.Ok) {
          setRequirementsData(res?.data?.requirements);
          setTotalPages(Math.ceil(res?.data?.count / pageSize));
          setTotalCount(res?.data?.count);
        }
      } catch (error) {
        setApiError(error);
        console.log("Error while fetching requirements: ", error.message);
      }
    },
    [currentPage, pageSize, searchText, selectedCity, showLeads, propertyType, adType, startDate, endDate, sharedAccommodation]
  );

  useEffect(() => {
    const fetchCities = async () => {
      const topCities = await getTopCities();
      setCityOptions(topCities || []);
    };
    fetchCities();
  }, []);

  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);

      fetchRequirementsData(text);
    },
    [fetchRequirementsData]
  );

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { 
        ...prevFilters, 
        [key]: value 
      };
      if (key === "adType" && value === REQUIREMENT_TYPE_TAGS.BUY) {
        updatedFilters.sharedAccommodation = false;
      }
      return updatedFilters;
    });
    setCurrentPage(1);
  }, []);

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];
    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(
      ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER,
      filters
    );
    setCurrentPage(1);
    setSelectedRowsData([]);
    fetchRequirementsData();
  };

  const handleRemoveAllFilter = () => {
    setFilteredData([]);
    setFilters(initialFiltersData);
    setEndDate("");
    setStartDate("");
    setSelectedCity("");
  };

  const handleDelete = async (id) => {
    try {
      const url = USER_REQUIREMENTS_API;
      const method = 'delete';
      const payload = {
        deleteRequirements: [{
          _id: id
        }]
      };
      const response = await handleApiRequest({apiCall, payload, url, method });
      if(response?.statusCode === HttpStatusCode.Ok){
        setRequirementsData((prev) => prev.filter((requirement) => requirement._id !== id));
      }
    } catch (error) {
      console.error("Error deleting requirement", error);
    }
  };

  const handleRequirementsUpdate = async ({ id, status }) => {
    try {
      const data = requirementsData.find((item) => item._id === id);
      if (!data) {
        console.error(`No data found for ID: ${id}`);
        return;
      }
      setSelectedStatus(status);
      if (status === USER_REQUIREMENT_ACTIONS.SEND_LEAD) {
        setOpenSendLeadModal(true);
        setSelectedUser({
          _id: data._id
        });
      } else if(status === USER_REQUIREMENT_ACTIONS.DELETE_REQUIREMENT) {
        await handleDelete(id);
      } else {
        setOpenRequirementForm(true);
        setSelectedUser({
          property_type: data.property_type,
          ad_type: data.ad_type,
          location: data.location,
          comment: data.comment,
          _id: data._id,
          shared_accommodation_interest: data.shared_accommodation_interest
        });
      }
    } catch (error) {
      console.log("error updating user requirements", error);
    }
  };

  const checkBoxFilter = [
    {
      name: "sharedAccommodation",
      label: "Show Only Interested in Shared Accommodation",
      value: filters.sharedAccommodation,
      isDisabled: (filters.adType === REQUIREMENT_TYPE_TAGS.BUY)
    },
  ];

  const dropdownFilters = [
    {
      name: "propertyType",
      placeholder: "Property Type",
      value: filters?.propertyType,
      data: PROPERTY_TYPE_LIST,
    },
    {
      name: "adType",
      placeholder: "Select Ad Type",
      value: filters?.adType,
      data: REQUIREMENT_TYPE_DATA,
      isDisabled: (filters.propertyType === PROPERTY_COLLECTIONS.PLOT || filters.propertyType === PROPERTY_COLLECTIONS.PG),
    },
    {
      name: "showLeads",
      placeholder: "Select Lead Type",
      value: filters?.showLeads,
      data: USER_LEAD_FILTER_OPTIONS
    },
  ];

  const dropdownSorts = [
    {
      name: "sortType",
      placeholder: "",
      value: sortType,
      data: SORT_TYPES,
    },
    {
      name: "sortDirection",
      placeholder: "",
      value: sortDirection,
      data: SORT_DIRECTIONS,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    sorts: dropdownSorts,
    checkBox: checkBoxFilter,
  };

  const columns = useMemo(() => requirementsColumn, [requirementsColumn]);
  const data = useMemo(
    () => formatUserRequirementsTableData(requirementsData),
    [requirementsData]
  );

  const lsLocalStorageManageColumns = getFilterFromLocalStorage(
    ENTITY_COLUMNS_TYPES.USER_REQUIREMENTS_COLUMNS
  );

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = !excludedColumnVisiblity.includes(
            column.accessor
          );
          return acc;
        }, {})
  );

  const [manageColumnSelectAllCheckbox, setManageColumnSelectAllCheckbox] = useState(false);

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.USER_REQUIREMENTS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  useEffect(() => {
    if (queryObject?.search_text) {
      setSearchText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      setSearchText("");
      fetchRequirementsData();
      setFilterToLocalStorage(
        ENTITY_FILTER_TYPES.USER_REQUIREMENTS_FILTER,
        filters
      );
    }
  }, [
    queryObject?.search_text,
    pageSize,
    currentPage,
    sortConfig,
  ]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();

    setCurrentPage(1);
    fetchRequirementsData(searchText);
  };

  const handleMultipleUpdateStatus = async ({ selectedStatus }) => {
    if(selectedStatus === "Send Leads") {
      setOpenSendLeadModal(true);
    }
  };

  /**SELECT ROW LOGIC**/ 
  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find((item) => item._id === row._id);

      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item._id !== findData._id);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  const selectedRowsId = selectedRowsData.map((item) => item._id);

  const selectedData = useMemo(
    () =>
      selectedRowsId.map((id) => data.find((row) => row._id === id)),
    [selectedRowsId, data]
  );

  const handleAfterRequirementsUpdate = (res) => {
    setOpenRequirementForm(false);
    setRequirementsData((prev) =>
      prev.map((requirement) =>
        requirement._id === res._id ? { ...requirement, ...res } : requirement
      )
    );
  };

  const handleAfterSendLead = (res) => {
    setOpenSendLeadModal(false);
  };

  if (apiError) {
    return <ErrorDisplay error={apiError} />;
  }

  return (
    <>
      <Box height="100vh" bg="#F8FAFB">
        <Flex
          justify="space-between"
          align="center"
          p="2"
          boxShadow="sm"
          borderRadius="sm"
          pos="fixed"
          style={{ width: "calc(100vw - 96px)" }}
          zIndex={1000}
        >
          <HStack spacing="4" p={2}>
            <Box position="relative" display="inline-block">
              <Button
                paddingX={6}
                leftIcon={<FiFilter />}
                rightIcon={<FiChevronsDown />}
                colorScheme="teal"
                variant="outline"
                borderRadius="md"
                size="sm"
                onClick={() => {
                  toggleSidebar();
                  setFilters(lsFilters);
                }}
              >
                Filter
              </Button>
              {filteredData.length > 0 && (
                <Badge
                  position="absolute"
                  top="-3"
                  right="0"
                  colorScheme="teal"
                  borderRadius="full"
                  px={2}
                  fontSize="0.8em"
                >
                  {filteredData.length}
                </Badge>
              )}
            </Box>
            <ManageColumns
              columns={columns}
              handleColumnChange={handleColumnChange}
              columnVisibility={columnVisibility}
              setManageColumnSelectAllCheckbox={setManageColumnSelectAllCheckbox}
              manageColumnSelectAllCheckbox={manageColumnSelectAllCheckbox}
            />
            <form onSubmit={handleInputFormSubmit}>
              <InputGroup size="sm">
                <SearchBar
                  background="#F9F9FB"
                  color="#637381"
                  setSearchText={(e) => setSearchText(e.target.value)}
                  searchText={searchText}
                  borderRadius="5px"
                  borderWidth="0.5px"
                  borderColor="#637381"
                />
              </InputGroup>
            </form>
          </HStack>
          <Text
            borderRadius="5px"
            borderWidth="0.5px"
            borderColor="#637381"
            background="#F9F9FB"
            color="#637381"
            p={1}
            fontWeight={"bold"}
          >
            Total Requirements: {totalCount}
          </Text>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => {
              setSelectedRowsData([]);
              setCurrentPage(value);
            }}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPageSizeToLocalStorage={(value) =>
              setFilterToLocalStorage(
                ENTITY_FILTER_TYPES.USER_REQUIREMENTS_PAGE_SIZE,
                value
              )
            }
          />
        </Flex>
        <SidebarFilter
          filters={allFiltersProps}
          handleFilterChange={handleFilterChange}
          handleApplyFilter={handleApplyFilter}
          isOpen={isOpenFilterSidebar}
          onClose={toggleSidebar}
          appliedFilters={filters}
          setFilters={setFilters}
          lsFilters={lsFilters}
          handleRemoveAllFilter={handleRemoveAllFilter}
          // onFilterDateRangeSubmit={onFilterDateRangeSubmit}
          //date filter
          isCalenderFilter={true}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          ////location filter
          locationCitiesFilter={cityOptions}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
        {requirementsData.length !== 0 ? (
          <>
            <CustomTable
              columns={visibleColumns}
              data={data}
              onSelectedAction={setSelectedAction}
              selectedRowsData={selectedRowsData}
              selectedRowsSlug={selectedRowsId}
              selectedData={selectedData}
              handleRowSelect={handleRowSelect}
              setSelectedRowsData={setSelectedRowsData}
              sortableColumns={sortableColumns}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              single
              menu
              props
              menuComponent={
                <Menu
                  data={Object.values(PROJECT_LIST_STATUS)}
                  onUpdate={() => {}}
                  onUpdatePropertyStatus={handleRequirementsUpdate}
                  selectedAction={selectedAction}
                />
              }
              multiSelectMenuComponent={
                selectedRowsId?.length > 0 &&
                areAllStatusesSame(
                  selectedData.map((item) => item?.status)
                ) && (
                  <MultiSelectMenu
                    multiSelectCurrentValue={selectedData[0]?.status}
                    onMultipleUpdateStatus={handleMultipleUpdateStatus}
                    selectedAction={selectedAction}
                    selectedRowsData={selectedRowsData}
                    bgColor="#B2F5EA"
                    dataType="userRequirements"
                  />
                )
              }
            />
          </>
        ) : !loading ? (
          <NoDataFound />
        ) : null}
        <OverlayLoaderComponent isOpen={loading} />
      </Box>
      <UserRequirementModal
        isOpen={openRequirementForm}
        onClose={() => setOpenRequirementForm(false)}
        userId={selectedAction?.user_id}
        selectedRequirement={selectedUser}
        afterRequirementsUpdate={(res) => handleAfterRequirementsUpdate(res)}
        selectedData={selectedAction}
        disableSubmitButton={selectedStatus === USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT}
      />
      <SendLeadModal
        isOpen={openSendLeadModal}
        onClose={() => setOpenSendLeadModal(false)}
        selectedRequirements={selectedRowsId?.length >0 ? selectedRowsId : [selectedUser._id]}
        afterSendLead={(res) => handleAfterSendLead(res)}
        selectedData={selectedRowsData?.length > 0 ? selectedRowsData : [selectedAction]}
      />
    </>
  );
}
